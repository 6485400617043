import {MessageStorage, mapApiResponseMessage} from '@ifeelonline/chat-core';

import {Dispatch} from 'redux';

import {FavoritesActionType} from 'src/state/action_types/FavoritesActionType';
import {FavoritesAction} from 'src/state/actions/favorites';
import {fetchWrapper} from 'src/helpers/fetch';

export const getFavoritesConversation = (
  conversationId: number,
  page: number,
) => {
  return async (dispatch: Dispatch<FavoritesAction>) => {
    if (page === 1) {
      dispatch({type: FavoritesActionType.FAVORITES_LOADING});
    }
    const resp = await fetchWrapper({
      endPoint: `favorites?id=${conversationId}&page=${page}`,
    });

    if (!resp) return null;
    if (!resp.body.success || !resp.body.messages) return false;

    const {messages} = resp.body;
    const messagesMapped = messages?.map((message: MessageStorage) =>
      mapApiResponseMessage(message),
    );

    if (page === 1) {
      dispatch({
        type: FavoritesActionType.SET_FAVORITES,
        payload: {
          conversationId: conversationId,
          messages: messagesMapped,
        },
      });
      return conversationId;
    } else {
      if (messagesMapped.length < 0) return false;

      dispatch({
        type: FavoritesActionType.FAVORITES_LOAD_MORE_MESSAGES,
        payload: messagesMapped,
      });
      return true;
    }
  };
};
