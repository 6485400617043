import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Conversation} from '@ifeelonline/chat-core';
import {Button} from '@ifeelonline/storybook';

import {Tooltip} from '@mui/material';
import moment from 'moment';

import AgentBgWhite from 'src/assets/images/chat/sidebar-blue/agent-white.svg';
import IconDateSmall from 'src/assets/images/chat/sidebar-blue/date_small.svg';
import payment_icon from 'src/assets/images/chat/sidebar-blue/payment.svg';
import IconPlan from 'src/assets/images/chat/sidebar-blue/ic_plan.png';
import {RoutePatientInfo} from 'src/constants/Routes';

import {SidePanelSection} from '../components/SidePanelSection';

export const PlanInfoSection = ({
  conversation,
}: {
  conversation: Conversation;
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  if (!conversation.patientId) return null;

  const handleGoToPatientInfo = (patientId: number) => {
    navigate(RoutePatientInfo(patientId.toString()));
  };

  return (
    <SidePanelSection icon={IconPlan} title={t('generic.plan')}>
      <div id="userPlanInfo" className="px-6 pb-5">
        <ul className="ms-3 pt-2.5">
          <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
            <img src={payment_icon} alt="Agent" className="mb-0.5 mr-1 w-4" />
            {conversation.patientInactive
              ? 'Plan expired'
              : conversation.patientGetPlanName}
          </li>
          <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
            <img src={payment_icon} alt="Agent" className="mb-0.5 mr-1 w-4" />
            {conversation.patientPayMethod}
          </li>
          <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
            <Tooltip
              title={t('generic.active_until')}
              key="active_until_tooltip"
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -8],
                      },
                    },
                  ],
                },
              }}>
              <img
                src={IconDateSmall}
                alt="Agent"
                className="mb-0.5 mr-1 w-4 cursor-pointer"
              />
            </Tooltip>

            {conversation.patientActiveUntil &&
              moment(conversation.patientActiveUntil).format('LLL')}
          </li>
          <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
            <img src={AgentBgWhite} alt="Agent" className="mb-0.5 mr-1 w-4" />
            {conversation.patientPsyName}
          </li>
        </ul>
        <Button
          buttonType="default"
          onClick={() => handleGoToPatientInfo(conversation.patientId!)}
          size="large"
          text={
            <div className="transition-color block w-full pl-5 text-left text-3.5 text-brandPrimaryLight-600 duration-200 hover:text-brandPrimaryLight-200">
              {t('info_user.btn')}
            </div>
          }
        />
      </div>
    </SidePanelSection>
  );
};
