import {
  BrandColors,
  TextColors,
} from '@ifeelonline/storybook/dist/src/lib/Types/TailwindTypes';

import {SurveyStatus} from './Surveys';

type AIQuestionTagStorage =
  | 'agent'
  | 'radio-button'
  | 'dropdown'
  | 'checkbox'
  | 'text'
  | 'number';

export enum AIQuestionTag {
  RadioButton = 'radioButton',
  Dropdown = 'dropdown',
  Checkbox = 'checkbox',
  Text = 'text',
  Agent = 'agent',
  Number = 'number',
}

export interface AIQuestionnaireForm {
  isOpen: boolean;
  displayIsOpen: boolean;
  status: AIQuestionnaireStatus | null;
  agent: string | null;
  isLoading: boolean;
  patientId: string | number | null;
  result: AIQuestionnaireShow | null;
}

export interface AIQuestionStorage {
  answer?: AIQuestionAnswerStorage;
  body: string;
  id: number;
  field: 'required' | 'no_required';
  position: number;
  question_options?: AIQuestionOptionStorage[];
  rules?: {max_number_characters: number};
  survey_id: number;
  tag: AIQuestionTagStorage;
}

export interface AIQuestionAnswerStorage {
  id: number;
  question_id: number;
  option_id: number[];
  position: number;
  agent_id: number;
  comment: string;
}

export interface AIQuestionAnswer {
  id: number;
  questionId: number;
  optionId: number[];
  comment: string;
  position: number;
  agentId: number;
}

export interface AIAnswerNew {
  id?: number;
  questionId?: number;
  optionIds?: number[];
  comment?: string;
  position?: number;
  agentId?: number;
  type?: AIAnswerType;
}

export interface AIQuestionOptionStorage {
  agent_id?: number;
  body?: string;
  conversation_id?: number;
  id: number;
  nickname?: string;
  position: number;
}

export interface AIQuestionNew {
  answer?: AIAnswerNew;
  body: string;
  id: number;
  position: number;
  questionOptions?: AIQuestionOption[];
  required: boolean;
  rules?: {maxLength: number};
  surveyId: number;
  tag: AIQuestionTag;
  isSOFAS: boolean;
}

export interface AIQuestion {
  answer?: AIQuestionAnswer;
  body: string;
  id: number;
  position: number;
  questionOptions?: AIQuestionOption[];
  required: boolean;
  rules?: {maxLength: number};
  surveyId: number;
  tag: AIQuestionTag;
  isSOFAS: boolean;
}

export interface AIQuestionOption {
  agentId?: number;
  body?: string;
  conversationId?: number;
  id: number;
  nickname?: string;
  position: number;
}

export interface AIAnswersStorage {
  conversation_id: number;
  questions: AIAnswerStorage[];
}

export interface AIAnswerStorage {
  question_id: number;
  option_ids?: number[];
  comment?: string;
  agent_id?: number;
}

export interface AIAnswers {
  conversationId: number;
  questions: AIAnswer[] | null;
}

export interface AIAnswersNew {
  conversationId: number;
  questions: AIAnswerNew[] | null;
}

export enum AIAnswerType {
  Agent = 'agent',
  Options = 'options',
  Comment = 'comment',
}
export interface AIAnswer {
  questionId: number;
  optionIds?: number[];
  comment?: string;
  agentId?: number;
  type?: AIAnswerType;
}

export enum AIQuestionnaireStatus {
  START = SurveyStatus.START,
  DRAFT = SurveyStatus.DRAFT,
  EDITING = SurveyStatus.EDITING,
  SENT = 3,
}

export type AIAgentSelectionStorage =
  | 'virtual_assistant'
  | 'emotional_support_chat'
  | 'video_therapy'
  | 'referral';

export type AIAgentSelection =
  | 'virtualAssistant'
  | 'emotionalSupportChat'
  | 'videoTherapy'
  | 'referral';

export interface AISurveyStatusStorage {
  success: boolean;
  ai_status: 'start' | 'draft' | 'editing' | 'sent';
  ai_status_list: AIStatusList;
  agent: string | null;
}

export interface AISurveyStatus {
  success: boolean;
  aiStatus: AIQuestionnaireStatus;
  aiStatusList: AIStatusList;
  agent: string | null;
}
export interface AIStatusList {
  start: number;
  draft: number;
  editing: number;
  sent: number;
}

export interface AIShowStorage {
  success: boolean;
  ai: AIStorage;
  agent_possible_selection: AIAgentPossibleSelectionStorage;
}

export interface AIStorage {
  id: number;
  conversation_id: number;
  ai_status: AIQuestionnaireStatus;
  virtual_assistant: number;
  emotional_support_chat: number;
  video_therapy: number;
  referral: number;
  agent_selection: string;
}

export interface AIAgentPossibleSelectionStorage {
  virtual_assistant: number;
  emotional_support_chat: number;
  video_therapy: number;
  referral: number;
}

export type AIQuestionnaireResult = {
  key: string;
  icon: React.ReactNode;
  color: BrandColors | TextColors | undefined;
};

export interface AIQuestionnaireShow {
  success: boolean;
  ai: AIUserQuestionnaire;
  agentPossibleSelection: AgentPossibleSelection;
}

export interface AIUserQuestionnaire {
  id: number;
  conversationId: number;
  aiStatus: AIQuestionnaireStatus;
  virtualAssistant: number;
  emotionalSupportChat: number;
  videoTherapy: number;
  referral: number;
  agentSelection: string | null;
}

export interface AgentPossibleSelection {
  virtualAssistant: number;
  emotionalSupportChat: number;
  videoTherapy: number;
  referral: number;
}

export interface AIResultStorage {
  id: number;
  conversation_id: number;
  agent: AIAgentStorage;
  ai_status: string;
  virtual_assistant: number;
  emotional_support_chat: number;
  video_therapy: number;
  referral: number;
}

export interface AIAgentStorage {
  id: number;
  agent_id: number;
  user_id: number;
  nickname: string;
  position: number;
}

export interface AIResult {
  id: number;
  conversationId: number;
  agent: AIAgent;
  aiStatus: string;
  virtualAssistant: number;
  emotionalSupportChat: number;
  videoTherapy: number;
  referral: number;
}

export interface AIAgent {
  id: number;
  agentId: number;
  userId: number;
  nickname: string;
  position: number;
}

export enum AIFormSteps {
  QUESTIONS = 0,
  SOFAS = 1,
}
