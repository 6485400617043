import {Control, Controller, FieldErrors, FieldValues} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {SurveyQuestion, SurveyQuestionTag} from 'src/pages/chat/types/Surveys';

import {RadioButtonCommentFormField} from '../form_fields/form_fields_with_comment/RadioButtonCommentFormField';
import {CheckboxCommentFormField} from '../form_fields/form_fields_with_comment/CheckboxCommentFormField';
import {RadioButtonFormField} from '../form_fields/RadioButtonFormField';
import {TextInputFormField} from '../form_fields/TextInputFormField';
import {CheckboxFormField} from '../form_fields/CheckboxFormField';
import {DropdownFormField} from '../form_fields/DropdownFormField';
import {TextAreaFormField} from '../form_fields/TextAreaFormField';

interface UseRenderFormFieldProps {
  question: SurveyQuestion;
  control: Control<FieldValues>;
  errors: FieldErrors<FieldValues>;
  conversationId: number;
  handleBlur: (questionId: number, textAnswer: string) => void;
}

export const useRenderFormField = ({
  question,
  control,
  errors,
  conversationId,
  handleBlur,
}: UseRenderFormFieldProps) => {
  const {t} = useTranslation();

  const renderField = () => {
    const commonProps = {
      control,
      name: question.id.toString(),
      defaultValue: question.answer?.comment || '',
      rules: {required: question.required},
      error:
        errors[question.id.toString()]?.type === 'required'
          ? t('surveys.form.field_required')
          : null,
    };

    switch (question.tag) {
      case SurveyQuestionTag.Agent:
      case SurveyQuestionTag.Dropdown:
        return (
          <Controller
            {...commonProps}
            defaultValue={
              question.answer?.agentId ||
              (question.answer?.optionId && question.answer?.optionId.length > 0
                ? question.answer.optionId[0]
                : '')
            }
            render={({field: {name, value, onChange}}) => (
              <DropdownFormField
                conversationId={conversationId}
                options={
                  question.questionOptions?.map((option) => ({
                    text: option.body || option.nickname || '',
                    value: option.agentId || option.id,
                  })) || []
                }
                selectedOption={value}
                handleChange={onChange}
                question={question}
                error={
                  errors[name]?.type === 'required'
                    ? t('surveys.form.field_required')
                    : null
                }
              />
            )}
          />
        );

      case SurveyQuestionTag.RadioButton:
        return (
          <Controller
            {...commonProps}
            defaultValue={question.answer?.optionId[0] || ''}
            render={({field: {name, value, onChange}}) => (
              <RadioButtonFormField
                conversationId={conversationId}
                questionOptions={question.questionOptions || []}
                selectedOption={value}
                handleRadioButtonChange={onChange}
                question={question}
                error={
                  errors[name]?.type === 'required'
                    ? t('surveys.form.field_required')
                    : null
                }
              />
            )}
          />
        );

      case SurveyQuestionTag.RadioButtonComment:
        return (
          <Controller
            {...commonProps}
            defaultValue={question.answer?.optionId[0] || ''}
            render={({field: {name, value, onChange}}) => (
              <RadioButtonCommentFormField
                conversationId={conversationId}
                questionOptions={question.questionOptions || []}
                selectedOption={value}
                handleRadioButtonChange={onChange}
                question={question}
                error={
                  errors[name]?.type === 'required'
                    ? t('surveys.form.field_required')
                    : null
                }
              />
            )}
          />
        );

      case SurveyQuestionTag.Checkbox:
        return (
          <Controller
            {...commonProps}
            defaultValue={question.answer?.optionId || []}
            render={({field: {value, name, onChange}}) => (
              <CheckboxFormField
                conversationId={conversationId}
                questionOptions={question.questionOptions || []}
                selectedOptions={value}
                handleCheckboxChange={onChange}
                question={question}
                error={
                  errors[name]?.type === 'required'
                    ? t('surveys.form.field_required')
                    : null
                }
              />
            )}
          />
        );

      case SurveyQuestionTag.CheckboxComment:
        return (
          <Controller
            {...commonProps}
            defaultValue={question.answer?.optionId || []}
            render={({field: {value, name, onChange}}) => (
              <CheckboxCommentFormField
                conversationId={conversationId}
                questionOptions={question.questionOptions || []}
                selectedOptions={value}
                handleCheckboxChange={onChange}
                question={question}
                error={
                  errors[name]?.type === 'required'
                    ? t('surveys.form.field_required')
                    : null
                }
              />
            )}
          />
        );

      case SurveyQuestionTag.Text:
        return (
          <Controller
            {...commonProps}
            render={({field: {value, onChange}}) => (
              <TextAreaFormField
                textAnswer={value}
                handleTextAreaChange={onChange}
                handleBlur={handleBlur}
                questionId={question.id}
              />
            )}
          />
        );

      default:
        return (
          <Controller
            {...commonProps}
            render={({field: {value, onChange}}) => (
              <TextInputFormField
                textAnswer={value}
                questionId={question.id}
                error={
                  errors[question.id.toString()]?.type === 'required'
                    ? t('surveys.form.field_required')
                    : errors[question.id.toString()]?.message?.toString()
                }
                handleTextAreaChange={onChange}
                handleBlur={handleBlur}
              />
            )}
          />
        );
    }
  };

  return renderField;
};
