const localStorageHelper = {
  setItem(key: string, value: string) {
    localStorage.setItem(key, value.toString());
  },

  getItem(key: string, defaultValue = null) {
    return localStorage.getItem(key) ?? defaultValue;
  },

  removeItem(key: string) {
    localStorage.removeItem(key);
  },

  setUserDetails({
    email,
    id,
    token,
  }: {
    email: string;
    id: number;
    token: string;
  }) {
    this.setItem('email', email);
    this.setItem('id', id.toString());
    this.setItem('token', token);
    this.setItem('token-init-date', new Date().getTime().toString());
  },

  getUserDetails() {
    return {
      email: this.getItem('email'),
      id: this.getItem('id'),
      token: this.getItem('token'),
      tokenInitDate: this.getItem('token-init-date'),
    };
  },

  setCurrentAgent(agentId: number | null) {
    this.setItem('currentAgent', agentId?.toString() ?? '');
  },

  getCurrentAgent(defaultValue = null) {
    return this.getItem('currentAgent', defaultValue);
  },
};

export default localStorageHelper;
