import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';

import {conversationsReducer} from 'src/state/reducers/conversationsReducer';
import {conversationReducer} from 'src/state/reducers/conversationReducer';
import {patientReducer} from 'src/state/reducers/patientReducer';
import {uiReducer} from 'src/state/reducers/uiReducer';

import {favoritesReducer} from './reducers/favoritesReducer';

export const store = configureStore({
  reducer: combineReducers({
    conversation: conversationReducer,
    conversations: conversationsReducer,
    favorites: favoritesReducer,
    ui: uiReducer,
    patient: patientReducer,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
