import {TFunction} from 'react-i18next';

import {Quote} from 'src/types/Quote';

export function getQuoteList(t: TFunction<'translation', undefined>) {
  const quoteKeys = ['one', 'two', 'three', 'four', 'five'];
  const quoteList: Quote[] = quoteKeys.map((key) => ({
    text: t(`quotes.${key}.quote`),
    author: t(`quotes.${key}.author`),
  }));

  return quoteList;
}
