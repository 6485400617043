import {AuthUserSupport} from '@ifeelonline/chat-core';

import {UserContextType} from 'src/providers/UserProvider';
import {PARTNERS_AVAILABLE} from 'src/constants/partners';

import localStorageHelper from './localStorage';

export function setLocationParams(setLocation: UserContextType['setLocation']) {
  if (!navigator.geolocation) {
    setLocation({
      latitude: null,
      longitude: null,
      error: 'Geolocation is not supported by your browser',
    });
  } else {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null,
          });
        },
        () => {
          setLocation({
            latitude: null,
            longitude: null,
            error: 'Unable to retrieve your location',
          });
        },
      );
    } catch (error) {
      setLocation({
        latitude: null,
        longitude: null,
        error: 'Unable to retrieve your location',
      });
    }
  }
}

export function loadAndSetCurrentAgentId(
  setCurrentAgentId: UserContextType['setCurrentAgentId'],
) {
  const agent = localStorageHelper.getCurrentAgent();
  if (agent) setCurrentAgentId(Number(agent));
}

export function isFemiSupport(user: AuthUserSupport) {
  return user.partner === PARTNERS_AVAILABLE.FEMI;
}
