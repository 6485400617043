import React, {createContext, useEffect, useState} from 'react';

import actionCable from 'actioncable';

import {handleAgentChannelReceived} from 'src/action_cable/handlers/agentChannelHandlers';
import {AgentChannel} from 'src/action_cable/types/agentChannelDataTypes';
import ConnectionLost from 'src/pages/chat/components/ConnectionLost';
import {useAppDispatch} from 'src/hooks/hooks';

import {connectToAgentChannelProvider} from './ConnectToAgentChannelProvider';
import {useUser} from './UserProvider';

export const ActionCableContext = createContext<actionCable.Cable | null>(null);

export const ActionCableProvider = ({
  children,
}: {
  children: React.JSX.Element;
}) => {
  const {user, setIsChatOnline, setChatStateChanged} = useUser();
  const dispatch = useAppDispatch();
  const [isConnected, setIsConnected] = useState(true);
  const url = import.meta.env.VITE_ACTION_CABLE_URL;
  const cable = actionCable.createConsumer(
    `${url}?action_cable_token=${user?.actionCableToken}&email=${user?.email}` ??
      '',
  );
  const {shouldDisconnectFromAgentChannel} = connectToAgentChannelProvider();

  useEffect(() => {
    if (!shouldDisconnectFromAgentChannel) {
      if (user) {
        const params = {
          channel: 'AgentChannel',
          agent_id: user.uid,
        };
        const handlers = {
          connected: () => {
            setIsConnected(true);
          },
          disconnected: () => {
            setIsConnected(false);
          },
          received: (dataResponse: AgentChannel['DataResponse']) => {
            try {
              if (dataResponse?.action) {
                handleAgentChannelReceived(
                  dataResponse,
                  dispatch,
                  setIsChatOnline,
                  setChatStateChanged,
                );
              }
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error);
            }
          },
        };
        const subscription = cable.subscriptions.create(params, handlers);

        return () => {
          subscription.unsubscribe();
        };
      }
    } else {
      cable.disconnect();
    }
  }, [user, cable, dispatch]);

  return (
    <ActionCableContext.Provider value={cable}>
      {!isConnected && <ConnectionLost />}
      {children}
    </ActionCableContext.Provider>
  );
};
