import React, {ReactNode, createContext, useState} from 'react';

interface ChatPageContextInterface {
  pageConversations: number | null;
  setPageConversations: React.Dispatch<React.SetStateAction<number>> | null;
}

export const ChatPageContext = createContext<ChatPageContextInterface>({
  pageConversations: null,
  setPageConversations: null,
});

export const ChatPageProvider = ({children}: {children: ReactNode}) => {
  const [pageConversations, setPageConversations] = useState(1);
  return (
    <ChatPageContext.Provider value={{pageConversations, setPageConversations}}>
      {children}
    </ChatPageContext.Provider>
  );
};
