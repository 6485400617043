import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

import {AIQuestionnaireFormProps} from 'src/pages/chat/components/ai_questionnaire/AIQuestionnaireForm';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {setQuestionsAI} from 'src/state/action_creators/conversationActionCreator';
import {extractSOFASQuestion} from 'src/pages/chat/utils/ai_questionnaire/logic';
import {AIFormSteps} from 'src/pages/chat/types/AIQuestionnaire';
import {useAppDispatch} from 'src/hooks/hooks';

import {useChat} from './ChatProvider';

const AIQuestionnaireContext = createContext<
  | Pick<
      AIQuestionnaireFormProps,
      | 'step'
      | 'setStep'
      | 'SOFASAnswer'
      | 'setSOFASAnswer'
      | 'isOpenSofaErrorModal'
      | 'setIsOpenSofaErrorModal'
    >
  | undefined
>(undefined);

export const AIQuestionnaireProvider: React.FC<{children: ReactNode}> = ({
  children,
}) => {
  const dispatch = useAppDispatch();

  const [step, setStep] = useState<AIQuestionnaireFormProps['step']>(
    AIFormSteps.QUESTIONS,
  );

  const [isOpenSofaErrorModal, setIsOpenSofaErrorModal] = React.useState(false);

  const [SOFASAnswer, setSOFASAnswer] = useState<
    AIQuestionnaireFormProps['SOFASAnswer']
  >([]);

  const {conversation} = useChat();
  const patientId = conversation?.patientId;

  const getAIQuestionnairePatientAnswersNew = async () => {
    dispatch(setQuestionsAI(conversation!.id!)).then((questions) => {
      if (questions) {
        dispatch({
          type: ConversationActionType.SET_AI_QUESTIONS,
          payload: questions,
        });
        const SOFASQuestion = extractSOFASQuestion(questions);
        if (SOFASQuestion.answer?.comment) {
          setStep(AIFormSteps.SOFAS);
          setSOFASAnswer([
            {
              comment: SOFASQuestion.answer.comment,
              questionId: SOFASQuestion.id,
            },
          ]);
        }

        dispatchPatientId();
      }
    });
  };

  const dispatchPatientId = () => {
    if (!conversation?.id) return;
    dispatch({
      type: ConversationActionType.AI_QUESTIONNAIRE_FORM_PATIENT_ID,
      payload: patientId,
    });
  };

  useEffect(() => {
    if (conversation?.id) {
      setStep(AIFormSteps.QUESTIONS);
      getAIQuestionnairePatientAnswersNew();
    }
  }, [conversation?.id]);

  return (
    <AIQuestionnaireContext.Provider
      value={{
        step,
        setStep,
        SOFASAnswer,
        setSOFASAnswer,
        isOpenSofaErrorModal,
        setIsOpenSofaErrorModal,
      }}>
      {children}
    </AIQuestionnaireContext.Provider>
  );
};

export const useAIQuestionnaireFromProvider = () => {
  const context = useContext(AIQuestionnaireContext);
  if (!context) {
    throw new Error(
      'useAIQuestionnaire must be used within an AIQuestionnaireProvider',
    );
  }
  return context;
};
