export enum ROUTES {
  AI_QUESTIONNAIRE_INFO = '/chat/questionnaire/info',
  CONVERSATION = '/conversation/:id',
  FAVORITE_MESSAGES = '/favorites/:conversationId',
  HOME = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  PATIENT = '/patients/:id',
  VIDEO_CALL = '/video-call/:conversationId/:agentId',
  END_VIDEO_CALL = '/end-video-call/:meetingId/:conversationId',
  SCHEDULED = '/scheduled',
}
export const RouteConversation = (id: string) =>
  `${ROUTES.CONVERSATION.replace(':id', id)}`;

export const RoutePatientInfo = (patientId: string) =>
  `${ROUTES.PATIENT.replace(':id', patientId)}`;

export const RouteFavoriteMessages = (conversationId: string) =>
  `${ROUTES.FAVORITE_MESSAGES.replace(':conversationId', conversationId)}`;

/*VideoCall Routes */
export const RouteVideoCall = (conversationId: number, agentId: number) =>
  `${ROUTES.VIDEO_CALL.replace(
    ':conversationId',
    conversationId.toString(),
  ).replace(':agentId', agentId.toString())}`;

export const RouteEndVideoCall = ({
  meetingId,
  conversationId,
}: {
  conversationId: number;
  meetingId: number;
}) =>
  `${ROUTES.END_VIDEO_CALL.replace(':meetingId', meetingId.toString()).replace(
    ':conversationId',
    conversationId.toString(),
  )}`;
