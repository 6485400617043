export const ZoomVersion = '3.9.0';
export const ZoomBootStrap = `https://source.zoom.us/${ZoomVersion}/css/bootstrap.css`;
export const ZoomReactSelect = `https://source.zoom.us/${ZoomVersion}/css/react-select.css`;
export const ZoomLib = `https://source.zoom.us/${ZoomVersion}/lib`;
export const zoomReact = `https://source.zoom.us/${ZoomVersion}/lib/vendor/react.min.js`;
export const zoomReactDom = `https://source.zoom.us/${ZoomVersion}/lib/vendor/react-dom.min.js`;
export const ZoomRedux = `https://source.zoom.us/${ZoomVersion}/lib/vendor/redux.min.js`;
export const ZoomReduxThunk = `https://source.zoom.us/${ZoomVersion}/lib/vendor/redux-thunk.min.js`;
export const ZoomLodash = `https://source.zoom.us/${ZoomVersion}/lib/vendor/lodash.min.js`;
export const ZoomMeeting = `https://source.zoom.us/zoom-meeting-${ZoomVersion}.min.js`;
export const ZoomMeetingEmbedded = `https://source.zoom.us/zoom-meeting-embedded-${ZoomVersion}.min.js`;
