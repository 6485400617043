import {PropsWithChildren, useEffect, useState} from 'react';

import {Button, Text} from '@ifeelonline/storybook';

import {CaretDown, CaretUp} from 'phosphor-react';

interface Props {
  icon?: string;
  title: string;
  handleOpen?: () => void;
  isInnerItem?: boolean;
  show?: boolean;
  conversationId?: number;
}

export const SidePanelSection = ({
  title,
  icon,
  handleOpen,
  children,
  isInnerItem = false,
  conversationId,
}: PropsWithChildren<Props>) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = async () => {
    if (!isOpen && handleOpen) {
      await handleOpen();
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (conversationId) setIsOpen(false);
  }, [conversationId]);

  return (
    <div
      className={`${
        !isInnerItem && `border-t border-solid border-borders-sidebar`
      } flex w-full flex-col`}>
      <div className="disabled:svg:hidden flex h-14 w-full items-center justify-between px-5 py-2.5 text-white hover:bg-borders-sidebarHover disabled:opacity-50">
        <Button
          buttonType="default"
          onClick={toggleSection}
          size="large"
          text={
            <div className="flex w-full gap-2 text-left text-white">
              {icon && (
                <img src={icon} alt="PQuestionnaire" className="size-6" />
              )}
              <Text
                textType={isInnerItem ? 'body-3' : 'body-2'}
                fontWeight={isInnerItem ? 'bold' : 'regular'}
                fontColor="text-white"
                fontFamily={isInnerItem ? 'workSans' : 'sofia'}>
                {title}
              </Text>
            </div>
          }
          iconPosition="end"
          icon={
            isOpen ? (
              <CaretUp size={12} color="white" />
            ) : (
              <CaretDown size={12} color="white" />
            )
          }
        />
      </div>
      {isOpen && <div>{children}</div>}
    </div>
  );
};
