import {useTranslation} from 'react-i18next';

import {AgentInactive, AgentPerson} from '@ifeelonline/icons';
import {Text} from '@ifeelonline/storybook';

import {PencilLine} from 'phosphor-react';

import {PlanTypeFunctions} from 'src/helpers/planTypeFunctions';
import {ConversationListItem} from 'src/types/ConversationList';

import {isMatched} from '../../utils/conversation';

export const InfoConversation = ({
  conversationListItem,
}: {
  conversationListItem: ConversationListItem;
}) => {
  const {t} = useTranslation();
  const [planTypeIcon, planTypeText] = PlanTypeFunctions();

  return (
    <div className="w-full-minus-10 ps-2.5">
      <div className="flex items-center">
        <Text textType="body-2" className="overflow-hidden text-ellipsis">
          {conversationListItem.patientNickname}
        </Text>
        {conversationListItem.typing && (
          <span className="ml-3 flex w-10.5 items-center text-3 after:inline-block after:w-0 after:animate-typing after:overflow-hidden after:align-bottom after:content-typing">
            <PencilLine size={12} />
          </span>
        )}
      </div>
      <div className="flex">
        <span className="flex items-center gap-1 text-3.5 text-blue21">
          {conversationListItem.patientTypePlan && (
            <>
              <img
                src={planTypeIcon(conversationListItem.patientTypePlan)}
                alt="Plan type"
                className="max-h-5"
              />
              {planTypeText(conversationListItem.patientTypePlan)}
            </>
          )}
        </span>
        <span className="ml-2 flex items-center gap-1 text-3.5 text-blue21">
          {isMatched(conversationListItem.status) ? (
            <AgentPerson size={16} />
          ) : (
            <AgentInactive size={16} />
          )}

          {isMatched(conversationListItem.status)
            ? conversationListItem.agentName
            : t('filter_conversations.no_agent')}
        </span>
      </div>
    </div>
  );
};
