import {useState} from 'react';

import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {Button, Logo} from '@ifeelonline/storybook';

import {mapAuthSupportResponseStorageToAuthSupportResponse} from 'src/helpers/api_response/login';
import {login} from 'src/state/action_creators/authActionCreator';
import localStorageHelper from 'src/helpers/localStorage';
import {changeLanguage} from 'src/helpers/translations';
import {useUser} from 'src/providers/UserProvider';
import {AMAZON_URL} from 'src/constants';

import {LoginFormField} from './components/LoginFormField';

export interface LoginFormProps {
  email: string;
  password: string;
}

const initialState: LoginFormProps = {
  email: '',
  password: '',
};

export const LoginPage = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const {setUser, setIsChatOnline} = useUser();
  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm<LoginFormProps>({defaultValues: initialState, mode: 'all'});
  const [errorLogin, setErrorLogin] = useState(false);

  const onSubmit: SubmitHandler<LoginFormProps> = async ({email, password}) => {
    setErrorLogin(false);
    setLoading(true);

    const result = await login(email, password);
    setLoading(false);

    if (result) {
      const userNew = mapAuthSupportResponseStorageToAuthSupportResponse(
        result,
        email,
      ).user;
      setUser(userNew);
      setIsChatOnline(userNew.state === 'true');
      localStorageHelper.setUserDetails({
        email,
        id: userNew.id,
        token: userNew.token,
      });
      changeLanguage(userNew.locale);
    } else {
      setErrorLogin(true);
    }
  };
  return (
    <div>
      <div className="relative flex h-screen w-full flex-col items-center justify-center bg-white ">
        <div className="absolute inset-x-0 -top-25 h-120 -skew-y-12 bg-bgDecorationLogin"></div>
        <div className="login__container relative flex flex-col items-center">
          <Logo hasBackground={false} size={'medium'} />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={`z-50 flex flex-col items-center gap-3 rounded-1 bg-white px-8 py-5 shadow-login ${
              errorLogin ? 'shake' : ''
            }`}>
            <LoginFormField
              name="email"
              control={control}
              defaultValue=""
              rules={{required: true}}
              placeholder="email@domain.com"
              errors={errors.email ? t('validation.field_required') : null}
              labelText={t('generic.email')}
              setErrorLogin={setErrorLogin}
            />
            <LoginFormField
              name="password"
              placeholder={'********'}
              control={control}
              defaultValue=""
              rules={{required: true}}
              errors={errors.password ? t('validation.field_required') : null}
              labelText={t('generic.password')}
              setErrorLogin={setErrorLogin}
            />

            {errorLogin && (
              <div className="mb-5 mt-2 flex items-center justify-start rounded-1.5 bg-semantic-danger-100 px-5 py-2.5">
                <img
                  src={`${AMAZON_URL}/images/ic_forbidden.png`}
                  alt="Forbidden icon"
                />
                <p className="ms-3 text-start text-3.5 text-text-600">
                  {t('auth.incorrect_email_password')}
                </p>
              </div>
            )}
            <div>
              <Button
                onFocus={(e) => e.target.blur()}
                type="submit"
                buttonType="button-primary"
                text={t('auth.login')}
                loading={loading}
                size="medium"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
