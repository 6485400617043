import React from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';
import {Text} from '@ifeelonline/storybook';

import {RootState} from 'src/state';

export const TextSearcher = (status: SharedActionType) => {
  const {t} = useTranslation();
  const {conversation, messages} = useSelector(
    (state: RootState) => state.conversation,
  );

  let text = '';

  switch (status) {
    case SharedActionType.LOADING:
      text = t('chat.searcher.loading');
      break;
    case SharedActionType.IDLE:
      text = `${t('chat.searcher.with', {
        who: conversation?.patientNickname || '',
      })}.${t('chat.searcher.min_2_chars')}`;
      break;
    case SharedActionType.FAILED:
      text = t('chat.searcher.error');
      break;
    case SharedActionType.NOT_FOUND:
      text = t('chat.searcher.messages_not_found');
      break;
    default:
      text = '';
      break;
  }

  return (
    <Text
      textType={
        [SharedActionType.NOT_FOUND, SharedActionType.FAILED].includes(status)
          ? 'body-3'
          : 'caption'
      }
      fontColor={
        status === SharedActionType.FAILED
          ? 'text-semantic-danger-700'
          : 'text-text-400'
      }>
      {text}
    </Text>
  );
};
