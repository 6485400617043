import {
  AuthSupportResponse,
  AuthSupportResponseStorage,
} from '@ifeelonline/chat-core';

export const mapAuthSupportResponseStorageToAuthSupportResponse = (
  response: AuthSupportResponseStorage,
  email: string,
): AuthSupportResponse => {
  return {
    authToken: response.auth_token,
    user: {
      id: response.user.id,
      locale: response.user.locale,
      name: response.user.name,
      partner: response.user.partner,
      role: response.user.role,
      state: response.user.state ? 'true' : 'false',
      actionCableToken: response.user.action_cable_token,
      chatState: false,
      token: response.auth_token,
      email: email,
      uid: response.user.uid,
    },
  };
};
