import {useEffect, useState} from 'react';

import {Loading} from '@ifeelonline/storybook';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {TriageSurveyRedux} from 'src/state/reducers/conversationReducer';
import TriageSurveyRepository from 'src/services/TriageSurveyRepository';
import {TriageSurveySection} from 'src/pages/chat/types/TriageSurveys';
import {SurveyStatus} from 'src/pages/chat/types/Surveys';
import {useAppDispatch} from 'src/hooks/hooks';

import {TriageSurveyCompleted} from './TriageSurveyCompleted';
import {TriageSurveyForm} from './TriageSurveyForm';

export const TriageSurvey = ({
  triageSurvey,
  conversationId,
}: {
  triageSurvey: TriageSurveyRedux;
  conversationId: number;
}) => {
  const dispatch = useAppDispatch();

  if (!triageSurvey.form.id) return null;

  const [triageSurveySections, setTriageSurveySections] = useState<
    TriageSurveySection[] | null
  >(null);

  const fetchTriageSurvey = async (id: number) => {
    const response = await TriageSurveyRepository.getSurvey(id, conversationId);
    if (response) setTriageSurveySections(response);

    dispatch({
      type: ConversationActionType.TRIAGE_SURVEY_FORM_LOADING,
      payload: false,
    });
    setTriageSurveySections(response);
  };

  useEffect(() => {
    if (triageSurvey?.form.id) fetchTriageSurvey(triageSurvey.form.id);
  }, [triageSurvey?.form.id]);

  return (
    <div className="flex w-91.5 flex-col justify-between border-l border-solid border-borders-aiQuestionnaire bg-white">
      {!triageSurveySections ? (
        <Loading />
      ) : triageSurvey.form.status === SurveyStatus.SAVED ? (
        <TriageSurveyCompleted
          triageSurveyForm={triageSurvey.form}
          triageSurveySections={triageSurveySections}
        />
      ) : (
        <TriageSurveyForm
          triageSurveyForm={triageSurvey.form}
          triageSurveySections={triageSurveySections}
          conversationId={conversationId}
        />
      )}
    </div>
  );
};
