import {useTranslation} from 'react-i18next';

import {VideoSessionsRecord} from '@ifeelonline/chat-core';
import {Button} from '@ifeelonline/storybook';

import moment from 'moment';

import ArrowCaretLeft from 'src/assets/images/icons/arrow_caret_left_blue.svg?react';
import CheckFilledSvg from 'src/assets/images/icons/check_filled.svg?react';
import VCAvailableSvg from 'src/assets/images/icons/vc_available.svg?react';
import InfoSvg from 'src/assets/images/icons/info.svg?react';
import CollapseComp from 'src/components/CollapseComp';
import {CardWhite} from 'src/components/CardWhite';

import {VideoSessionsHeldHeader} from './video_sessions/VideoSessionsHeldHeader';

export const VideoSessionsInfoCard = ({
  videoSessionsRecord,
}: {
  videoSessionsRecord: VideoSessionsRecord;
}) => {
  const {t} = useTranslation();
  const {
    videoSessionsLength: sessionsPerMonth,
    videoSessionsDates: sessionsDate,
    videoSessions: sessionsHeld,
  } = videoSessionsRecord;

  const videoSessionAvailable = () =>
    sessionsHeld && sessionsPerMonth && sessionsHeld < sessionsPerMonth
      ? sessionsPerMonth - sessionsHeld
      : 0;

  return (
    <CardWhite className="p-0">
      {sessionsDate && sessionsHeld && sessionsHeld > 0 ? (
        <CollapseComp
          buttonIcon={
            <div className="mx-5 flex h-full w-7 border-brandPrimary-600 p-0.5">
              <Button
                buttonType="icon-button-secondary"
                size="small"
                icon={<ArrowCaretLeft className="rotate-90" />}
              />
            </div>
          }
          buttonChildren={
            <VideoSessionsHeldHeader sessionsHeld={sessionsHeld} />
          }
          collapseChildren={
            <ul className="border-t border-borderConversation bg-white ps-10">
              {sessionsDate.map((session: string, i: number) => (
                <li key={i} className="flex h-11.5 items-center !justify-start">
                  <CheckFilledSvg />
                  <p className="border-text-text-300 border-r px-2.5 text-4 text-text-600">
                    {t('videosessions.session')} {sessionsDate.length - i}
                  </p>
                  <span className="font-4 ml-2.5 text-text-400">
                    {moment(session).format('DD/MM/YYYY')}
                  </span>
                </li>
              ))}
            </ul>
          }
          className="justify-between"
        />
      ) : (
        <VideoSessionsHeldHeader sessionsHeld={0} />
      )}
      <div className="border-t border-borderConversation p-5">
        <h3 className="mb-2.5 font-sofia text-4 font-semibold text-text-600">
          <>
            <VCAvailableSvg className="mr-1 inline" />
            {videoSessionAvailable} {t('videosessions.vs_available')}
          </>
        </h3>
        <p className="mt-4 flex !items-center !justify-start text-3.5 text-text-300">
          <InfoSvg className="mr-1 inline" />
          {t('videosessions.reminder')}
        </p>
      </div>
    </CardWhite>
  );
};
