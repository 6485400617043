import {Conversation} from '@ifeelonline/chat-core';

import moment from 'moment';

import i18n from 'src/i18n';

export function changeLanguage(lng: string) {
  i18n.changeLanguage(lng);
  moment.locale(lng as string);
  document.body.dir = i18n.dir();
}

export const isHebrewPatient = (conversation: Conversation) =>
  conversation?.patientLocale === 'he';
