import React from 'react';

type MenuSpanProps = {
  variant:
    | 'unanswered-filter'
    | 'unread-msg-filter'
    | 'b2b-filter'
    | 'b2c-filter'
    | 'limbo-filter'
    | 'maccabi-filter'
    | 'maccabi-scheduled-filter'
    | 'no-agent-filter';
};

export const MenuSpan = ({variant}: MenuSpanProps) => {
  const MenuSpanStyles = {
    base: 'flex',
    variants: {
      'unanswered-filter': 'w-2 h-2 bg-brandSalmon-700 rounded-full',
      'unread-msg-filter': 'w-2 h-2 bg-blue-500 rounded-full',
      'b2b-filter': 'w-3 h-3 bg-red-200 rounded-full',
      'b2c-filter': 'w-3 h-3 bg-blue-200 rounded-full',
      'limbo-filter': 'w-3 h-3 bg-gray-400 rounded-full',
      'maccabi-filter': 'w-2 h-2  bg-semantic-warning-700 rounded-full',
      'maccabi-scheduled-filter':
        'w-2 h-2 bg-brandComplementaryAquaMarina rounded-full',
      'no-agent-filter': 'text-2.5 bg-blue-300 rounded text-white px-1 py-0.5',
    },
  };

  const classes = `${MenuSpanStyles.base} ${
    MenuSpanStyles.variants[variant] || ''
  }`;

  return (
    <div className="flex w-10 items-center justify-center">
      <span className={classes}>
        {variant === 'no-agent-filter' ? 'New' : ''}
      </span>
    </div>
  );
};
