import React from 'react';

import {useTranslation} from 'react-i18next';

import {
  ChatProps,
  LayoutMessageType,
  Message,
  formatDateBadgeChat,
} from '@ifeelonline/chat-core';
import {DateBadge} from '@ifeelonline/storybook';

import dayjs, {Dayjs} from 'dayjs';

import {RenderAutomaticMessage} from './RenderAutomaticMessage';
import {RenderMessage} from './RenderMessage';

interface Props {
  messages: Message[];
  msgRefs: ChatProps['msgRefs'];
  isFavoritesView: boolean;
  messagesDiv: ChatProps['messagesDiv'];
  setFirstMsgRefId?: React.Dispatch<React.SetStateAction<number | null>>;
}

export const RenderMessages = ({
  messages,
  msgRefs,
  isFavoritesView,
  messagesDiv,
  setFirstMsgRefId,
}: Props) => {
  let previousDate: Dayjs | null = null;
  const {t} = useTranslation();

  return (
    <>
      {messages.map((message) => {
        const messageDate = dayjs(message.receivedOn);
        const isNewDate = !messageDate.isSame(previousDate, 'day');
        previousDate = messageDate;

        const shouldRenderAutomaticMessage =
          msgRefs &&
          message.layout === LayoutMessageType.AUTOMATIC_MSG_VIDEO_CALL &&
          message.data;

        return (
          <div key={message.id} className="overflow-hidden">
            {isNewDate && (
              <DateBadge
                dateString={formatDateBadgeChat(
                  messageDate,
                  t('calendar.today'),
                  t('calendar.yesterday'),
                )}
              />
            )}
            {shouldRenderAutomaticMessage ? (
              <RenderAutomaticMessage message={message} />
            ) : (
              <RenderMessage
                message={message}
                msgRefs={msgRefs}
                isFavoritesView={isFavoritesView}
                messagesDiv={messagesDiv}
                setFirstMsgRefId={setFirstMsgRefId}
              />
            )}
          </div>
        );
      })}
    </>
  );
};
