import {Message} from '@ifeelonline/chat-core';

import {UiActionType} from 'src/state/action_types/UiActionType';

export const uiEditMsg = (message: Message | null) => ({
  type: UiActionType.UI_EDIT_MSG,
  payload: message,
});
export const uiReplyTo = (message: Message | null) => ({
  type: UiActionType.UI_REPLY_TO,
  payload: message,
});
