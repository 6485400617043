import React, {useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {changeAgent} from 'src/state/action_creators/conversationActionCreator';
import agent_white from 'src/assets/images/chat/sidebar-blue/agent-white.svg';
import {useChat} from 'src/providers/ChatProvider';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {agentGreetingMessage} from '../../utils/agent';

export const AgentSelector = () => {
  const {activeAgents, defaultAgentPosition} = useSelector(
    (state: RootState) => state.conversations,
  );
  const {conversation} = useSelector((state: RootState) => state.conversation);
  const [agentSelected, setAgentSelected] = useState('');
  const {handleInputChange} = useChat();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  if (!conversation || !conversation.id) return null;

  const handleSelectChange = ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>) => {
    setAgentSelected(target.value);
    if (!conversation) return;
    dispatch(
      changeAgent({
        conversationId: conversation.id,
        agentId: Number(target.selectedOptions[0].id),
        agentName: target.selectedOptions[0].text ?? '',
        conversationStatus: conversation.status!,
      }),
    ).then(() => {
      toast.success(t('chat.assign_agent.new_agent_assigned'));
      handleInputChange({
        target: {
          name: 'messageBody',
          value: agentGreetingMessage(
            t,
            conversation,
            target.selectedOptions[0].text,
          ),
        },
      } as React.ChangeEvent<HTMLTextAreaElement>);
    });
  };

  useEffect(() => {
    setAgentSelected('');
    if (activeAgents && conversation) {
      const {myAgent} = conversation;

      if (typeof myAgent !== 'string' && myAgent?.agent?.position) {
        setAgentSelected(myAgent.agent.position.toString());
      }
    }
  }, [conversation]);

  return (
    <div className="">
      <div className="flex h-14 w-full items-center justify-between px-5 py-2.5 text-white hover:bg-borders-sidebarHover disabled:opacity-50">
        <div>
          <img src={agent_white} alt="Agent" className="inline-block" />
          <span className="ms-2 font-sofia text-4 font-normal">
            {t('chat_options.assigned_agent')}
          </span>
        </div>
      </div>
      <form className="new-styles-form px-2.5 py-1">
        {activeAgents && (
          <select
            className="w-full bg-backgrounds-12 text-white"
            value={agentSelected}
            onChange={handleSelectChange}
            disabled={conversation ? false : true}>
            <option
              value={
                defaultAgentPosition ? defaultAgentPosition.toString() : ''
              }
              disabled>
              {t('ai.form.select')}
            </option>
            {activeAgents.map(
              (agent, index: number) =>
                agent.id && (
                  <option
                    key={index}
                    value={agent.position!}
                    id={agent.id.toString()}>
                    {agent.nickname}
                  </option>
                ),
            )}
          </select>
        )}
      </form>
    </div>
  );
};
