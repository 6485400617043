import {useEffect, useRef, useState} from 'react';

import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {MessageRefs} from '@ifeelonline/chat-core';
import {Loading} from '@ifeelonline/storybook';

import {ChatChannelSubscriber} from 'src/action_cable/subscribers/ChatChannelSubscriber';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {getConversation} from 'src/state/action_creators/conversationActionCreator';
import {useUser} from 'src/providers/UserProvider';
import {useAppDispatch} from 'src/hooks/hooks';
import {Navbar} from 'src/components/Navbar';
import {RootState} from 'src/state';

import {RenderSearcherMessages} from '../chat/components/chat_section/RenderSearcherMessages';
import {INITIAL_PAGE} from '../chat/constants/chat';

import {ChatSinglePageContent} from './components/ChatSinglePageContent';

export const ChatSinglePage = () => {
  const {id} = useParams<string>();
  const dispatch = useAppDispatch();
  const messagesDiv = useRef<any>(null); //@TODO TEMP ANY TO FIX THE TYPES OF THE FOWARD REF
  const [showSearcher, setShowSearcher] = useState(false);
  const [firstMsgRefId, setFirstMsgRefId] = useState<number | null>(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {user} = useUser();

  const {conversation} = useSelector((state: RootState) => state.conversation);
  const msgRefs = useRef<MessageRefs>({});

  useEffect(() => {
    if (id) {
      dispatch(getConversation(parseInt(id), 1));
    }
    msgRefs.current = {};
  }, [id]);

  const readyForSubscription =
    id && user && conversation && conversation.patientId;

  const getFirstPage = () => {
    setIsFirstLoad(true);
    dispatch({type: ConversationActionType.RESET_INITIAL_STATE});

    if (conversation && conversation.id)
      dispatch(getConversation(conversation.id, INITIAL_PAGE)).then(() => {
        dispatch({
          type: ConversationActionType.SET_PAGES_LOADED,
          payload: [INITIAL_PAGE],
        });
      });
  };

  return (
    <div
      className={`home__screen w-full ${
        showSearcher && 'max-w-full-minus-84'
      }`}>
      <Navbar isSingleConversation={true} />
      {showSearcher && (
        <RenderSearcherMessages
          messagesDiv={messagesDiv}
          closeSearcher={() => setShowSearcher(false)}
          setIsLoading={setIsLoading}
          msgRefs={msgRefs}
          setFirstMsgRefId={setFirstMsgRefId}
        />
      )}

      {readyForSubscription ? (
        <>
          <ChatChannelSubscriber
            userId={conversation.patientId!}
            clientId={user.uid}
          />
          <ChatSinglePageContent
            setShowSearcher={setShowSearcher}
            messagesDiv={messagesDiv}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            showSearcher={showSearcher}
            msgRefs={msgRefs}
            isFirstLoad={isFirstLoad}
            setIsFirstLoad={setIsFirstLoad}
            getFirstPage={getFirstPage}
            firstMsgRefId={firstMsgRefId}
            setFirstMsgRefId={setFirstMsgRefId}
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};
