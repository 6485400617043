import {MeetingStorage, VideoCall} from '@ifeelonline/chat-core';

import {fetchWrapper} from 'src/helpers/fetch';

export const getSignature = (meetingNumber: string, role: number) => {
  return async () => {
    const resp = await fetchWrapper({
      endPoint: `video_calls/signature?meeting_number=${meetingNumber}&role=${role}`,
    });

    if (!resp) return null;

    return resp.body.signature;
  };
};

export const startVideoCall = ({
  meeting,
  conversationId,
  agentId,
}: {
  conversationId: number;
  meeting: MeetingStorage;
  videoCall: VideoCall | undefined | null;
  agentId: number;
}) => {
  return async (dispatch: (dispatch: any) => Promise<void>) => {
    dispatch(createVideoCall(meeting, conversationId, agentId));
  };
};

export const createVideoCall = (
  meeting: MeetingStorage,
  conversationId: number,
  agentId: number,
) => {
  return async () => {
    const resp = await fetchWrapper({
      endPoint: `video_calls`,
      data: {
        conversation_id: Number(conversationId),
        status: true,
        agent_id: agentId,
        meeting_id: meeting.meeting_id,
        meeting_pass: meeting.password,
      },
      method: 'POST',
    });

    if (!resp) return null;
    sessionStorage.setItem('videocallId', resp.body.video_call.id);
    return true;
  };
};

export const getVideoCallInfo = (conversationId: number) => {
  return async () => {
    const resp = await fetchWrapper({
      endPoint: `video_calls/start_zoom_meeting?conversation_id=${conversationId}`,
    });

    if (!resp) return null;

    return resp.body;
  };
};

export const endVideoCall = (videoCallId: number) => {
  return async () => {
    const resp = await fetchWrapper({
      endPoint: `video_calls/terminate/${videoCallId}`,
      data: {
        status: false,
      },
      method: 'PUT',
    });
    if (resp && resp.status.toString().startsWith('20')) {
      return true;
    }
    return null;
  };
};
