import {useState} from 'react';

import {useTranslation} from 'react-i18next';

import {InputErrorMessage, Text} from '@ifeelonline/storybook';

import {
  SurveyAnswer,
  SurveyQuestion,
  SurveyQuestionOption,
} from 'src/pages/chat/types/Surveys';
import {getQuestionType} from 'src/pages/chat/utils/surveys/logic';

import {useSurveys} from '../../hooks/useSurveys';

export const RadioButtonCommentFormField = ({
  questionOptions,
  selectedOption,
  handleRadioButtonChange,
  error,
  question,
  conversationId,
}: {
  questionOptions: SurveyQuestionOption[];
  selectedOption: number | null;
  handleRadioButtonChange: (optionId: number) => void;
  error: string | null;
  question: SurveyQuestion;
  conversationId: number;
}) => {
  const [comment, setComment] = useState<string>(
    question.answer?.comment || '',
  );
  const [optionIds, setOptionIds] = useState<number[] | undefined>(
    question.answer?.optionId || undefined,
  );

  const {updateAnswers} = useSurveys({question, conversationId});
  const {t} = useTranslation();

  const OptionText = (option: SurveyQuestionOption) => (
    <Text textType="body-3" fontColor="text-text-400">
      {option.body}
    </Text>
  );

  const handleBlur = () => {
    const newAnswer: SurveyAnswer = {
      questionId: question.id,
      optionIds,
      comment,
    };

    updateAnswers(newAnswer);
  };

  const showOptionsInColumn = questionOptions.length > 2;
  const showLabelInColumn =
    question.questionOptions && question.questionOptions?.length < 5;

  const getQuestionOptionStyle = (
    showOptionsInColumn: boolean,
    showLabelInColumn: boolean | undefined,
  ) => {
    if (showOptionsInColumn) return 'items-center gap-2';
    if (showLabelInColumn) return 'flex-row-reverse items-center gap-1';
    return 'flex-col items-center justify-center';
  };

  return (
    <div className="flex flex-col gap-3">
      <div
        className={`flex ${
          showOptionsInColumn ? 'flex-col justify-between gap-3' : 'gap-2'
        }`}>
        {questionOptions &&
          questionOptions.map((option) => (
            <div
              key={option.id}
              className={`flex ${getQuestionOptionStyle(
                showOptionsInColumn,
                showLabelInColumn,
              )}`}>
              {!showOptionsInColumn && OptionText(option)}

              <input
                type="radio"
                value={option.id}
                checked={selectedOption === option.id}
                className="size-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500"
                onChange={() => {
                  setOptionIds([option.id]);
                  updateAnswers({
                    questionId: question.id,
                    type: getQuestionType(question.tag),
                    optionIds: [option.id],
                  });
                  handleRadioButtonChange(option.id);
                }}
              />

              {showOptionsInColumn && OptionText(option)}
            </div>
          ))}
        <div onBlur={handleBlur}>
          <input
            className="h-10 w-full rounded-1.5 border border-gray-300 text-neutral-400"
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={t('surveys.form.questions.comment_placeholder')}
          />
        </div>
      </div>
      <InputErrorMessage text={error || ''} />
    </div>
  );
};
