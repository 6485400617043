import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';
import toast from 'react-hot-toast';

import {ChatStatusType, LayoutMessageType} from '@ifeelonline/chat-core';
import {UploadFileModal} from '@ifeelonline/storybook';

import {sendMessageFormData} from 'src/state/action_creators/conversationActionCreator';
import {useChatTranslations} from 'src/pages/chat/hooks/useChatTranslations';
import {RenderUploadFileModalProps as Props} from 'src/types/Modals';
import {useChat} from 'src/providers/ChatProvider';
import {useUser} from 'src/providers/UserProvider';
import {useAppDispatch} from 'src/hooks/hooks';

export const RenderUploadFileModal = ({conversation, close, isOpen}: Props) => {
  const {t} = useTranslation();
  const {user} = useUser();
  const {setChatStatus} = useChat();
  const dispatch = useAppDispatch();
  const {uploadFileTranslations} = useChatTranslations();

  const [isUploading, setIsUploading] = useState(false);
  const [previewFile, setPreviewFile] = useState<string | null>(null);

  const handleSendFile = async (selectedFile: File | null) => {
    setIsUploading(true);
    const formData = new FormData();
    if (selectedFile && conversation?.id && user?.uid) {
      formData.append('file', selectedFile);
      formData.append('body', 'file');
      formData.append('conversation_id', conversation.id.toString());
      formData.append('layout', LayoutMessageType.FILE.toString());
      formData.append('patient_id', user.uid.toString());
      formData.append('user_id', user.uid.toString());

      dispatch(sendMessageFormData(formData)).then((res) => {
        if (res === null) toast.error(t('modals.upload_file_error'));
      });
      setChatStatus(ChatStatusType.DEFAULT);
      setPreviewFile(null);
      setIsUploading(false);
      close();
    } else {
      toast.error(t('errors.generic.contact_admin'));
    }
  };

  return (
    <UploadFileModal
      showFileModal={isOpen}
      closeModal={close}
      translations={uploadFileTranslations}
      handleSendFile={handleSendFile}
      isUploading={isUploading}
      previewFile={previewFile}
      setPreviewFile={setPreviewFile}
    />
  );
};
