import {useTranslation} from 'react-i18next';

import {PlanType} from '@ifeelonline/chat-core';

import emotional_support_icon from 'src/assets/images/plan_icons/emotional_support.svg';
import no_plan_icon from 'src/assets/images/plan_icons/no_plan.svg';
import video_icon from 'src/assets/images/plan_icons/video.svg';
import chat_icon from 'src/assets/images/plan_icons/chat.svg';

export const PlanTypeFunctions = () => {
  const {t} = useTranslation();

  const planTypeIcon = (planType: PlanType) => {
    switch (planType) {
      case 'noPlan':
        return no_plan_icon;
      case 'video':
        return video_icon;
      case 'emotionalSupport':
        return emotional_support_icon;
      default:
        return chat_icon;
    }
  };

  const planTypeText = (planType: PlanType) => {
    switch (planType) {
      case 'noPlan':
        return t('plan_type.no_plan');
      case 'video':
        return t('plan_type.video');
      case 'emotionalSupport':
        return t('plan_type.emotional_support');
      default:
        return t('plan_type.chat');
    }
  };

  return [planTypeIcon, planTypeText];
};
