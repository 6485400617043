import React from 'react';

import {useTranslation} from 'react-i18next';

import {Text} from '@ifeelonline/storybook';

export const AIQuestionnaireTitle = () => {
  const {t} = useTranslation();
  return (
    <div className="flex gap-5">
      <div className="flex w-full flex-col gap-3 border-b border-solid border-borders-aiQuestionnaire py-6 text-center">
        <Text
          textType={'subtitle'}
          fontWeight="semibold"
          fontColor="text-brandPrimary-700">
          {t('ai.form.title')}
        </Text>
        <Text textType={'body-3'} fontColor="text-text-400">
          {t('ai.form.info')}
        </Text>
      </div>
    </div>
  );
};
