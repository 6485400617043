import {Conversation} from '@ifeelonline/chat-core';

import {t} from 'i18next';

import {
  aiQuestionnairePossibleResult,
  getIaOption,
} from 'src/pages/chat/utils/ai_questionnaire/renderInformation';
import {
  AIQuestionnaireForm,
  AIQuestionnaireStatus,
} from 'src/pages/chat/types/AIQuestionnaire';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import AIQuestionnaireRepository from 'src/services/AIQuestionnaireRepository';
import {useAppDispatch} from 'src/hooks/hooks';
import {notify} from 'src/helpers/notify';
import {AMAZON_URL} from 'src/constants';

import {AIQuestionnaireResult} from '../components/AIQuestionnaireResult';
import {AIAgentAssessment} from '../components/AIAgentAssessment';
import {SidePanelSection} from '../components/SidePanelSection';

export const AISidePanelSection = ({
  aiQuestionnaireForm,
  conversation,
}: {
  aiQuestionnaireForm: AIQuestionnaireForm;
  conversation: Conversation;
}) => {
  const dispatch = useAppDispatch();

  const updateQuestionnaire = async () => {
    dispatch({
      type: ConversationActionType.AI_QUESTIONNAIRE_FORM_OPEN,
      payload: true,
    });

    if (
      aiQuestionnaireForm.status === AIQuestionnaireStatus.START ||
      aiQuestionnaireForm.status === AIQuestionnaireStatus.DRAFT
    ) {
      const result = await AIQuestionnaireRepository.changeSurveyStatus(
        conversation.id.toString(),
        AIQuestionnaireStatus.EDITING,
      );

      if (result) {
        dispatch({
          type: ConversationActionType.SET_STATUS_AI_QUESTIONNAIRE_FORM,
          payload: {status: AIQuestionnaireStatus.EDITING},
        });
      } else
        notify({
          text: t('ai.toast.error.response'),
          type: 'error',
          duration: 500000,
        });
    }
  };

  return (
    <SidePanelSection
      icon={`${AMAZON_URL}/images/ic_ia.png`}
      title={t('ai.side_panel.title')}>
      <ul>
        {getIaOption(
          aiQuestionnaireForm.isLoading
            ? 'loading'
            : aiQuestionnaireForm.status,
          aiQuestionnaireForm.agent,
          aiQuestionnaireForm.status !== AIQuestionnaireStatus.SENT
            ? () => updateQuestionnaire()
            : () =>
                dispatch({
                  type: ConversationActionType.AI_QUESTIONNAIRE_FORM_DISPLAY_OPEN,
                  payload: true,
                }),
        )}

        {aiQuestionnaireForm.result && (
          <>
            <AIQuestionnaireResult
              aiQuestionnaireResult={aiQuestionnaireForm.result}
              aiQuestionnairePossibleResult={aiQuestionnairePossibleResult}
            />
            <AIAgentAssessment
              aiQuestionnaireResult={aiQuestionnaireForm.result}
            />
          </>
        )}
      </ul>
    </SidePanelSection>
  );
};
