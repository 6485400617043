import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';

import TRANSLATIONS_EN from './locales/en.json';
import TRANSLATIONS_ES from './locales/es.json';
import TRANSLATIONS_FR from './locales/fr.json';
import TRANSLATIONS_HE from './locales/he.json';
import TRANSLATIONS_PT from './locales/pt.json';
import TRANSLATIONS_DE from './locales/de.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
        dir: 'ltr',
      },
      es: {
        translation: TRANSLATIONS_ES,
        dir: 'ltr',
      },
      fr: {
        translation: TRANSLATIONS_FR,
        dir: 'ltr',
      },
      pt: {
        translation: TRANSLATIONS_PT,
        dir: 'ltr',
      },
      de: {
        translation: TRANSLATIONS_DE,
        dir: 'ltr',
      },
      he: {
        translation: TRANSLATIONS_HE,
        dir: 'rtl',
      },
    },
  });

export default i18n;
