import {TFunction} from 'react-i18next';

import {Conversation} from '@ifeelonline/chat-core';

import {isHebrewPatient} from 'src/helpers/translations';
import {isPatientB2C} from 'src/helpers/patients';

export const agentGreetingMessage = (
  t: TFunction,
  conversation: Conversation | null,
  agentNickname: string,
) => {
  const messageKey =
    conversation && isPatientB2C(conversation) && isHebrewPatient(conversation)
      ? 'chat.agent_greeting_b2c'
      : 'chat.agent_greeting';

  return t(messageKey, {
    nickname: agentNickname,
    lng: conversation?.patientLocale ?? '',
  });
};
