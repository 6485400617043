import {
  TriageSurveyList,
  TriageSurveyListStorage,
  TriageSurveySection,
  TriageSurveySectionsStorage,
  TriageSurveyStorage,
} from 'src/pages/chat/types/TriageSurveys';

import {mapApiResponseSurveyQuestions} from './survey';

export function mapSurveyListStatus(
  jsonBack: TriageSurveyListStorage,
): TriageSurveyList {
  return {
    surveys: {
      mandatory: mapSurveyStatus(jsonBack.surveys.mandatory),
      optional: jsonBack.surveys.optional.map((survey) =>
        mapSurveyStatus(survey),
      ),
      intake: mapSurveyStatus(jsonBack.surveys.intake),
    },
    isTriageCompleted: jsonBack.triage_completed,
  };
}

function mapSurveyStatus(jsonBack: TriageSurveyStorage) {
  return {
    id: jsonBack.id,
    name: jsonBack.name,
    status: jsonBack.status,
    title: jsonBack.name,
    score: jsonBack.score,
  };
}

export function mapSurveySections(
  jsonBack: TriageSurveySectionsStorage,
): TriageSurveySection[] {
  return jsonBack.sections.map((jsonBack) => {
    return {
      id: jsonBack.id,
      openingQuestion: jsonBack.opening_question,
      questions: mapApiResponseSurveyQuestions(jsonBack.questions),
    };
  });
}
