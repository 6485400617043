import {TFunction} from 'react-i18next';

import {
  SurveyAnswer,
  SurveyAnswerType,
  SurveyQuestion,
  SurveyQuestionTag,
  SurveyStatus,
} from 'src/pages/chat/types/Surveys';

export const getQuestionType = (
  tag: SurveyQuestionTag,
): SurveyAnswer['type'] => {
  switch (tag) {
    case SurveyQuestionTag.Agent:
      return SurveyAnswerType.Agent;
    case SurveyQuestionTag.Text:
      return SurveyAnswerType.Comment;
    default:
      return SurveyAnswerType.Options;
  }
};

export const statusToText = (status: SurveyStatus, t: TFunction) => {
  switch (status) {
    case SurveyStatus.DRAFT:
      return t('surveys.status.draft');
    case SurveyStatus.EDITING:
      return t('surveys.status.editing');
    case SurveyStatus.SAVED:
      return t('surveys.status.finished');
    default:
      return t('surveys.status.start');
  }
};

export const getAnswers = (
  id: number,
  answer: SurveyAnswer | undefined,
  questionsAi: SurveyQuestion[] | null,
) => {
  if (answer?.comment) return answer.comment;

  if (answer?.agentId) {
    const options = questionsAi?.find((item) => item.id === id);
    const value = options?.questionOptions?.find(
      (item) => item.agentId === answer.agentId,
    );

    return value?.nickname;
  }

  if (answer?.optionId) {
    const answersDescription = answer.optionId.map((optionId) => {
      if (optionId) {
        const options = questionsAi?.find((item) => item.id === id);
        const value = options?.questionOptions?.filter(
          (item) => item.id === optionId,
        );

        if (value) return value[0].body;
      }
      return null;
    });

    return answersDescription.join(', ');
  }
};
