import {Message} from '@ifeelonline/chat-core';

import {UiActionType} from 'src/state/action_types/UiActionType';
import {UiAction} from 'src/state/actions/ui';

interface uiState {
  editMsg: Message | null;
  replyTo: Message | null;
}

const initialState = {
  editMsg: null,
  replyTo: null,
};

export const uiReducer = (state: uiState = initialState, action: UiAction) => {
  switch (action.type) {
    case UiActionType.UI_EDIT_MSG:
      return {
        ...state,
        editMsg: action.payload,
      };

    case UiActionType.UI_REPLY_TO:
      return {
        ...state,
        replyTo: action.payload,
      };

    default:
      return state;
  }
};
