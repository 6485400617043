import {PlanType, SupportMatchStatus} from '@ifeelonline/chat-core';

import {
  ConversationListItem,
  ConversationListItemStorage,
} from 'src/types/ConversationList';
import {AgentChannel} from 'src/action_cable/types/agentChannelDataTypes';

export function mapApiResponseConversationList(
  jsonBack: ConversationListItemStorage | AgentChannel['NewUserData'],
  typing: boolean,
) {
  const conversation: ConversationListItem = {
    id: jsonBack.id,
    patientId: jsonBack.patient_id,
    isNew: jsonBack.is_new ?? false,
    enabled: jsonBack.enabled ?? false,
    agentId: jsonBack.agent_id ?? null,
    status: mapApiResponseSupportMatchStatus(jsonBack.status),
    agentName: jsonBack.agent_name ?? '',
    patientInactive: jsonBack.patient_inactive ?? false,
    patientTypePlan: mapApiResponsePlanType(jsonBack.patient_type_plan),
    patientNickname: jsonBack.patient_nickname ?? '',
    patientPartner: jsonBack.patient_partner ?? null,
    patientState: jsonBack.patient_state,
    unreadMessages: jsonBack.unread_messages ?? 0,
    typing,
    lastMessageReceivedOn: jsonBack.last_message_received_on ?? '',
    lastMessageSentBy: jsonBack.last_message_sent_by ?? null,
  };
  return conversation;
}

export function mapApiResponseSupportMatchStatus(
  status: ConversationListItemStorage['status'] | undefined,
) {
  switch (status) {
    case 'unmatched':
      return SupportMatchStatus.UNMATCHED;
    case 'matched':
      return SupportMatchStatus.MATCHED;
    case 'is_under_age':
      return SupportMatchStatus.IS_UNDER_AGE;
    case 'waiting':
      return SupportMatchStatus.WAITING;
    default:
      return SupportMatchStatus.WAITING;
  }
}

export function mapApiResponsePlanType(
  planType: ConversationListItemStorage['patient_type_plan'],
) {
  switch (planType) {
    case 'Chat':
      return PlanType.CHAT;
    case 'Video':
      return PlanType.VIDEO;
    case 'Emotional support':
      return PlanType.EMOTIONAL_SUPPORT;
    case 'No plan':
    default:
      return PlanType.NO_PLAN;
  }
}
