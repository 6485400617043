import {useContext, useEffect, useState} from 'react';

import {handleConversationChannelReceived} from 'src/action_cable/handlers/conversationChannelHandlers';
import {ConversationChannel} from 'src/action_cable/types/conversationChannelDataTypes';
import {ActionCableContext} from 'src/providers/ActionCableProvider';
import {useAppDispatch} from 'src/hooks/hooks';

export const useChatChannel = (userId?: number, clientId?: number) => {
  const dispatch = useAppDispatch();
  const cable = useContext(ActionCableContext);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (!userId || !clientId || !cable) {
      return;
    }
    const params = {
      channel: 'ConversationChannel',
      client_id: clientId,
      user_id: userId,
    };
    const handlers = {
      connected: () => {
        setIsConnected(true);
      },
      disconnected: () => {
        setIsConnected(false);
      },
      received: (dataResponse: ConversationChannel['DataResponse']) => {
        try {
          if (dataResponse?.action) {
            handleConversationChannelReceived(dataResponse, dispatch);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      },
    };
    const subscription = cable.subscriptions.create(params, handlers);
    return () => {
      subscription.unsubscribe();
    };
  }, [userId, clientId, cable]);

  return isConnected;
};
