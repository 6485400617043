import React, {createContext, useContext, useRef, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {ChatStatusType, useVisibility} from '@ifeelonline/chat-core';

import {Channel} from 'actioncable';

import {markAsReadConversation} from 'src/state/action_creators/conversationsActionCreator';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {ChatContext as ChatContextInterface} from 'src/types/ChatContext';
import {CHAT_FORM_INITIAL_VALUES} from 'src/pages/chat/constants/chat';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {useUser} from './UserProvider';

export const ChatContext = createContext<ChatContextInterface | undefined>(
  undefined,
);

function ChatProvider({children}: {children: React.ReactNode}) {
  const {conversation, status, newMessages} = useSelector(
    (state: RootState) => state.conversation,
  );
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const {currentAgentId} = useUser();
  const dispatchApp = useAppDispatch();
  const [conversationChannel, setConversationChannel] =
    useState<Channel | null>(null);
  const [imageBigUrl, setImageBigUrl] = useState<string | null>(null);
  const [formValues, setFormValues] = useState(CHAT_FORM_INITIAL_VALUES);
  const isVisibleLastMessage = useVisibility(messagesEndRef, {threshold: 0.1});
  const [chatStatus, setChatStatus] = useState<ChatStatusType>(
    ChatStatusType.DEFAULT,
  );

  const resetFormValues = (newFormState = CHAT_FORM_INITIAL_VALUES) => {
    setFormValues(newFormState);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChatStatus(
      event.target.value === ''
        ? ChatStatusType.DEFAULT
        : ChatStatusType.WRITING,
    );
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const setNewMessages = (messages: number | null) => {
    dispatch({
      type: ConversationActionType.SET_NEW_MESSAGES,
      payload: messages,
    });

    if (messages === null && conversation?.id) {
      dispatchApp(
        markAsReadConversation({
          conversationId: conversation?.id,
          agentId: currentAgentId,
        }),
      );
    }
  };

  return (
    <ChatContext.Provider
      value={{
        conversation,
        conversationChannel,
        formValues,
        chatStatus,
        handleInputChange,
        imageBigUrl,
        resetFormValues,
        setConversationChannel,
        setImageBigUrl,
        status,
        setChatStatus,
        isVisibleLastMessage,
        messagesEndRef,
        newMessages,
        setNewMessages,
      }}>
      {children}
    </ChatContext.Provider>
  );
}

function useChat() {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}

export {ChatProvider, useChat};
