import {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {ModalLayout, modalService} from '@ifeelonline/storybook';
import {ChatStatusType} from '@ifeelonline/chat-core';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import {
  sendCoupon,
  sendMessage,
} from 'src/state/action_creators/conversationActionCreator';
import {useChat} from 'src/providers/ChatProvider';
import {useUser} from 'src/providers/UserProvider';
import {ModalBaseProps} from 'src/types/Modals';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

export const SendCouponModal = ({isOpen, close}: ModalBaseProps) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const {user} = useUser();
  const {conversation} = useSelector((state: RootState) => state.conversation);
  const {couponsAvailable, plansAvailable} = useSelector(
    (state: RootState) => state.conversations,
  );
  const [planSelected, setPlanSelected] = useState<string | null>(null);
  const [couponSelected, setCouponSelected] = useState<string | null>(null);
  const {setChatStatus} = useChat();

  const closeModal = () => {
    setCouponSelected(null);
    setPlanSelected(null);
    close();
  };

  const handleSubmit = () => {
    modalService.fire({
      show: true,
      closeOutside: true,
      title: t('swal_questions.are_you_sure'),
      cancelButtonText: t('generic.no'),
      confirmButtonText: t('generic.yes'),
      confirmButtonAction: () => {
        dispatch(
          sendCoupon(conversation!.id!, couponSelected!, planSelected!),
        ).then((res) => {
          if (res) {
            dispatch(
              sendMessage({
                body: res,
                conversation_id: conversation!.id,
                patient_id: user!.uid,
                user_id: conversation!.patientId,
              }),
            );
          }
          setCouponSelected(null);
          setPlanSelected(null);
          setChatStatus(ChatStatusType.DEFAULT);
          close();
          modalService.close();
        });
      },
    });
  };

  return (
    <ModalLayout
      show={isOpen}
      closeModal={closeModal}
      confirmButtonText={t('chat_options.send_coupon')}
      confirmButtonAction={handleSubmit}
      isConfirmButtonDisabled={
        planSelected === null ||
        couponSelected === null ||
        planSelected === undefined ||
        couponSelected === undefined
      }
      title={t('chat_options.send_coupon')}
      layout="basic">
      <form className="flex flex-col gap-4">
        {couponsAvailable && (
          <Autocomplete
            id="coupon-selector"
            className="pt-1.5"
            options={couponsAvailable}
            size="small"
            getOptionLabel={(option) => option.id}
            onChange={(event, newValue) => {
              setCouponSelected(newValue?.id as string);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('coupons.select_coupon')}
                variant="outlined"
              />
            )}
          />
        )}
        {plansAvailable && (
          <Autocomplete
            id="plan-selector"
            className="pb-6"
            options={plansAvailable}
            size="small"
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setPlanSelected(newValue?.id as string);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('plans.select_plan')}
                variant="outlined"
              />
            )}
          />
        )}
      </form>
    </ModalLayout>
  );
};
