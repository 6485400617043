import {useTranslation} from 'react-i18next';

import {Dropdown, InputErrorMessage} from '@ifeelonline/storybook';
import {DropDownOption} from '@ifeelonline/chat-core';

import {SelectChangeEvent} from '@mui/material';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {SurveyQuestion, SurveyStatus} from 'src/pages/chat/types/Surveys';
import {useAppDispatch} from 'src/hooks/hooks';

import {useSurveys} from '../hooks/useSurveys';

export const DropdownFormField = ({
  options,
  selectedOption,
  handleChange,
  error,
  question,
  conversationId,
}: {
  options: DropDownOption[];
  selectedOption: string | number | null;
  handleChange: (event: SelectChangeEvent<unknown>) => void;
  error: string | null;
  question: SurveyQuestion;
  conversationId: number;
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {updateAnswers} = useSurveys({question, conversationId});
  const handleDropdownChange = (event: SelectChangeEvent<unknown>) => {
    const selectedOption: number | null =
      (event.target.value as number) ?? null;
    handleChange(event);

    if (question.tag === 'agent') {
      updateAnswers({
        questionId: question.id,
        agentId: selectedOption ?? undefined,
      }).then(() => {
        const agent = options.find((option) => option.value === selectedOption);
        const agentName = agent ? agent.text : '';
        dispatch({
          type: ConversationActionType.SET_STATUS_AI_QUESTIONNAIRE_FORM,
          payload: {status: SurveyStatus.EDITING, agent: agentName},
        });
      });
    } else {
      updateAnswers({
        questionId: question.id,
        optionIds: [selectedOption],
      });
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <Dropdown
        value={selectedOption ? selectedOption : undefined}
        options={options}
        hasBorder={true}
        disabled={false}
        errors={error ? true : false}
        onChange={handleDropdownChange}
        placeholder={t('ai.form.select')}
        height={'16px'}
      />
      <InputErrorMessage text={error || ''} />
    </div>
  );
};
