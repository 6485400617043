import {Control, Controller, FieldErrors, FieldValues} from 'react-hook-form';

import {InputErrorMessage, Textarea} from '@ifeelonline/storybook';

import {t} from 'i18next';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {createAnswer} from 'src/state/action_creators/conversationActionCreator';
import {AIQuestionNew} from 'src/pages/chat/types/AIQuestionnaire';
import {useAppDispatch} from 'src/hooks/hooks';

export const RenderTextArea = ({
  question,
  control,
  errors,
  conversationId,
}: {
  question: AIQuestionNew;
  conversationId: number;
  control: Control<FieldValues>;
  errors: FieldErrors<FieldValues>;
  handleBlur: () => () => Promise<void>;
}) => {
  const dispatch = useAppDispatch();

  return (
    <Controller
      name={question.id.toString()}
      control={control}
      defaultValue={question.answer?.comment || ''}
      rules={{required: question.required}}
      render={({field: {value, onChange, name}}) => (
        <div
          className="flex w-full flex-col"
          onBlur={async () => {
            await dispatch(createAnswer(conversationId, question)).then(
              (res) => {},
            );
          }}>
          <Textarea
            value={value}
            onChange={(e) => {
              onChange(e);
              dispatch({
                type: ConversationActionType.UPDATE_AI_ANSWERS,
                payload: {
                  questionId: question.id,
                  newAnswer: {
                    questionId: question.id,
                    comment: e.target.value,
                  },
                },
              });
            }}
            placeholder=""
            adjustHeightAutomatically
          />
          <InputErrorMessage
            text={
              errors[name]?.type === 'required'
                ? t('ai.form.error_required')
                : ''
            }
          />
        </div>
      )}
    />
  );
};
