import {useTranslation} from 'react-i18next';

import {Button, modalService} from '@ifeelonline/storybook';
import {Videocall} from '@ifeelonline/icons';

import {RouteVideoCall} from 'src/constants/Routes';
import {useChat} from 'src/providers/ChatProvider';
import {useUser} from 'src/providers/UserProvider';

export const VideocallButton = () => {
  const {conversation} = useChat();
  const {t} = useTranslation();
  const {currentAgentId} = useUser();

  const startVideoCall = () => {
    if (conversation?.id) {
      if (!currentAgentId) return;
      window.open(RouteVideoCall(conversation.id, currentAgentId), '_blank');
      modalService.close();
    }
  };

  const handleVideoCallClick = () => {
    modalService.fire({
      show: true,
      closeOutside: true,
      title: t('video_call.are_you_sure'),
      description: t('video_call.start_video_call_description'),
      cancelButtonText: t('generic.no'),
      confirmButtonText: t('generic.yes'),
      confirmButtonAction: startVideoCall,
      cancelButtonAction: () => {
        modalService.close();
      },
      closeModal: () => {
        modalService.close();
      },
      showCloseButtonX: true,
      reverseButtons: true,
    });
  };

  return (
    <div className="overflow-hidden rounded-full">
      <Button
        buttonType="icon-button-primary"
        onClick={handleVideoCallClick}
        icon={<Videocall size={20} />}
      />
    </div>
  );
};
