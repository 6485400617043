import {Patient, SharedActionType} from '@ifeelonline/chat-core';

import {PatientActionType} from '../action_types/PatientActionType';
import {PatientAction} from '../actions/patient';

interface PatientState {
  error: string | null | undefined;
  status: SharedActionType;
  patient: Patient | null;
}

const initialState = {
  patient: null,
  status: SharedActionType.IDLE,
  error: null,
};

export const patientReducer = (
  state: PatientState = initialState,
  action: PatientAction,
): PatientState => {
  switch (action.type) {
    case PatientActionType.PATIENT_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case PatientActionType.SET_PATIENT:
      return {
        ...state,
        patient: action.payload,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
