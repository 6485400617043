import {useState} from 'react';

import {useTranslation} from 'react-i18next';

import {Conversation} from '@ifeelonline/chat-core';
import {Button} from '@ifeelonline/storybook';

import {CaretDown, CaretUp} from 'phosphor-react';

import language_icon from 'src/assets/images/chat/sidebar-blue/language.svg';
import home_small from 'src/assets/images/chat/sidebar-blue/home_small.svg';
import country_icon from 'src/assets/images/chat/sidebar-blue/country.svg';
import desktop_icon from 'src/assets/images/chat/sidebar-blue/desktop.svg';
import user_fill from 'src/assets/images/chat/sidebar-blue/user-fill.svg';
import mail_icon from 'src/assets/images/chat/sidebar-blue/mail.svg';
import user_icon from 'src/assets/images/chat/sidebar-blue/user.svg';
import ID_icon from 'src/assets/images/chat/sidebar-blue/ID.svg';

export const UserInfoSection = ({
  conversation,
}: {
  conversation: Conversation;
}) => {
  const {t} = useTranslation();
  const [showInfo, setShowInfo] = useState(true);

  const toggleShowInfo = () => {
    setShowInfo(!showInfo);
  };
  return (
    <>
      <div className="flex h-14 w-full items-center justify-between border-t border-solid border-backgrounds-13 px-5 py-2.5 text-white hover:bg-borders-sidebarHover disabled:opacity-50">
        <Button
          buttonType="default"
          onClick={toggleShowInfo}
          size="large"
          text={
            <div className="w-17/18 text-left text-white">
              <img src={user_fill} alt="Agent" className="inline-block" />
              <span className="ms-2 font-sofia text-4 font-normal">
                {t('generic.user')}
              </span>
            </div>
          }
          iconPosition="end"
          icon={
            showInfo ? (
              <CaretUp size={12} color="white" />
            ) : (
              <CaretDown size={12} color="white" />
            )
          }
        />
      </div>

      {showInfo && (
        <div id="userInfo" className="px-6 pb-5">
          <p className="font-sofia text-3.5 font-light">{t('user.profile')}</p>
          <ul className="ms-3 pt-2.5">
            <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
              <img src={ID_icon} alt="ID" className="mb-0.5 mr-1 w-4" />
              {conversation.patientId}
            </li>
            <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
              <img src={mail_icon} alt="Mail" className="mb-0.5 mr-1 w-4" />
              {conversation.patientEmail
                ? conversation.patientEmail.split('@').pop()
                : ''}
            </li>
            <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
              <img src={user_icon} alt="user" className="mb-0.5 mr-1 w-4" />
              {conversation.patientNickname}
            </li>
            <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
              <img src={home_small} alt="Partner" className="mb-0.5 mr-1 w-4" />
              {conversation.patientPartner
                ? conversation.patientPartner
                : t('user.no_partner')}
            </li>
            <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
              <img src={home_small} alt="Metric" className="mb-0.5 mr-1 w-4" />
              Metric: {conversation.metric ? conversation.metric : 'No'}
            </li>
            <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
              <img
                src={language_icon}
                alt="Language"
                className="mb-0.5 mr-1 w-4"
              />
              {conversation.patientLocale}
            </li>
            <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
              <img
                src={language_icon}
                alt="Country"
                className="mb-0.5 mr-1 w-4"
              />
              {conversation.patientTimeZone}
            </li>
            <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
              <img
                src={desktop_icon}
                alt="Source"
                className="mb-0.5 mr-1 w-4"
              />
              {conversation.patientSource}
            </li>

            <li className="mb-0.5 flex gap-0.5 truncate font-sofia text-4 font-light">
              <img src={country_icon} alt="Agent" className="mb-0.5 mr-1 w-4" />
              Trial: {conversation.patientTrialOn ? 'Yes' : 'No'}
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
