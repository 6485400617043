import React from 'react';

import {AutomaticMessage} from '@ifeelonline/storybook';
import {Message} from '@ifeelonline/chat-core';

import {GeneralFunctions} from 'src/helpers/generalFunctions';

interface Props {
  message: Message;
}

export const RenderAutomaticMessage = ({message}: Props) => {
  const {automaticTitle, automaticSubMessage} = GeneralFunctions();

  if (!message.data?.statusVideoCall) return null;

  return (
    <AutomaticMessage
      messageId={message.id}
      title={automaticTitle(message) ?? ''}
      subtitle={automaticSubMessage(message) ?? ''}
      statusVideoCall={message.data?.statusVideoCall}
    />
  );
};
