import React from 'react';

import {Loading, Text} from '@ifeelonline/storybook';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {TriageSurveyRedux} from 'src/state/reducers/conversationReducer';
import {TriageSurveySection} from 'src/pages/chat/types/TriageSurveys';
import {getAnswers} from 'src/pages/chat/utils/surveys/logic';
import {useAppDispatch} from 'src/hooks/hooks';

import {SurveyFormHeader} from '../surveys/SurveyFormHeader';

export const TriageSurveyCompleted = ({
  triageSurveyForm,
  triageSurveySections,
}: {
  triageSurveyForm: TriageSurveyRedux['form'];
  triageSurveySections: TriageSurveySection[] | null;
}) => {
  const dispatch = useAppDispatch();
  const handleClose = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    dispatch({
      type: ConversationActionType.TRIAGE_SURVEY_FORM_OPEN,
      payload: {
        isOpen: false,
      },
    });
  };

  return (
    <>
      <SurveyFormHeader
        isLoading={triageSurveyForm.isLoading}
        handleClose={(e) => handleClose(e)}
        title={triageSurveyForm.title || ''}
      />
      <div className="h-full overflow-auto">
        <div className="px-6">
          {triageSurveySections && triageSurveySections.length > 0 ? (
            triageSurveySections.map((section) => (
              <div key={section.id}>
                <div className="py-3">
                  <Text textType="body-3" fontWeight="bold">
                    {section.openingQuestion}
                  </Text>
                </div>
                {section.questions.map((question) => (
                  <div className="flex" key={question.id}>
                    <div className="flex w-full flex-col border-b border-solid border-borders-aiQuestionnaire py-5 text-start">
                      <div className="mb-3">
                        <Text textType="body-3" fontColor="text-text-400">
                          {question.body}
                        </Text>
                      </div>
                      <Text textType={'body-3'} fontColor="text-text-600">
                        {getAnswers(
                          question.id,
                          question.answer,
                          section.questions,
                        )}
                      </Text>
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </>
  );
};
