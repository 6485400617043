import {AuthUserSupport, Conversation} from '@ifeelonline/chat-core';

import {PARTNERS_AVAILABLE} from 'src/constants/partners';

export function checkIfVideoCallIsAvailable(
  user: AuthUserSupport,
  conversation: Conversation,
) {
  return (
    conversation?.patientPartner &&
    [
      PARTNERS_AVAILABLE.MACCABI.toLowerCase(),
      PARTNERS_AVAILABLE.MACCABI_PRE_PLAN.toLowerCase(),
    ].includes(conversation.patientPartner.toLowerCase()) &&
    user?.partner?.toLowerCase() === PARTNERS_AVAILABLE.FEMI.toLowerCase()
  );
}
