import {useEffect, useRef, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {Toaster} from 'react-hot-toast';
import {useSelector} from 'react-redux';

import {camelCaseToSnakeCase} from '@ifeelonline/chat-core';
import {Button, Text} from '@ifeelonline/storybook';

import {Tooltip} from '@mui/material';

import {
  AIAgentSelection,
  AIQuestionnaireResult,
  AIQuestionnaireShow,
} from 'src/pages/chat/types/AIQuestionnaire';
import {aiQuestionnairePossibleResult} from 'src/pages/chat/utils/ai_questionnaire/renderInformation';
import AIQuestionnaireRepository from 'src/services/AIQuestionnaireRepository';
import {notify} from 'src/helpers/notify';
import {RootState} from 'src/state';

interface Props {
  aiQuestionnaireResult: AIQuestionnaireShow;
}

export const AIAgentAssessment = ({aiQuestionnaireResult}: Props) => {
  const {t} = useTranslation();
  const {conversation} = useSelector((state: RootState) => state.conversation);
  const [selectedAgent, setSelectedAgent] =
    useState<AIQuestionnaireResult | null>(null);
  const [undo, setUndo] = useState(false);
  const undoRef = useRef(false);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  if (aiQuestionnaireResult.ai.agentSelection) {
    const foundItem = aiQuestionnairePossibleResult.find(
      (item) => item.key === aiQuestionnaireResult.ai.agentSelection,
    );
    if (foundItem && !selectedAgent) setSelectedAgent(foundItem);
  }

  const setAgentSelection = async (agentSelection: AIAgentSelection) => {
    setUndo(true);
    undoRef.current = false;

    const foundItem = aiQuestionnairePossibleResult.find(
      (item) => item.key === agentSelection,
    );
    if (foundItem) setSelectedAgent(foundItem);

    timerRef.current = setTimeout(async () => {
      if (!undoRef.current && conversation?.id) {
        const result = await AIQuestionnaireRepository.agentSelection(
          conversation.id.toString(),
          agentSelection,
        );
        if (!result) {
          notify({
            text: t('ai.toast.error.response'),
            type: 'error',
            duration: 300000,
          });
        }
      }
      setUndo(false);
    }, 5000);
  };

  const handleUndo = () => {
    setSelectedAgent(null);
    setUndo(false);
    undoRef.current = true;
    clearTimeout(timerRef.current);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  if (!aiQuestionnaireResult) return <></>;
  return (
    <div className="flex flex-col gap-4 pb-7 pl-6 pr-4">
      <Text textType="body-3" fontColor="text-white">
        {t('ai.result.assessment')}
      </Text>
      <div className="flex gap-4">
        {selectedAgent ? (
          <div className="flex h-10 justify-between">
            <div className="flex items-center gap-2 overflow-hidden">
              <div className="h-4">{selectedAgent.icon}</div>
              <div className="w-full overflow-hidden">
                <Text textType="body-2" fontColor="text-white">
                  {t(`ai.result.${camelCaseToSnakeCase(selectedAgent.key)}`)}
                </Text>
              </div>
              {undo && (
                <button
                  className="h-8 rounded-1.5 bg-white p-2 text-3.5 text-text-700 transition-colors hover:bg-brandPrimary-100 focus:bg-brandPrimary-100"
                  onClick={handleUndo}>
                  {t('ai.status.undo')}
                </button>
              )}
            </div>
          </div>
        ) : (
          aiQuestionnairePossibleResult.map((result) => (
            <Tooltip
              title={t(`ai.result.${camelCaseToSnakeCase(result.key)}`)}
              key={result.key}
              placement="top">
              <div>
                <Button
                  shape="round"
                  size="medium"
                  buttonType={'icon-button-primary-negative'}
                  text=""
                  icon={
                    <div className="flex h-4.5">{result.icon}</div>
                  } /*Hacky way to center the icon in the button */
                  onClick={() => {
                    setAgentSelection(result.key as AIAgentSelection);
                  }}
                />
              </div>
            </Tooltip>
          ))
        )}
      </div>

      <Toaster position="top-center" />
    </div>
  );
};
