import React from 'react';

import {SubmitHandler, FieldValues, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {Button, Text} from '@ifeelonline/storybook';

import {t} from 'i18next';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {useAIQuestionnaireFromProvider} from 'src/providers/AIQuestionnaireProvider';
import AIQuestionnaireRepository from 'src/services/AIQuestionnaireRepository';
import {useAppDispatch} from 'src/hooks/hooks';
import {notify} from 'src/helpers/notify';
import {RootState} from 'src/state';

import {
  AIQuestionNew,
  AIQuestionnaireStatus,
} from '../../types/AIQuestionnaire';

import {RenderNumberInput} from './form_fields/RenderNumberInput';
import {AIQuestionnaireError} from './AIQuestionnaireError';

interface Props {
  SOFASQuestion: AIQuestionNew;
}

export const AIQuestionnaireFormSofas = ({SOFASQuestion}: Props) => {
  const dispatch = useAppDispatch();
  const {SOFASAnswer} = useAIQuestionnaireFromProvider();

  const {conversation, aiQuestions} = useSelector(
    (state: RootState) => state.conversation,
  );
  const {aiQuestionnaireForm} = useSelector(
    (state: RootState) => state.conversation,
  );
  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm();

  if (!conversation || !conversation.id) return <></>;

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    dispatch({
      type: ConversationActionType.AI_QUESTIONNAIRE_FORM_LOADING,
      payload: true,
    });

    if (!aiQuestions) return;

    const res = await AIQuestionnaireRepository.sendSurvey(
      [
        ...aiQuestions,
        {...SOFASQuestion, answer: {comment: SOFASAnswer[0]?.comment}},
      ],
      conversation.id,
    );
    if (res && 'result' in res) {
      notify({
        text: t('ai.toast.success.sent'),
        type: 'success',
      });

      dispatch({
        type: ConversationActionType.AI_QUESTIONNAIRE_FORM_OPEN,
        payload: false,
      });

      dispatch({
        type: ConversationActionType.AI_QUESTIONNAIRE_FORM_RESULT,
        payload: {
          ai: {
            virtualAssistant: res.result.virtualAssistant,
            emotionalSupportChat: res.result.emotionalSupportChat,
            videoTherapy: res.result.videoTherapy,
            referral: res.result.referral,
          },
        },
      });
      dispatch({
        type: ConversationActionType.SET_STATUS_AI_QUESTIONNAIRE_FORM,
        payload: {status: AIQuestionnaireStatus.SENT},
      });
    } else if (res && 'error' in res)
      notify({
        text: res.error,
        type: 'error',
        duration: 500000,
      });
    else
      notify({
        text: t('errors.generic.title'),
        type: 'error',
        duration: 500000,
      });

    dispatch({
      type: ConversationActionType.AI_QUESTIONNAIRE_FORM_LOADING,
      payload: false,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-6">
        <div className="flex">
          <div
            key={`questionHolder-${SOFASQuestion.id}`}
            className={`new-comp flex w-full flex-col gap-3 py-2.5 text-start `}>
            <Text textType={'body-3'} fontColor="text-text-400">
              {SOFASQuestion.body}
            </Text>
            <RenderNumberInput
              question={SOFASQuestion}
              control={control}
              errors={errors}
              handleBlur={() => {}}
              defaultValue={SOFASAnswer[0]?.comment}
              placeholder={t('ai.info.sofa.placeholder')}
              conversationId={conversation.id}
            />
          </div>
        </div>

        {Object.keys(errors).length !== 0 && <AIQuestionnaireError />}
        <div className="flex w-full items-center justify-center py-5">
          <Button
            type="submit"
            buttonType={'button-primary'}
            text={t('generic.send')}
            loading={aiQuestionnaireForm.isLoading}
          />
        </div>
      </div>
    </form>
  );
};
