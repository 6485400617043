import React, {Dispatch, SetStateAction, useState} from 'react';

import {MessageRefs} from '@ifeelonline/chat-core';

import {ChatContainer} from 'src/pages/chat/components/chat_section/ChatContainer';
import {useChat} from 'src/providers/ChatProvider';

interface Props {
  setShowSearcher: Dispatch<SetStateAction<boolean>>;
  messagesDiv: React.MutableRefObject<any>;
  getFirstPage: () => void;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  showSearcher: boolean;
  msgRefs: React.MutableRefObject<MessageRefs>;
  isFirstLoad: boolean;
  setIsFirstLoad: Dispatch<SetStateAction<boolean>>;
  setFirstMsgRefId: React.Dispatch<React.SetStateAction<number | null>>;
  firstMsgRefId: number | null;
}

export const ChatSinglePageContent = ({
  setShowSearcher,
  messagesDiv,
  getFirstPage,
  isLoading,
  setIsLoading,
  setIsFirstLoad,
  isFirstLoad,
  msgRefs,
  firstMsgRefId,
  setFirstMsgRefId,
}: Props) => {
  const {formValues, resetFormValues} = useChat();

  const [areAllPagesLoaded, setAreAllPagesLoaded] = useState<boolean>(false);

  return (
    <div className="bg-f8 relative top-15 flex h-vh-minus-15 flex-row">
      <ChatContainer
        formValues={formValues}
        isSingleConversation
        msgRefs={msgRefs}
        resetFormValues={resetFormValues}
        areAllPagesLoaded={areAllPagesLoaded}
        setAreAllPagesLoaded={setAreAllPagesLoaded}
        setShowSearcher={setShowSearcher}
        messagesDiv={messagesDiv}
        loading={isLoading}
        setLoading={setIsLoading}
        isFirstLoad={isFirstLoad}
        setIsFirstLoad={setIsFirstLoad}
        patientId={true}
        getFirstPage={getFirstPage}
        firstMsgRefId={firstMsgRefId}
        setFirstMsgRefId={setFirstMsgRefId}
      />
    </div>
  );
};
