import React from 'react';

import {useTranslation} from 'react-i18next';

import {
  Caution,
  Chat,
  ChatEdit,
  Eye,
  PlusAddSmall,
  SelfCare5GrowthLeaf,
  VideoCallCamera,
} from '@ifeelonline/icons';
import {BrandColors} from '@ifeelonline/storybook/dist/src/lib/Types/TailwindTypes';
import {Loading, Text} from '@ifeelonline/storybook';

import {
  COLOR_BRAND_COMPLEMENTARY_AMARILLO,
  COLOR_BRAND_PRIMARY_400,
  COLOR_BRAND_SALMON_500,
  COLOR_BRAND_SECONDARY_700,
} from 'src/constants/colors';
import {
  AIQuestionnaireResult,
  AIQuestionnaireStatus,
} from 'src/pages/chat/types/AIQuestionnaire';

export const getIaOption = (
  status: AIQuestionnaireStatus | 'loading' | null,
  agent: string | null,
  action: (() => void) | undefined = undefined,
) => {
  const {t} = useTranslation();
  function renderIcon(icon: JSX.Element) {
    return <div className="h-4">{icon}</div>;
  }

  const renderChildren = (
    status: AIQuestionnaireStatus | 'loading' | null,
    agent: string | null,
  ) => {
    if (status === 'loading') {
      return (
        <>
          <p className="text-3 text-white">{t('ai.side_panel.loading')}...</p>
          <div className="size-5.5">
            <Loading isAbsolute={false} />
          </div>
        </>
      );
    }
    switch (status) {
      case AIQuestionnaireStatus.START:
        return (
          <>
            {renderIcon(<PlusAddSmall fullHeight />)}
            {t('ai.side_panel.add')}
          </>
        );

      case AIQuestionnaireStatus.EDITING:
        return (
          <>
            {renderIcon(<ChatEdit fullHeight />)}
            {t('ai.side_panel.editing', {name: agent || ''})}
          </>
        );
      case AIQuestionnaireStatus.DRAFT:
        return (
          <>
            {renderIcon(<ChatEdit fullHeight />)}
            {t('ai.side_panel.edit')}
            <div className="rounded-1.5 bg-brandPrimary-400 px-2 py-1 text-text-600">
              <Text textType={'caption'}>{t('ai.status.draft')}</Text>
            </div>
          </>
        );

      case AIQuestionnaireStatus.SENT:
        return (
          <>
            {renderIcon(<Eye fullHeight />)}
            {t('ai.side_panel.show')}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <li
      className={`relative flex w-full items-center gap-2.5 py-4 pl-6 text-text-400 transition-all hover:text-white ${
        action && 'cursor-pointer hover:bg-borders-sidebarHover'
      }`}
      onClick={() => {
        if (!action || status === 'loading') return;
        action();
      }}>
      {renderChildren(status, agent)}
    </li>
  );
};

export const aiQuestionnairePossibleResult: AIQuestionnaireResult[] = [
  {
    key: 'virtualAssistant',
    icon: <SelfCare5GrowthLeaf fullHeight />,
    color: COLOR_BRAND_COMPLEMENTARY_AMARILLO,
  },
  {
    key: 'emotionalSupportChat',
    icon: <Chat fullHeight />,
    color: COLOR_BRAND_PRIMARY_400 as BrandColors,
  },
  {
    key: 'videoTherapy',
    icon: <VideoCallCamera fullHeight />,
    color: COLOR_BRAND_SALMON_500,
  },
  {
    key: 'referral',
    icon: <Caution fullHeight />,
    color: COLOR_BRAND_SECONDARY_700,
  },
];

export const getColorInfoDescription = (title: string) => {
  const {t} = useTranslation();
  switch (title) {
    case t('ai.info.low'):
      return 'bg-brandPrimaryLight-500';
    case t('ai.info.high'):
      return 'bg-brandSecondary-200';
    case t('ai.info.medium'):
    case t('ai.info.medium_low'):
      return 'bg-brandComplementaryAmarillo';
    case t('ai.info.medium_high'):
      return 'bg-brandSalmon-400';
    default:
      return 'bg-transparent';
  }
};

export const getAIQuestionnaireInfoLeft = () => {
  const {t} = useTranslation();
  return [
    {
      title: t('ai.info.question1.title'),
      description: [
        {
          description: t('ai.info.question1.description'),
        },
      ],
    },
    {
      title: t('ai.info.question2.title'),
      description: [
        {
          description: t('ai.info.question2.description'),
        },
      ],
    },
    {
      title: t('ai.info.question3.title'),
      description: [
        {
          title: t('ai.info.low'),
          description: t('ai.info.question3.low'),
        },
        {
          title: t('ai.info.medium_low'),
          description: t('ai.info.question3.medium_low'),
        },
        {
          title: t('ai.info.medium_high'),
          description: t('ai.info.question3.medium_high'),
        },
        {
          title: t('ai.info.high'),
          description: t('ai.info.question3.high'),
        },
      ],
    },
    {
      title: t('ai.info.question4.title'),
      description: [
        {
          title: t('ai.info.low'),
          description: t('ai.info.question4.low'),
        },
        {
          title: t('ai.info.medium'),
          description: t('ai.info.question4.medium'),
        },

        {
          title: t('ai.info.high'),
          description: t('ai.info.question4.high'),
        },
      ],
    },
  ];
};

export const getAIQuestionnaireInfoRight = () => {
  const {t} = useTranslation();
  return [
    {
      title: t('ai.info.question5.title'),
      description: [
        {
          title: t('ai.info.low'),
          description: t('ai.info.question5.low'),
        },
        {
          title: t('ai.info.medium'),
          description: t('ai.info.question5.medium'),
        },

        {
          title: t('ai.info.high'),
          description: t('ai.info.question5.high'),
        },
      ],
    },
    {
      title: t('ai.info.question6.title'),
      description: [
        {
          title: t('ai.info.low'),
          description: t('ai.info.question6.low'),
        },
        {
          title: t('ai.info.medium'),
          description: t('ai.info.question6.medium'),
        },

        {
          title: t('ai.info.high'),
          description: t('ai.info.question6.high'),
        },
      ],
    },
  ];
};

export const getAIQuestionnaireInfoDown = () => {
  const {t} = useTranslation();
  return [
    {
      description: [
        {
          title: '1 - 10',
          description: t('ai.info.sofa.point1'),
        },
      ],
    },
    {
      description: [
        {
          title: '11 - 20',
          description: t('ai.info.sofa.point2'),
        },
      ],
    },
    {
      description: [
        {
          title: '21 - 30',
          description: t('ai.info.sofa.point3'),
        },
      ],
    },
    {
      description: [
        {
          title: '31 - 40',
          description: t('ai.info.sofa.point4'),
        },
      ],
    },
    {
      description: [
        {
          title: '41 - 50',
          description: t('ai.info.sofa.point5'),
        },
      ],
    },
    {
      description: [
        {
          title: '51 - 60',
          description: t('ai.info.sofa.point6'),
        },
      ],
    },
    {
      description: [
        {
          title: '61 - 70',
          description: t('ai.info.sofa.point7'),
        },
      ],
    },
    {
      description: [
        {
          title: '71 - 80',
          description: t('ai.info.sofa.point8'),
        },
      ],
    },
    {
      description: [
        {
          title: '81 - 90',
          description: t('ai.info.sofa.point9'),
        },
      ],
    },
    {
      description: [
        {
          title: '91 - 100',
          description: t('ai.info.sofa.point10'),
        },
      ],
    },
  ];
};
