import * as React from 'react';

import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Button} from '@ifeelonline/storybook';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {List} from 'phosphor-react';

import {logout} from 'src/state/action_creators/authActionCreator';
import {useUser} from 'src/providers/UserProvider';
import {useAppDispatch} from 'src/hooks/hooks';
import {ROUTES} from 'src/constants/Routes';

import {isFemiSupport} from '../../helpers/user';

export const MainMenu = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {setUser, user} = useUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigateHome = () => {
    if (location.pathname !== (ROUTES.HOME as string)) {
      navigate(ROUTES.HOME);
    }
  };

  const navigateScheduled = () => {
    if (location.pathname !== (ROUTES.SCHEDULED as string)) {
      navigate(ROUTES.SCHEDULED);
    }
  };

  const handleLogout = async () => {
    const result = await dispatch(logout());
    if (result) setUser(null);
  };

  return (
    <div className="flex size-10 items-center justify-center rounded-full p-0 text-text-500 transition-all hover:bg-brandPrimaryLight-200 hover:text-brandPrimary-600">
      <Button
        buttonType="icon-button-secondary"
        onClick={handleClick}
        icon={<List size={20} />}
        data-cy="main-menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      />
      <Menu
        data-cy="main-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableRestoreFocus>
        <MenuItem
          selected={location.pathname === (ROUTES.HOME as string)}
          onClick={navigateHome}>
          {t('chat.title')}
        </MenuItem>

        {isFemiSupport(user!) && (
          <MenuItem
            selected={location.pathname === (ROUTES.SCHEDULED as string)}
            onClick={navigateScheduled}>
            {t('incoming_patients')}
          </MenuItem>
        )}

        <MenuItem onClick={handleLogout}>{t('auth.sign_out')}</MenuItem>
      </Menu>
    </div>
  );
};
