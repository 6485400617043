export const IS_DEVELOPMENT: boolean =
  import.meta.env.VITE_APP_ENV &&
  ['local', 'development'].includes(import.meta.env.VITE_APP_ENV)
    ? true
    : false;

export const IS_PRODUCTION: boolean =
  import.meta.env.VITE_APP_ENV &&
  ['production', 'develop'].includes(import.meta.env.VITE_APP_ENV)
    ? true
    : false;

export const AMAZON_URL = 'https://s3.eu-west-2.amazonaws.com/ifeel-media';
