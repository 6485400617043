import React, {useEffect} from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {
  ChatStatusType,
  Conversation,
  SupportMatchStatus,
} from '@ifeelonline/chat-core';
import {Button, modalService} from '@ifeelonline/storybook';

import {CaretRight, Link} from 'phosphor-react';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import agent_inactive from 'src/assets/images/chat/sidebar-blue/agent_inactive.png';
import {sendMessage} from 'src/state/action_creators/conversationActionCreator';
import AIQuestionnaireRepository from 'src/services/AIQuestionnaireRepository';
import {AIQuestionnaireStatus} from 'src/pages/chat/types/AIQuestionnaire';
import coupon_icon from 'src/assets/images/chat/sidebar-blue/coupon.svg';
import {getResultMax} from 'src/pages/chat/utils/ai_questionnaire/logic';
import {isMatched} from 'src/pages/chat/utils/conversation';
import {RouteConversation} from 'src/constants/Routes';
import {isPatientWaiting} from 'src/helpers/patients';
import {useChat} from 'src/providers/ChatProvider';
import {useUser} from 'src/providers/UserProvider';
import {isFemiSupport} from 'src/helpers/user';
import {useAppDispatch} from 'src/hooks/hooks';
import {AMAZON_URL} from 'src/constants';
import {RootState} from 'src/state';

import {TriageSidePanelSection} from '../sections/TriageSidePanelSection';
import {AISidePanelSection} from '../sections/AISidePanelSection';
import {AgentSelector} from '../../chat_section/AgentSelector';
import {PlanInfoSection} from '../sections/PlanInfoSection';

import {SurveyInfoSection} from './SurveyInfoSection';
import {UserInfoSection} from './UserInfoSection';

interface Props {
  conversation: Conversation;
  setShowAppLinkModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSendCouponModal: React.Dispatch<React.SetStateAction<boolean>>;
  isScheduledView?: boolean;
}

export const SidePanelInfo = ({
  conversation,
  setShowSendCouponModal,
  setShowAppLinkModal,
  isScheduledView,
}: Props) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const {user} = useUser();
  const {setChatStatus} = useChat();

  const {aiQuestionnaireForm, triageSurvey} = useSelector(
    (state: RootState) => state.conversation,
  );

  const handleGoToConversation = (id: number) => {
    window.open(RouteConversation(id.toString()));
  };

  const handleGoToPatientInDashboard = (patientId: number) => {
    window.open(`${import.meta.env.VITE_DASHBOARD_PATIENT_URL}${patientId}`);
  };

  if (
    !conversation ||
    !conversation.id ||
    !conversation.patientId ||
    !conversation.status
  )
    return <></>;
  const {myAgent, patientId, patientPartner, status, id} = conversation;

  const dispatchMessage = () => {
    dispatch(
      sendMessage({
        body: t('chat_options.agent_off_msg', {
          agent_name:
            typeof myAgent !== 'string' ? myAgent?.user?.nickname : '',
          lng: conversation!.patientLocale ?? '',
        }),
        conversation_id: conversation!.id,
        patient_id: user!.uid,
        user_id: conversation!.patientId,
        reply_to: null,
      }),
    ).then(() => {
      modalService.close();
      setChatStatus(ChatStatusType.DEFAULT);
    });
  };

  const handleAgentOff = () => {
    modalService.fire({
      show: true,
      closeOutside: true,
      title: t('swal_questions.are_you_sure'),
      description: t('chat_options.agent_off_msg', {
        agent_name: typeof myAgent !== 'string' ? myAgent?.user?.nickname : '',
        lng: conversation!.patientLocale ?? '',
      }),
      cancelButtonText: t('generic.no'),
      confirmButtonText: t('generic.yes'),
      confirmButtonAction: dispatchMessage,
      cancelButtonAction: () => {
        modalService.close();
      },
      closeModal: () => {},
      showCloseButtonX: false,
      reverseButtons: true,
    });
  };

  const getAIQuestionnairePatientStatus = async (id: number) => {
    const status = await AIQuestionnaireRepository.getSurveyStatus(id);
    dispatch({
      type: ConversationActionType.SET_STATUS_AI_QUESTIONNAIRE_FORM,
      payload: {status: status?.aiStatus, agent: status?.agent},
    });

    if (status?.aiStatus === AIQuestionnaireStatus.SENT) {
      const result = await AIQuestionnaireRepository.showAI(id);
      dispatch({
        type: ConversationActionType.AI_QUESTIONNAIRE_FORM_RESULT,
        payload: result?.ai.emotionalSupportChat ? result : null,
      });

      if (result) getResultMax(result.ai);
    } else
      dispatch({
        type: ConversationActionType.AI_QUESTIONNAIRE_FORM_RESULT,
        payload: null,
      });
  };

  const closeAIQuestionnaireForm = async () => {
    if (aiQuestionnaireForm.patientId) {
      const result = await AIQuestionnaireRepository.changeSurveyStatus(
        id!.toString(),
        AIQuestionnaireStatus.DRAFT,
      );
      if (result)
        dispatch({
          type: ConversationActionType.SET_STATUS_AI_QUESTIONNAIRE_FORM,
          payload: {status: AIQuestionnaireStatus.DRAFT},
        });
    }
    dispatch({
      type: ConversationActionType.AI_QUESTIONNAIRE_FORM_OPEN,
      payload: false,
    });
    dispatch({
      type: ConversationActionType.AI_QUESTIONNAIRE_FORM_DISPLAY_OPEN,
      payload: false,
    });
  };

  useEffect(() => {
    /* TO-DO refactor to avoid making the request when support changes conversation 
      but when they open the AI questionnaire section toggle
    */
    if (user && !isFemiSupport(user)) {
      getAIQuestionnairePatientStatus(id!);
      if (aiQuestionnaireForm.isOpen) {
        closeAIQuestionnaireForm();
      }
      dispatch({
        type: ConversationActionType.AI_QUESTIONNAIRE_FORM_DISPLAY_OPEN,
        payload: false,
      });
    }
  }, [patientId]);

  if (!user) return null;

  return (
    <>
      {!isScheduledView && (
        <div className="agent_selector">
          <AgentSelector />
        </div>
      )}
      <UserInfoSection conversation={conversation} />
      {isFemiSupport(user) ? (
        <div className={`${isMatched(status!) ? 'flex flex-col' : 'hidden'}`}>
          <TriageSidePanelSection
            icon={`${AMAZON_URL}/images/ic_ia.png`}
            title={t('side_panel.maccabi.section_title')}
            conversationId={id!}
            triageSurvey={triageSurvey}
          />
        </div>
      ) : (
        <div className={`${isMatched(status!) ? 'flex flex-col' : 'hidden'}`}>
          <AISidePanelSection
            aiQuestionnaireForm={aiQuestionnaireForm}
            conversation={conversation}
          />
        </div>
      )}

      <PlanInfoSection conversation={conversation} />
      <SurveyInfoSection />
      {!patientPartner && (
        <div className="flex h-14 w-full items-center justify-between border-t border-solid border-backgrounds-13 px-5 py-2.5 text-white hover:bg-borders-sidebarHover disabled:opacity-50">
          <Button
            buttonType="default"
            onClick={() => setShowSendCouponModal(true)}
            disabled={status === SupportMatchStatus.WAITING}
            size="large"
            text={
              <div className="w-17/18 text-left text-white">
                <img src={coupon_icon} alt="Plan" className="inline-block" />
                <span className="ms-2 font-sofia text-4 font-normal">
                  {t('chat_options.send_coupon')}
                </span>
              </div>
            }
            iconPosition="end"
            icon={<CaretRight size={12} color="white" />}
          />
        </div>
      )}

      <div className="flex h-14 w-full items-center justify-between border-t border-solid border-backgrounds-13 px-5 py-2.5 text-white hover:bg-borders-sidebarHover disabled:opacity-50">
        <Button
          buttonType="default"
          onClick={() => setShowAppLinkModal(true)}
          disabled={isPatientWaiting(conversation)}
          size="large"
          text={
            <div className="w-17/18 text-left text-white">
              <Link size={24} className="inline-block disabled:hidden" />
              <span className="ms-2 font-sofia text-4 font-normal">
                {t('chat_options.send_app_link')}
              </span>
            </div>
          }
          iconPosition="end"
          icon={<CaretRight size={12} color="white" />}
        />
      </div>
      <div className="flex h-14 w-full items-center justify-between border-t border-solid border-backgrounds-13 px-5 py-2.5 text-white hover:bg-borders-sidebarHover disabled:opacity-50">
        <Button
          buttonType="default"
          onClick={handleAgentOff}
          disabled={isPatientWaiting(conversation)}
          size="large"
          text={
            <div className="w-17/18 text-left text-white">
              <img src={agent_inactive} alt="Agent" className="inline-block" />
              <span className="ms-2 font-sofia text-4 font-normal">
                {t('chat_options.agent_off')}
              </span>
            </div>
          }
          iconPosition="end"
          icon={<CaretRight size={12} color="white" />}
        />
      </div>

      <Button
        buttonType="default"
        onClick={() => handleGoToConversation(id!)}
        disabled={isPatientWaiting(conversation)}
        size="large"
        text={
          <div className="block w-full ps-5 text-left text-3.5 text-text-400 transition-all hover:text-brandPrimary-600">
            {t('chat_options.go_to_conversation')}
          </div>
        }
      />
      <Button
        buttonType="default"
        onClick={() => handleGoToPatientInDashboard(patientId!)}
        size="large"
        text={
          <div className="block w-full ps-5 text-left text-3.5 text-text-400 transition-all hover:text-brandPrimary-600">
            {t('chat_options.go_to_dashboard')}
          </div>
        }
      />
    </>
  );
};
