import React from 'react';

import {CloseMedium, InfoMedium} from '@ifeelonline/icons';

interface Props {
  actionClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  actionInfo: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const AIHeaderQuestionnaire = ({actionClose, actionInfo}: Props) => {
  return (
    <div className="flex h-12 w-full items-center justify-end gap-2 border-b border-solid border-borders-aiQuestionnaire p-4">
      <IconButton
        onClick={(e) => actionInfo(e)}
        ariaLabel="Information"
        icon={<InfoMedium fullHeight />}
      />

      <IconButton
        onClick={(e) => actionClose(e)}
        ariaLabel="Close"
        icon={<CloseMedium fullHeight />}
      />
    </div>
  );
};

interface IconButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon: React.ReactNode;
  ariaLabel: string;
}

const IconButton: React.FC<IconButtonProps> = ({onClick, icon, ariaLabel}) => {
  return (
    <button
      onClick={onClick}
      className="h-full cursor-pointer text-text-400 transition-colors hover:text-text-600"
      aria-label={ariaLabel}>
      {icon}
    </button>
  );
};
