import React from 'react';

import {useTranslation} from 'react-i18next';

import {Button, Text} from '@ifeelonline/storybook';

import {AIFormSteps} from '../../types/AIQuestionnaire';

interface Props {
  setIsOpenModal: (isOpen: boolean) => void;
  setStep: (step: number) => void;
}

export const SofaErrorModal = ({setIsOpenModal, setStep}: Props) => {
  const {t} = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-6">
          <Text
            textType="title"
            fontColor="text-text-gre"
            fontWeight="semibold">
            {t('ai.modal.title')}
          </Text>
          <Text textType="body-2" fontColor="text-text-600">
            {t('ai.modal.description')}
          </Text>
        </div>
        <div className="flex w-full flex-col items-center justify-center gap-6">
          <div>
            <Button
              buttonType="button-primary"
              text={t('ai.modal.confirm_button')}
              onClick={() => {
                setIsOpenModal(false);
              }}
            />
          </div>
          <Button
            buttonType="button-outline"
            text={t('ai.modal.cancel_button')}
            onClick={() => {
              setStep(AIFormSteps.SOFAS);
              setIsOpenModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};
