import {camelCaseToSnakeCase} from '@ifeelonline/chat-core';

import {
  convertQuestionsToAnswersStorage,
  mapAIResultStorageToAIResult,
  mapApiResponseAIQuestions,
  mapShowAIStorageToShowAI,
  mapSurveyStatus,
} from 'src/helpers/api_response/ai_questionnaire';
import {
  AIAgentSelection,
  AIQuestion,
  AIQuestionNew,
  AIQuestionnaireShow,
  AIQuestionnaireStatus,
  AIResult,
  AISurveyStatus,
} from 'src/pages/chat/types/AIQuestionnaire';
import {fetchWrapper} from 'src/helpers/fetch';

class AIQuestionnaireRepository {
  async getQuestions(conversationId: number) {
    const response = await fetchWrapper({
      endPoint: `ai_answers/get_ai_questions?conversation_id=${conversationId}`,
    });

    if (!response) return null;

    if (response.body.error || !response.body.success) return null;
    const questionsAIMapped: AIQuestion[] = mapApiResponseAIQuestions(
      response.body.questions,
    );

    return questionsAIMapped;
  }

  async sendSurvey(
    questions: AIQuestionNew[],
    conversationId: number,
  ): Promise<{result: AIResult} | false | {error: string}> {
    const response = await fetchWrapper({
      endPoint: `ai_answers/send_ai_survey`,
      method: 'POST',
      data: convertQuestionsToAnswersStorage(questions, conversationId),
    });
    if (response && response.body.success) {
      const resultsAIMapped: AIResult = mapAIResultStorageToAIResult(
        response.body.ai_data,
      );
      return {result: resultsAIMapped};
    } else {
      if (response?.body.errors) return {error: response.body.errors[0]};
      else return false;
    }
  }

  async getSurveyStatus(conversationId: number) {
    const response = await fetchWrapper({
      endPoint: `ai/get_ai_survey_status?conversation_id=${conversationId}`,
    });

    if (!response) return null;

    if (response.body.error || !response.body.success) return null;
    const statusAIMapped: AISurveyStatus = mapSurveyStatus(response.body);
    return statusAIMapped;
  }

  async changeSurveyStatus(
    conversationId: string,
    aIStatus: AIQuestionnaireStatus,
  ) {
    const response = await fetchWrapper({
      endPoint: `ai/change_ai_survey_status`,
      method: 'POST',
      data: {conversation_id: conversationId, ai_status: aIStatus},
    });
    if (response && response.body.success) return true;
    else return false;
  }

  async agentSelection(
    conversationId: string,
    agentSelection: AIAgentSelection,
  ) {
    const response = await fetchWrapper({
      endPoint: `ai/agent_selection`,
      method: 'POST',
      data: {
        conversation_id: conversationId,
        agent_selection: camelCaseToSnakeCase(agentSelection),
      },
    });
    if (response && response.body.success) return true;
    else return false;
  }

  async showAI(conversationId: number) {
    const response = await fetchWrapper({
      endPoint: `ai/show_ai?conversation_id=${conversationId}`,
    });
    if (!response) return null;

    if (response.body.error || !response.body.success) return null;
    const showAIMapped: AIQuestionnaireShow = mapShowAIStorageToShowAI(
      response.body,
    );
    return showAIMapped;
  }

  async calculateSofas(conversationId: number) {
    const response = await fetchWrapper({
      endPoint: `ai_answers/calculate_ai_sofas?conversation_id=${conversationId}`,
      method: 'POST',
    });
    if (!response) return null;

    if (response.body.error || !response.body.success) return null;
    const sofas: number = response.body.sofas;
    return sofas;
  }
}

export default new AIQuestionnaireRepository();
