import {useTranslation} from 'react-i18next';

import {Text} from '@ifeelonline/storybook';

import imgDefault from 'src/assets/images/column_blue_default.png';
import {Quote as QuoteInterface} from 'src/types/Quote';
import {getQuoteList} from 'src/helpers/quotes';
import {Quote} from 'src/components/Quote';

export const SidePanelNoConversation = () => {
  const {t} = useTranslation();
  const quotes: QuoteInterface[] = getQuoteList(t);
  const randomNum = Math.floor(Math.random() * quotes.length);
  return (
    <div className="flex h-full flex-col justify-evenly p-10 text-center">
      <div className="">
        <img className="mb-4" src={imgDefault} />
        <Text
          textType="body-2"
          fontColor="text-white"
          className="my-0 font-light leading-6">
          {t('remember_take_breaks')}
        </Text>
      </div>
      <hr className="mx-auto my-0 w-1/2 border border-solid border-brandPrimary-200" />
      <Quote text={quotes[randomNum].text} author={quotes[randomNum].author} />
    </div>
  );
};
