import React, {ComponentProps} from 'react';

export const SidePanelItem = ({children, onClick}: ComponentProps<'div'>) => {
  return (
    <div
      className="relative flex w-full cursor-pointer items-center gap-2.5 px-6 py-4 transition-all hover:bg-borders-sidebarHover hover:text-white"
      onClick={onClick}>
      {children}
    </div>
  );
};
