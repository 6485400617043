import React from 'react';

import {Button, Loading} from '@ifeelonline/storybook';

const ConnectionLost = () => {
  return (
    <div className="fixed left-1/2 top-2/10 z-50 -translate-x-1/2 -translate-y-1/2 rounded-2 bg-white p-8 text-center shadow-md">
      <Loading isAbsolute={false} />
      <p>Connection lost. Trying to reconnect...</p>
      <p>¿Do you want to refresh the page?</p>
      <div
        className="mt-3 flex items-center justify-center"
        onClick={() => window.location.reload()}>
        <Button buttonType="button-primary" text="Refresh" />
      </div>
    </div>
  );
};

export default ConnectionLost;
