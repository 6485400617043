import React, {PropsWithChildren} from 'react';

import {useTranslation} from 'react-i18next';

import {Button, Chip, Text} from '@ifeelonline/storybook';

import {shouldShowCompleteTriageButton} from 'src/pages/chat/utils/surveys/triageSurvey';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {TriageSurveyRedux} from 'src/state/reducers/conversationReducer';
import TriageSurveyRepository from 'src/services/TriageSurveyRepository';
import {TriageSurvey} from 'src/pages/chat/types/TriageSurveys';
import {statusToText} from 'src/pages/chat/utils/surveys/logic';
import {SurveyStatus} from 'src/pages/chat/types/Surveys';
import {useAppDispatch} from 'src/hooks/hooks';

import {SidePanelSection} from '../components/SidePanelSection';
import {SidePanelItem} from '../components/SidePanelItem';

interface Props {
  icon: string;
  title: string;
  conversationId: number;
  triageSurvey: TriageSurveyRedux;
}

export const TriageSidePanelSection = ({
  icon,
  title,
  conversationId,
  triageSurvey,
}: PropsWithChildren<Props>) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const handleClick = async () => {
    const response =
      await TriageSurveyRepository.getSurveysStatus(conversationId);

    if (response)
      dispatch({
        type: ConversationActionType.TRIAGE_SURVEY_LIST,
        payload: response,
      });
  };

  const handleCompleteTriage = async () => {
    const response =
      await TriageSurveyRepository.completeTriage(conversationId);

    if (response)
      dispatch({
        type: ConversationActionType.TRIAGE_SURVEY_COMPLETED,
      });
  };

  const handleOpenTriageSurvey = async ({
    id,
    status,
    name,
    title,
  }: TriageSurvey) => {
    if (status !== SurveyStatus.SAVED)
      await TriageSurveyRepository.updateStatus({
        conversationId,
        status: SurveyStatus.EDITING,
        surveyId: id,
      });

    dispatch({
      type: ConversationActionType.TRIAGE_SURVEY_FORM_OPEN,
      payload: {
        isOpen: true,
        isLoading: true,
        id,
        status,
        name,
        title,
      },
    });

    if (status !== SurveyStatus.SAVED)
      dispatch({
        type: ConversationActionType.TRIAGE_SURVEY_LIST_UPDATE_STATUS,
        payload: {
          surveyId: id,
          status: SurveyStatus.EDITING,
        },
      });
  };

  const TriageSidePanelSectionItem = ({survey}: {survey: TriageSurvey}) => (
    <SidePanelItem
      onClick={() => handleOpenTriageSurvey(survey)}
      key={`survey_${survey.id}`}>
      <Text textType="body-3" fontColor="text-white">
        {survey.name}
      </Text>
      {survey.status !== SurveyStatus.START && (
        <Chip
          size="small"
          text={statusToText(survey.status, t)}
          isClickable={false}
          theme={{backgroundColor: 'bg-text-600', fontColor: 'text-white'}}
        />
      )}
      {survey.score && (
        <Chip
          size="small"
          text={survey.score.toString()}
          isClickable={false}
          theme={{backgroundColor: 'bg-text-600', fontColor: 'text-white'}}
        />
      )}
    </SidePanelItem>
  );

  return (
    <SidePanelSection
      icon={icon}
      title={title}
      handleOpen={handleClick}
      conversationId={conversationId}>
      {triageSurvey.list &&
        Object.values(triageSurvey.list.surveys).map((surveyGroup, index) =>
          Array.isArray(surveyGroup) ? (
            <SidePanelSection
              title={t('side_panel.maccabi.optional')}
              isInnerItem={true}
              key={`survey_${index}_${surveyGroup}`}>
              {surveyGroup.map((survey, subIndex) => (
                <TriageSidePanelSectionItem
                  key={`survey_${index}_${subIndex}`}
                  survey={survey}
                />
              ))}
            </SidePanelSection>
          ) : (
            <TriageSidePanelSectionItem
              key={`survey_${index}`}
              survey={surveyGroup}
            />
          ),
        )}
      {triageSurvey.list?.isTriageCompleted ||
      shouldShowCompleteTriageButton(triageSurvey.list) ? (
        <div className="flex justify-center py-4">
          {triageSurvey.list?.isTriageCompleted ? (
            <Chip
              size="small"
              text={t('surveys.form.triage_completed')}
              isClickable={false}
              theme={{backgroundColor: 'bg-text-600', fontColor: 'text-white'}}
            />
          ) : (
            <Button
              buttonType="button-primary"
              onClick={handleCompleteTriage}
              text={t('surveys.form.complete_triage.button')}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </SidePanelSection>
  );
};
