import React from 'react';

import {useSelector} from 'react-redux';

import {SupportMatchStatus} from '@ifeelonline/chat-core';

import {
  getConversation,
  setSurvey,
} from 'src/state/action_creators/conversationActionCreator';
import {
  ConversationListItem,
  ScheduledConversationListItem,
} from 'src/types/ConversationList';
import {ConversationsActionType} from 'src/state/action_types/ConversationsActionType';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import TriageSurveyRepository from 'src/services/TriageSurveyRepository';
import {SurveyStatus} from 'src/pages/chat/types/Surveys';
import {useChat} from 'src/providers/ChatProvider';
import {useUser} from 'src/providers/UserProvider';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {isFemiSupport} from '../../../../helpers/user';

import {AvatarConversation} from './AvatarConversation';
import {InfoConversation} from './InfoConversation';
import {InfoLastMessage} from './InfoLastMessage';

interface Props {
  conversationListItem: ConversationListItem | ScheduledConversationListItem;
  page: number;
  setAreAllPagesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  reset: () => void;
  isHighRisk?: boolean;
  isScheduledView?: boolean;
}

export const Conversation = ({
  conversationListItem,
  setPage,
  setAreAllPagesLoaded,
  reset,
  isHighRisk,
  isScheduledView,
}: Props) => {
  const dispatch = useAppDispatch();
  const {user} = useUser();
  const {conversation} = useChat();
  const {triageSurvey} = useSelector((state: RootState) => state.conversation);

  const setActiveConversation = async (conversationId: number) => {
    if (conversationId) {
      if (triageSurvey.form.isOpen) {
        const prevConversationId = localStorage.getItem('conversationId');
        await TriageSurveyRepository.updateStatus({
          conversationId: Number(prevConversationId),
          surveyId: triageSurvey.form.id!,
          status: SurveyStatus.DRAFT,
        });
        dispatch({
          type: ConversationActionType.TRIAGE_SURVEY_FORM_OPEN,
          payload: false,
        });
      }
      dispatch({
        type: ConversationsActionType.SET_ACTIVE_CONVERSATION,
        payload: conversationId,
      });
      localStorage.setItem('conversationId', conversationId.toString());

      setPage(1);
      setAreAllPagesLoaded(false);
      dispatch(getConversation(conversationId, 1));
      dispatch(setSurvey(conversationId, isFemiSupport(user!)));
    }
  };

  if (!user) return <></>;

  return (
    <div
      className={`conversation relative mx-0 my-2.5 cursor-pointer rounded-2.5 border-l-2 border-solid bg-white px-3.5 pb-4.5 pt-5.5 shadow-conversationList ${
        conversationListItem.status &&
        conversationListItem.status === SupportMatchStatus.WAITING
          ? `before:absolute before:-top-1 before:left-3 before:h-4.5 before:w-auto before:rounded-1 before:bg-backgrounds-10 before:px-1 before:py-0 before:font-sofia before:text-3 before:font-normal before:capitalize before:leading-4.5 before:text-white before:content-new`
          : ''
      } ${
        conversation && conversation.id === conversationListItem.id
          ? 'border-borders-blue3b'
          : 'border-transparent'
      }
      ${
        isHighRisk
          ? 'after:absolute after:-top-1 \n' +
            '          after:left-3 after:h-4.5 after:w-auto after:rounded-1\n' +
            '          after:bg-red-400 after:px-1 \n' +
            '          after:py-0 after:font-sofia after:text-3 after:font-normal after:capitalize after:leading-4.5 after:text-white after:content-highRisk'
          : ''
      }`}
      onClick={() => {
        reset();
        setActiveConversation(conversationListItem.id!);
      }}
      data-cy="conversation-list-item"
      data-conversation-id={conversationListItem.id}
      data-client-id={user.uid}
      data-user-id={conversationListItem.patientId}>
      <div className="flex items-center justify-between">
        <AvatarConversation conversationListItem={conversationListItem} />
        <InfoConversation conversationListItem={conversationListItem} />
        <InfoLastMessage
          conversationListItem={conversationListItem}
          userId={user.id}
          isScheduledView={isScheduledView}
        />
      </div>
    </div>
  );
};
