import React from 'react';

import {Control, Controller} from 'react-hook-form';

import {InputLabel, PasswordInput, TextInput} from '@ifeelonline/storybook';

import {LoginFormProps} from '../LoginPage';

interface LoginFormFieldProps {
  name: keyof LoginFormProps;
  placeholder: string;
  control: Control<LoginFormProps>;
  defaultValue: string;
  rules: {required: boolean};
  errors: null | string;
  labelText: string;
  setErrorLogin: (value: boolean) => void;
}

export const LoginFormField: React.FC<LoginFormFieldProps> = ({
  name,
  control,
  defaultValue,
  rules,
  errors,
  labelText,
  setErrorLogin,
  placeholder,
}) => {
  return (
    <div className="mb-5 text-start">
      <InputLabel text={labelText} htmlFor={name} />
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({field}) =>
          field.name === 'password' ? (
            <PasswordInput
              name={field.name}
              value={field.value}
              placeholder={placeholder}
              onChange={(e) => {
                field.onChange(e);
                setErrorLogin(false);
              }}
              errors={errors}
            />
          ) : (
            <TextInput
              name={field.name}
              value={field.value}
              placeholder={placeholder}
              onChange={(e) => {
                field.onChange(e);
                setErrorLogin(false);
              }}
              errors={errors}
            />
          )
        }
      />
    </div>
  );
};
