import {useChatChannel} from 'src/pages/chat/hooks/useChatChannel';

export const ChatChannelSubscriber = ({
  userId,
  clientId,
}: {
  userId: number;
  clientId: number;
}) => {
  useChatChannel(userId, clientId);
  return null;
};
