import {Conversation, Message, SharedActionType} from '@ifeelonline/chat-core';

import {
  AIQuestionNew,
  AIQuestionnaireForm,
} from 'src/pages/chat/types/AIQuestionnaire';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {ConversationAction, UserSurvey} from 'src/state/actions/conversation';
import {TriageSurveyList} from 'src/pages/chat/types/TriageSurveys';
import {SurveyStatus} from 'src/pages/chat/types/Surveys';

interface ConversationState {
  conversation: Conversation | null;
  messageBounds: {firstMessageId: number; lastMessageId: number} | null;
  error: string | null;
  loadingMoreMessages: SharedActionType | null;
  messages: Message[];
  status: SharedActionType;
  userSurvey: UserSurvey | null;
  aiQuestionnaireForm: AIQuestionnaireForm;
  aiQuestions: AIQuestionNew[] | null;
  triageSurvey: TriageSurveyRedux;
  newMessages: number | null;
  isJumping: boolean;
  pagesLoaded: number[];
}

export interface TriageSurveyRedux {
  form: {
    id: number | null;
    isOpen: boolean;
    isLoading: boolean;
    name: string | null;
    title: string | null;
    status: SurveyStatus | null;
  };
  list: TriageSurveyList | null;
}

const initialState = {
  conversation: null,
  userSurvey: null,
  messages: [],
  newMessages: null,
  messageBounds: null,
  status: SharedActionType.IDLE,
  loadingMoreMessages: SharedActionType.IDLE,
  error: null,
  isJumping: false,
  pagesLoaded: [],
  aiQuestionnaireForm: {
    status: null,
    agent: null,
    isOpen: false,
    displayIsOpen: false,
    isLoading: false,
    patientId: null,
    result: null,
  },
  aiQuestions: null,
  triageSurvey: {
    form: {
      id: null,
      isOpen: false,
      isLoading: false,
      name: null,
      title: null,
      status: null,
      type: null,
    },
    list: null,
  },
};

export const conversationReducer = (
  state: ConversationState = initialState,
  action: ConversationAction,
): ConversationState => {
  let messagesUpdated: Message[] = [];
  switch (action.type) {
    case ConversationActionType.RESET_INITIAL_STATE:
      return {
        ...initialState,
        status: SharedActionType.LOADING,
      };
    case ConversationActionType.CONVERSATION_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case ConversationActionType.SET_PAGES_LOADED:
      return {
        ...state,
        pagesLoaded: action.payload,
      };

    case ConversationActionType.CONVERSATION_LOADING_MORE_MESSAGES:
      return {
        ...state,
        loadingMoreMessages: SharedActionType.LOADING,
      };

    case ConversationActionType.CONVERSATION_LOAD_MORE_MESSAGES:
      if (state.messages) {
        if (state.messages[0].id < action.payload[0].id) {
          messagesUpdated = [...state.messages, ...action.payload];
        } else {
          messagesUpdated = [...action.payload, ...state.messages];
        }
      } else {
        {
          messagesUpdated = action.payload;
        }
      }
      return {
        ...state,
        messages: messagesUpdated,
        status: SharedActionType.COMPLETED,
      };

    case ConversationActionType.CONVERSATION_MESSAGE_READ: {
      if (
        !state.messages ||
        state.messages.filter((m) => m.readOn === null).length === 0
      )
        return state;
      return {
        ...state,
        messages: state.messages.map((m) =>
          m.readOn === null
            ? {
                ...m,
                readOn: action.payload.date,
              }
            : m,
        ),
        status: SharedActionType.COMPLETED,
      };
    }

    case ConversationActionType.CONVERSATION_NEW_MESSAGE:
      return {
        ...state,
        messages:
          state.messages && state.messages.length > 0
            ? [...state.messages, action.payload]
            : [action.payload],
        status: SharedActionType.COMPLETED,
      };

    case ConversationActionType.SET_CONVERSATION:
      return {
        ...state,
        isJumping: false,
        conversation: action.payload.conversation,
        messages: action.payload.messages,
        status: SharedActionType.COMPLETED,
        aiQuestionnaireForm: {
          ...state.aiQuestionnaireForm,
          isOpen: false,
        },
      };

    case ConversationActionType.JUMP_TO_MESSAGES:
      return {
        ...state,
        loadingMoreMessages: SharedActionType.LOADING,
        messages: action.payload,
        status: SharedActionType.COMPLETED,
      };

    case ConversationActionType.JUMPING_TO_MESSAGES:
      return {
        ...state,
        loadingMoreMessages: SharedActionType.COMPLETED,
        isJumping: action.payload,
      };

    case ConversationActionType.CONVERSATION_MESSAGE_DELETED:
      return {
        ...state,
        messages: state.messages.map((m) =>
          m.id === action.payload.message_id
            ? {
                ...m,
                deleted: true,
              }
            : m,
        ),
        status: SharedActionType.COMPLETED,
      };

    case ConversationActionType.CONVERSATION_MESSAGE_EDITED:
      return {
        ...state,
        messages: state.messages.map((m) =>
          m.id === action.payload.message_id
            ? {
                ...m,
                updated: true,
                body: action.payload.body,
              }
            : m,
        ),
        status: SharedActionType.COMPLETED,
      };

    case ConversationActionType.CONVERSATION_HANDLE_MESSAGE_FAVORITE:
      return {
        ...state,
        messages: state.messages.map((m) =>
          m.id === action.payload.messageId
            ? {
                ...m,
                isFavorite: action.payload.favorite,
              }
            : m,
        ),
      };

    case ConversationActionType.CONVERSATION_USER_STATUS:
      return {
        ...state,
        conversation: {
          ...state.conversation!,
          patientState: action.payload.status,
        },
      };

    case ConversationActionType.SET_MESSAGES_BOUNDS:
      return {
        ...state,
        messageBounds: action.payload
          ? {
              firstMessageId: action.payload.firstMessageId,
              lastMessageId: action.payload.lastMessageId,
            }
          : null,
      };

    case ConversationActionType.SET_PATIENT_SURVEY:
      return {
        ...state,
        userSurvey: action.payload,
      };

    case ConversationActionType.TRIAGE_SURVEY_FORM_OPEN:
      return {
        ...state,
        triageSurvey: {
          ...state.triageSurvey,
          form: {
            isOpen: action.payload.isOpen || false,
            isLoading: action.payload.isLoading || false,
            id: action.payload.id,
            name: action.payload.name,
            title: action.payload.title,
            status: action.payload.status,
          },
        },
      };

    case ConversationActionType.TRIAGE_SURVEY_FORM_LOADING:
      return {
        ...state,
        triageSurvey: {
          ...state.triageSurvey,
          form: {
            ...state.triageSurvey.form,
            isLoading: action.payload || false,
          },
        },
      };

    case ConversationActionType.TRIAGE_SURVEY_LIST:
      return {
        ...state,
        triageSurvey: {
          ...state.triageSurvey,
          list: action.payload,
        },
      };

    case ConversationActionType.TRIAGE_SURVEY_COMPLETED:
      return {
        ...state,
        triageSurvey: {
          ...state.triageSurvey,
          list: state.triageSurvey.list
            ? {
                ...state.triageSurvey.list,
                isTriageCompleted: true,
              }
            : null,
        },
      };

    case ConversationActionType.TRIAGE_SURVEY_LIST_UPDATE_STATUS: {
      const {surveyId, status} = action.payload;
      const surveyList = state.triageSurvey.list?.surveys;

      if (!surveyList) return state;

      const updatedSurveys = {
        mandatory:
          surveyList.mandatory.id === surveyId
            ? {...surveyList.mandatory, status}
            : surveyList.mandatory,
        optional: surveyList.optional.map((survey) =>
          survey.id === surveyId ? {...survey, status} : survey,
        ),
        intake:
          surveyList.intake.id === surveyId
            ? {...surveyList.intake, status}
            : surveyList.intake,
      };

      return {
        ...state,
        triageSurvey: {
          ...state.triageSurvey,
          list: {
            ...state.triageSurvey.list,
            surveys: updatedSurveys,
            isTriageCompleted:
              state.triageSurvey.list?.isTriageCompleted || false,
          },
        },
      };
    }

    case ConversationActionType.AI_QUESTIONNAIRE_FORM_OPEN:
      return {
        ...state,
        aiQuestionnaireForm: {
          ...state.aiQuestionnaireForm,
          isOpen: action.payload || false,
        },
      };

    case ConversationActionType.AI_QUESTIONNAIRE_FORM_DISPLAY_OPEN:
      return {
        ...state,
        aiQuestionnaireForm: {
          ...state.aiQuestionnaireForm,
          displayIsOpen: action.payload || false,
        },
      };

    case ConversationActionType.AI_QUESTIONNAIRE_FORM_LOADING:
      return {
        ...state,
        aiQuestionnaireForm: {
          ...state.aiQuestionnaireForm,
          isLoading: action.payload || false,
        },
      };

    case ConversationActionType.AI_QUESTIONNAIRE_FORM_PATIENT_ID:
      return {
        ...state,
        aiQuestionnaireForm: {
          ...state.aiQuestionnaireForm,
          patientId: action.payload,
        },
      };

    case ConversationActionType.SET_STATUS_AI_QUESTIONNAIRE_FORM: {
      return {
        ...state,
        aiQuestionnaireForm: {
          ...state.aiQuestionnaireForm,
          status: action.payload.status,
          agent: action.payload.agent || state.aiQuestionnaireForm.agent,
        },
      };
    }

    case ConversationActionType.AI_QUESTIONNAIRE_FORM_RESULT:
      return {
        ...state,
        aiQuestionnaireForm: {
          ...state.aiQuestionnaireForm,
          result: action.payload || null,
        },
      };

    case ConversationActionType.SET_AI_QUESTIONS:
      return {
        ...state,
        aiQuestions: action.payload,
      };

    case ConversationActionType.UPDATE_AI_ANSWERS: {
      const {questionId, newAnswer} = action.payload;

      const questions = state.aiQuestions || [];

      const updatedQuestions = questions.map((question) =>
        question.id === questionId
          ? {...question, answer: newAnswer}
          : question,
      );

      const questionExists = questions.some(
        (question) => question.id === questionId,
      );
      if (questionExists)
        return {
          ...state,
          aiQuestions: [...updatedQuestions],
        };
      else return {...state};
    }

    case ConversationActionType.SET_NEW_MESSAGES: {
      const messages = state.newMessages === null ? 1 : state.newMessages + 1;
      if (action.payload === null)
        return {
          ...state,
          newMessages: null,
        };
      if (action.conversationId === state.conversation?.id)
        return {
          ...state,
          isJumping: false,
          newMessages: messages,
        };
      else return state;
    }

    default:
      return state;
  }
};
