import {Conversation, SupportMatchStatus} from '@ifeelonline/chat-core';

export const isPatientB2C = (conversation: Conversation) =>
  conversation?.patientPartner === null;

export const isPatientMatched = (conversation: Conversation) =>
  conversation?.status === SupportMatchStatus.MATCHED;

export const isPatientWaiting = (conversation: Conversation) =>
  conversation?.status === SupportMatchStatus.WAITING;
