import React, {createContext, useContext, useState} from 'react';

import {AuthUserSupport} from '@ifeelonline/chat-core';

export interface UserContextType {
  isChatOnline: boolean;
  chatStateChanged: null | boolean;
  user: AuthUserSupport | null;
  setUser: React.Dispatch<React.SetStateAction<AuthUserSupport | null>>;
  setIsChatOnline: React.Dispatch<React.SetStateAction<boolean>>;
  setChatStateChanged: React.Dispatch<React.SetStateAction<null | boolean>>;
  location: LocationState;
  setLocation: React.Dispatch<React.SetStateAction<LocationState>>;
  currentAgentId: number | null;
  setCurrentAgentId: React.Dispatch<React.SetStateAction<number | null>>;
}
export interface NonNullableUserContextType
  extends Omit<UserContextType, 'user'> {
  user: AuthUserSupport;
}
interface LocationState {
  latitude: number | null;
  longitude: number | null;
  error: string | null;
}

export const UserContext = createContext<UserContextType | undefined>(
  undefined,
);
UserContext.displayName = 'UserContext';

function UserProvider({children}: {children: React.ReactNode}) {
  const [user, setUser] = useState<UserContextType['user']>(null);
  const [currentAgentId, setCurrentAgentId] =
    useState<UserContextType['currentAgentId']>(null);
  const [location, setLocation] = useState<UserContextType['location']>({
    latitude: null,
    longitude: null,
    error: null,
  });

  const [isChatOnline, setIsChatOnline] = useState<boolean>(false);
  const [chatStateChanged, setChatStateChanged] = useState<null | boolean>(
    null,
  );

  return (
    <UserContext.Provider
      value={{
        isChatOnline,
        setIsChatOnline,
        chatStateChanged,
        setChatStateChanged,
        user,
        setUser,
        location,
        setLocation,
        currentAgentId,
        setCurrentAgentId,
      }}>
      {children}
    </UserContext.Provider>
  );
}

function useUser() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export {UserProvider, useUser};
