import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {
  ChatProps,
  ResultSearch,
  SharedActionType,
  useScroll,
} from '@ifeelonline/chat-core';
import {SearcherMessages} from '@ifeelonline/storybook';

import dayjs from 'dayjs';

import {
  getPreviousMessagesToOne,
  searchMessages,
} from 'src/state/action_creators/conversationActionCreator';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {TextSearcher} from './TextSearcher';

interface Props {
  messagesDiv: any;
  closeSearcher: (value: boolean) => void;
  setFirstMsgRefId: React.Dispatch<React.SetStateAction<number | null>>;
  setIsLoading: (value: boolean) => void;
}

export const RenderSearcherMessages = ({
  msgRefs,
  closeSearcher,
  messagesDiv,
  setFirstMsgRefId,
  setIsLoading,
}: Props & Pick<ChatProps, 'msgRefs'>) => {
  const messagesFoundInit = {
    total: 0,
    messages: [],
  };

  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {scrollToMessage} = useScroll();
  const {conversation, messages} = useSelector(
    (state: RootState) => state.conversation,
  );

  const [searchTextBuffer, setSearchTextBuffer] = useState('');
  const [textToSearch, setTextToSearch] = useState('');
  const [messagesFound, setMessagesFound] =
    useState<ResultSearch>(messagesFoundInit);
  const [statusSearch, setStatusSearch] = useState<SharedActionType>(
    SharedActionType.IDLE,
  );

  const formatFoundMessageDate = (dateString: string) => {
    const messageDate = dayjs(dateString);
    return messageDate.format('DD MMMM YYYY HH:mm');
  };

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTextBuffer(target.value);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatusSearch(SharedActionType.LOADING);
    setTextToSearch(searchTextBuffer);

    if (searchTextBuffer.length > 2) {
      dispatch(searchMessages(conversation!.id!, searchTextBuffer)).then(
        (res) => {
          if (res) {
            setMessagesFound({
              messages: res.messages,
              total: res.total,
            });
            setStatusSearch(
              res.total > 0
                ? SharedActionType.FOUND
                : SharedActionType.NOT_FOUND,
            );
          }
        },
      );
    } else {
      setStatusSearch(
        searchTextBuffer.length === 0
          ? SharedActionType.IDLE
          : SharedActionType.FAILED,
      );
    }
  };

  const handleClickMsgFound = (
    e: React.MouseEvent<HTMLParagraphElement>,
    id: number,
  ) => {
    e.preventDefault();

    const messageToScroll = msgRefs?.current[id];

    setFirstMsgRefId(id);
    if (messageToScroll && document.contains(msgRefs?.current[id])) {
      if (messages.filter((message) => message.id === id).length) {
        if (msgRefs?.current && msgRefs.current[id]) {
          scrollToMessage({
            messageDiv: msgRefs.current[id] as unknown as HTMLDivElement,
            addBlinkingEffect: true,
            messagesDiv: messagesDiv,
          });
        }
      }
    } else {
      dispatch(getPreviousMessagesToOne(id, conversation!.id!)).then(
        (newPage) => {
          if (newPage) {
            dispatch({
              type: ConversationActionType.SET_PAGES_LOADED,
              payload: [newPage],
            });
            if (msgRefs?.current && msgRefs.current[id]) {
              scrollToMessage({
                messageDiv: msgRefs.current[id] as unknown as HTMLDivElement,
                addBlinkingEffect: true,
                messagesDiv: messagesDiv,
              });
              dispatch({
                type: ConversationActionType.JUMPING_TO_MESSAGES,
                payload: true,
              });
            }
          }
        },
      );
    }
  };

  return (
    <SearcherMessages
      isSupportChat={false}
      isSupportProject={true}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleClickMsgFound={handleClickMsgFound}
      statusSearch={statusSearch}
      textToSearch={textToSearch}
      messagesFound={messagesFound}
      searcherTitle={t('chat.searcher.title')}
      placeholderSearch={t('generic.search')}
      closeSearcher={closeSearcher}
      waitingText={TextSearcher}
      formatFoundMessageDate={formatFoundMessageDate}
    />
  );
};
