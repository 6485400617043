import React from 'react';

import {useTranslation} from 'react-i18next';

import {StopForbiddenDisabled} from '@ifeelonline/icons';
import {Text} from '@ifeelonline/storybook';

export const SurveyFormError = () => {
  const {t} = useTranslation();
  return (
    <div className="flex gap-5 py-5 pl-5 pr-8">
      <div className="flex w-full items-center justify-center gap-2 rounded-1 bg-semantic-danger-100 p-3">
        <div className="h-4 text-semantic-danger-700">
          <StopForbiddenDisabled fullHeight />
        </div>

        <Text textType={'body-3'} fontColor="text-text-400">
          {t('ai.form.error2')}
        </Text>
      </div>
    </div>
  );
};
