import {useTranslation} from 'react-i18next';

import {Text} from '@ifeelonline/storybook';

import {
  getAIQuestionnaireInfoDown,
  getAIQuestionnaireInfoLeft,
  getAIQuestionnaireInfoRight,
} from '../../utils/ai_questionnaire/renderInformation';

import {AIQuestionnaireInfoItem} from './AIQuestionnaireInfoItem';

export const AIQuestionnaireInfo = () => {
  const {t} = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center bg-backgrounds-8 p-10">
      <div className="flex flex-col items-center justify-center gap-8 rounded-3 border border-solid border-borders-aiQuestionnaire  bg-white p-10">
        <Text
          textType={'headline-3'}
          fontColor="text-text-600"
          fontWeight="semibold">
          {t('ai.info.title')}
        </Text>
        <div className="flex gap-12 pt-5">
          <div className="flex-1 flex-col">
            {getAIQuestionnaireInfoLeft().map((item, index) => (
              <div
                className={`flex-col border-b py-3 ${
                  index === getAIQuestionnaireInfoLeft.length - 1
                    ? 'border-none'
                    : 'border-solid  border-borders-aiQuestionnaire'
                }`}>
                <AIQuestionnaireInfoItem
                  title={item.title}
                  description={item.description}
                />
              </div>
            ))}
          </div>
          <div className="flex-1 flex-col">
            {getAIQuestionnaireInfoRight().map((item, index) => (
              <div
                className={`border-b py-3 ${
                  index === getAIQuestionnaireInfoRight.length - 1
                    ? 'border-none'
                    : 'border-solid border-borders-aiQuestionnaire'
                }`}>
                <AIQuestionnaireInfoItem
                  title={item.title}
                  description={item.description}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="flex w-full flex-col justify-start">
          <div className="flex w-full flex-col justify-start gap-3 pb-3">
            {' '}
            <Text
              textType={'body-1'}
              fontColor="text-text-500"
              fontWeight="semibold">
              {t('ai.info.sofa.name')}
            </Text>
            <Text textType={'body-3'} fontColor="text-text-400">
              {t('ai.info.sofa.title')}
            </Text>
          </div>
          {getAIQuestionnaireInfoDown().map((item) => (
            <AIQuestionnaireInfoItem
              description={item.description}
              small={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
