import {ApiResponse} from '@ifeelonline/chat-core';

import localStorageHelper from 'src/helpers/localStorage';
import {ChatStatus} from 'src/pages/chat/constants/chat';
import {fetchWrapper} from 'src/helpers/fetch';

export const startChecking = async () => {
  const {id, email} = localStorageHelper.getUserDetails();

  if (email && id && typeof id !== 'undefined' && id !== 'undefined') {
    const resp = await fetchWrapper({
      endPoint: `user?id=${id}`,
      data: null,
    });
    if (!resp) return null;
    if (!resp.body.success) {
      localStorage.clear();
      return false;
    }

    return resp.body.data;
  }
};

export const login = async (email: string, password: string) => {
  localStorage.clear();

  const resp: ApiResponse = await fetchWrapper({
    endPoint: 'sessions',
    data: {
      user: {email, password},
    },
    method: 'POST',
  });

  if (!resp) return null;
  if (!resp.body.success) return false;

  return resp.body.data;
};

export const logout = () => {
  return async () => {
    const resp = await fetchWrapper({
      endPoint: 'sessions',
      data: {},
      method: 'DELETE',
    });

    if (!resp) return null;
    if (!resp.body.success) return false;

    localStorage.clear();
    return true;
  };
};

export const changeStatusChat = (state: boolean, status: ChatStatus) => {
  return async () => {
    const resp = await fetchWrapper({
      endPoint: `users/change_status`,
      data: {state, status},
      method: 'POST',
    });

    if (!resp) return null;
    return resp.body.success;
  };
};
