import React, {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Loading, Themes} from '@ifeelonline/storybook';

import {endVideoCall} from 'src/state/action_creators/videoCallActionCreator';
import {useAppDispatch} from 'src/hooks/hooks';

export const VideoCallEnd = () => {
  const {meetingId, conversationId} = useParams<string>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (meetingId && conversationId) {
      const videoCallId = sessionStorage.getItem('videocallId');
      dispatch(endVideoCall(Number(videoCallId))).then((res) => {
        if (res) {
          window.close();
        }
      });
    }
  }, []);

  return (
    <div className="size-full bg-black">
      <Loading
        color={Themes.DefaultTheme.theme.extend.colors.brandPrimary[50]}
      />
    </div>
  );
};

export {VideoCallEnd as default};
