import React from 'react';

import {Navigate} from 'react-router-dom';

interface Props {
  isAuthenticated: boolean;
  children: JSX.Element;
}

export const PublicRoute = ({isAuthenticated, children}: Props) => {
  return isAuthenticated ? <Navigate to="/" /> : children;
};
