import {
  SurveyQuestion,
  SurveyQuestionStorage,
  SurveyQuestionTag,
} from 'src/pages/chat/types/Surveys';

export function mapApiResponseSurveyQuestions(
  jsonArray: SurveyQuestionStorage[],
): SurveyQuestion[] {
  return jsonArray.map((jsonBack) => {
    const mappedQuestion: SurveyQuestion = {
      id: jsonBack.id,
      surveyId: jsonBack.survey_id,
      position: jsonBack.position,
      body: jsonBack.body,
      tag: mapSurveyQuestionTag(jsonBack.tag),
      required: jsonBack.field === 'required',
    };

    if (jsonBack.question_options) {
      mappedQuestion.questionOptions = jsonBack.question_options.map(
        (option) => ({
          id: option.id,
          agentId: option.agent_id,
          userId: option.user_id,
          nickname: option.nickname,
          position: option.position,
          body: option.body,
        }),
      );
    }

    if (jsonBack.answer) {
      mappedQuestion.answer = {
        id: jsonBack.answer.id,
        questionId: jsonBack.answer.question_id,
        optionId: jsonBack.answer.option_id,
        comment: jsonBack.answer.comment,
        position: jsonBack.answer.position,
      };
    }

    return mappedQuestion;
  });
}

function mapSurveyQuestionTag(
  tag: SurveyQuestionStorage['tag'],
): SurveyQuestionTag {
  switch (tag) {
    case 'agent':
      return SurveyQuestionTag.Agent;
    case 'checkbox':
      return SurveyQuestionTag.Checkbox;
    case 'radio-button':
      return SurveyQuestionTag.RadioButton;
    case 'dropdown':
      return SurveyQuestionTag.Dropdown;
    case 'text':
      return SurveyQuestionTag.Text;
    case 'checkbox-comment':
      return SurveyQuestionTag.CheckboxComment;
    case 'radio-button-comment':
      return SurveyQuestionTag.RadioButtonComment;
    default:
      return SurveyQuestionTag.Text;
  }
}
