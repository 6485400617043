import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {Message, SharedActionType} from '@ifeelonline/chat-core';
import {Loading} from '@ifeelonline/storybook';

import {ChatContainerPicks} from 'src/pages/favorites/components/FavoritesContainer';
import {RenderMessages} from 'src/components/messages/RenderMessages';
import {RootState} from 'src/state';

import {NoFavorite} from './NoFavorite';

interface Props extends ChatContainerPicks {
  isLoading: boolean;
  areAllPagesLoaded: boolean;
  messagesEndRef: React.RefObject<HTMLDivElement>;
  messagesDiv: React.MutableRefObject<any>;
}

export const FavoritesContent = ({
  isLoading,
  areAllPagesLoaded,
  messagesEndRef,
  msgRefs,
  messagesDiv,
}: Props) => {
  const {t} = useTranslation();
  const {messages, status} = useSelector((state: RootState) => state.favorites);

  if (status === SharedActionType.LOADING) {
    return <Loading />;
  }

  // Messages available and not all deleted from favorites
  if (messages.some((message: Message) => !message.deletedFromFavorites)) {
    return (
      <>
        {areAllPagesLoaded && (
          <>
            <p className="text-body14 text-center font-sofia font-light text-text-blue9b">
              {t('chat.confidential') as string}
            </p>
            <hr className="mx-auto my-4 h-1 max-w-sm" />
          </>
        )}
        {isLoading && <Loading />}
        <RenderMessages
          messages={messages}
          msgRefs={msgRefs}
          isFavoritesView
          messagesDiv={messagesDiv}
        />
        <div ref={messagesEndRef} />
      </>
    );
  }

  return <NoFavorite />;
};
