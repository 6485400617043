import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';

import {ModalLayout, Text} from '@ifeelonline/storybook';
import {CheckCircle} from '@ifeelonline/icons';

import {useUser} from 'src/providers/UserProvider';
import {useChat} from 'src/providers/ChatProvider';
import {ModalBaseProps} from 'src/types/Modals';
import {isFemiSupport} from 'src/helpers/user';

interface Question {
  id: string;
  text: string;
  value: string;
}

export const AutomaticMessagesModal = ({close, isOpen}: ModalBaseProps) => {
  const {t} = useTranslation();
  const {handleInputChange} = useChat();
  const {user} = useUser();

  const [questionSelected, setQuestionSelected] = useState<Question | null>(
    null,
  );

  const predefinedQuestions = [...Array(4)].map((_, i) => ({
    id: `question_${i + 1}`,
    text: t(`modals.automatic_message.question_${i + 1}`),
    value: t(`modals.automatic_message.question_${i + 1}`),
  }));

  const predefinedQuestionsFemi = [...Array(6)].map((_, i) => ({
    id: `question_${i + 1}`,
    text: t(`modals.automatic_message_femi.text_${i + 1}`).replace(
      /\\n/g,
      '\n',
    ),
    value: t(`modals.automatic_message_femi.question_${i + 1}`).replace(
      /\\n/g,
      '\n',
    ),
  }));

  const questions =
    user && isFemiSupport(user) ? predefinedQuestionsFemi : predefinedQuestions;

  const handleSelect = (e: React.MouseEvent, q: Question) => {
    e.preventDefault();
    setQuestionSelected(q);
  };

  const closeModal = () => {
    setQuestionSelected(null);
    close();
  };

  const handleSubmit = () => {
    handleInputChange({
      target: {
        name: 'messageBody',
        value: questionSelected!.text,
      },
    } as React.ChangeEvent<HTMLTextAreaElement>);
    closeModal();
  };

  return (
    <ModalLayout
      closeModal={closeModal}
      confirmButtonAction={handleSubmit}
      confirmButtonText={t('generic.send')}
      show={isOpen}
      isConfirmButtonDisabled={questionSelected === null}
      title={t('modals.automatic_message.title')}>
      <Text textType="headline-4" fontWeight="semibold" className="mb-7">
        {t('modals.automatic_message.title')}
      </Text>
      <ul className="mb-4 text-start">
        {questions.map((q) => {
          const isSelected = questionSelected?.id === q.id;

          return (
            <li
              key={q.id}
              onClick={(e) => handleSelect(e, q)}
              className={`flex cursor-pointer items-center justify-between rounded-1 p-2 ${
                isSelected
                  ? 'bg-brandPrimary-50 text-brandPrimary-600'
                  : 'hover:bg-brandPrimary-50'
              }`}>
              <Text textType="body-1" className="max-w-9.5/10">
                {q.value}
              </Text>
              {isSelected && <CheckCircle />}
            </li>
          );
        })}
      </ul>
    </ModalLayout>
  );
};
