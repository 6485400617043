/* Survey questions */
export interface SurveyQuestion {
  answer?: SurveyQuestionAnswer;
  body: string;
  id: number;
  position: number;
  questionOptions?: SurveyQuestionOption[];
  rules?: {maxLength: number};
  required: boolean;
  surveyId: number;
  tag: SurveyQuestionTag;
}

export interface SurveyQuestionStorage {
  answer?: SurveyQuestionAnswerStorage;
  body: string;
  id: number;
  field: 'required' | 'no_required';
  position: number;
  question_options?: SurveyQuestionOptionStorage[];
  rules?: {max_number_characters: number};
  survey_id: number;
  tag: SurveyQuestionTagStorage;
}

type SurveyQuestionTagStorage =
  | 'agent'
  | 'checkbox'
  | 'text'
  | 'checkbox-comment'
  | 'dropdown'
  | 'number'
  | 'radio-button'
  | 'radio-button-comment'
  | 'text'
  | 'textarea';

export interface SurveyQuestionOptionStorage {
  id: number;
  agent_id?: number;
  user_id?: number;
  nickname?: string;
  position: number;
  body?: string;
}

export interface SurveyQuestionOption {
  id: number;
  agentId?: number;
  userId?: number;
  nickname?: string;
  position: number;
  body?: string;
}

export enum SurveyQuestionTag {
  RadioButton = 'radioButton',
  Dropdown = 'dropdown',
  Checkbox = 'checkbox',
  Text = 'text',
  Agent = 'agent',
  CheckboxComment = 'checkbox-comment',
  RadioButtonComment = 'radio-button-comment',
}

/* Survey Answers */
/* TO-DO review with back why do they make us send optionsIds in the request, but return optionId 
The problem is that the API is not consistent with the data it sends and receives. (receives optionId, but sends optionIds)
*/
export interface SurveyAnswer {
  questionId: number;
  optionIds?: number[];
  optionId?: number[];
  comment?: string;
  agentId?: number;
  type?: SurveyAnswerType;
}

export enum SurveyAnswerType {
  Agent = 'agent',
  Options = 'options',
  Comment = 'comment',
}

export interface SurveyQuestionAnswerStorage {
  id: number;
  question_id: number;
  option_id: number[];
  position: number;
  agent_id?: number; // Just for AI survey
  comment: string;
}

export interface SurveyQuestionAnswer {
  id: number;
  questionId: number;
  optionId: number[];
  comment: string;
  position: number;
  agentId?: number;
}

export interface SurveyUserQuestionnaire {
  id: number;
  userId: number;
  aiStatus: string | null;
  virtualAssistant: number;
  emotionalSupportChat: number;
  videoTherapy: number;
  referral: number;
  agentSelection: string | null;
}

/* Survey status */
export enum SurveyStatus {
  START = 0,
  DRAFT = 1,
  EDITING = 2,
  SAVED = 3,
}
