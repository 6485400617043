import React from 'react';

import {Button, Loading, Text} from '@ifeelonline/storybook';
import {CloseMedium} from '@ifeelonline/icons';

interface Props {
  handleClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading: boolean;
  title: string;
}

export const SurveyFormHeader = ({title, handleClose, isLoading}: Props) => {
  return (
    <div className="flex h-12 w-full items-center justify-between border-b border-solid border-borders-aiQuestionnaire px-6 capitalize">
      <Text textType="title" color="text-brandPrimary-700">
        {title}
      </Text>
      <div className="flex items-center gap-2">
        {isLoading && (
          <div className="w-4 text-text-400">
            <Loading size={20} isAbsolute={false} />
          </div>
        )}
        <Button
          buttonType={'default'}
          text={''}
          onClick={handleClose}
          icon={
            <div className="h-3 cursor-pointer text-text-400">
              <CloseMedium fullHeight />
            </div>
          }
        />
      </div>
    </div>
  );
};
