import {TriageSurveyRedux} from 'src/state/reducers/conversationReducer';
import {SurveyStatus} from 'src/pages/chat/types/Surveys';

export const shouldShowCompleteTriageButton = (
  triageSurveyList: TriageSurveyRedux['list'],
) => {
  const isMandatoryCompleted =
    triageSurveyList?.surveys.mandatory.status === SurveyStatus.SAVED;
  const isIntakeCompleted =
    triageSurveyList?.surveys.mandatory.status === SurveyStatus.SAVED;
  return (
    !triageSurveyList?.isTriageCompleted &&
    isMandatoryCompleted &&
    isIntakeCompleted
  );
};
