import {useEffect} from 'react';

import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';
import {Loading} from '@ifeelonline/storybook';

import {getPatient} from 'src/state/action_creators/patientActionCreator';
import {useAppDispatch} from 'src/hooks/hooks';
import {Navbar} from 'src/components/Navbar';
import {RootState} from 'src/state';

import {PatientInfoCard} from './components/patient_profile/PatientInfoCard';
import {VideoSessionsInfoCard} from './components/VideoSessionsInfoCard';

export const PatientInfoPage = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const {status, patient} = useSelector((state: RootState) => state.patient);

  const {id} = useParams<string>();

  useEffect(() => {
    if (id) {
      dispatch(getPatient(parseInt(id!)));
    }
  }, [id]);

  return (
    <div className="home__screen">
      <div className="h-screen">
        <Navbar chatView={false} />

        <div className="bg-f8 relative mx-auto my-0 min-h-full px-10 pb-12 pt-19">
          {status === SharedActionType.LOADING ? (
            <Loading />
          ) : (
            patient && (
              <div className="m-auto max-w-270">
                <h3 className="mb-3 font-sofia text-4 font-medium text-text-400">
                  {t('user.profile')}
                </h3>
                <PatientInfoCard patient={patient} />
                <div className="mt-20 flex items-start !justify-start">
                  {patient.videoSessionsRecord && (
                    <div className="flex-1">
                      <h3 className="mb-3 font-sofia text-4 font-medium text-text-400">
                        {t('videosessions.title')}
                      </h3>
                      <VideoSessionsInfoCard
                        videoSessionsRecord={patient.videoSessionsRecord}
                      />
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
