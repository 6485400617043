import {t} from 'i18next';

import {SurveyAnswer, SurveyQuestion} from 'src/pages/chat/types/Surveys';
import TriageSurveyRepository from 'src/services/TriageSurveyRepository';
import {getQuestionType} from 'src/pages/chat/utils/surveys/logic';
import {useChat} from 'src/providers/ChatProvider';
import {notify} from 'src/helpers/notify';

export const useSurveys = ({
  question,
  conversationId,
}: {
  question: SurveyQuestion;
  conversationId: number;
}) => {
  const {handleInputChange} = useChat();

  const updateAnswers = async (newAnswer: SurveyAnswer) => {
    const result = await TriageSurveyRepository.createAnswer(
      newAnswer,
      conversationId,
    );
    if (!result)
      notify({
        text: t('surveys.form.save_answer.error'),
        type: 'error',
        duration: 500000,
      });
  };

  const handleBlur = (questionId: number, textAnswer: string) => {
    {
      updateAnswers({
        questionId: questionId,
        type: getQuestionType(question.tag),
        comment: textAnswer,
      });
    }
  };

  const handleSendQuestionToPatient = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault();
    handleInputChange({
      target: {
        name: 'messageBody',
        value: `${question.body}${
          question.questionOptions
            ? question.questionOptions.map((option) => `\n • ${option.body}`)
            : ''
        }`,
      },
    } as React.ChangeEvent<HTMLTextAreaElement>);
  };

  return {
    handleBlur,
    updateAnswers,
    handleSendQuestionToPatient,
  };
};
