export const CHAT_FORM_INITIAL_VALUES = {
  messageBody: '',
};

export const APP_HEADER_HEIGHT = 60;
export const MESSAGE_BOX_HEIGHT = 88;
export const SEARCHER_WIDTH = 336;

export const INITIAL_PAGE = 1;

export const enum ChatStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
}
