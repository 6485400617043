import React from 'react';

import {FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {Button, Loading, Text} from '@ifeelonline/storybook';
import {Send} from '@ifeelonline/icons';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {TriageSurveyRedux} from 'src/state/reducers/conversationReducer';
import TriageSurveyRepository from 'src/services/TriageSurveyRepository';
import {TriageSurveySection} from 'src/pages/chat/types/TriageSurveys';
import {SurveyStatus} from 'src/pages/chat/types/Surveys';
import {useChat} from 'src/providers/ChatProvider';
import {useAppDispatch} from 'src/hooks/hooks';
import {notify} from 'src/helpers/notify';

import {SurveyFormContainer} from '../surveys/SurveyFormContainer';
import {SurveyFormQuestions} from '../surveys/SurveyFormQuestions';
import {SurveyFormHeader} from '../surveys/SurveyFormHeader';
import {SurveyFormError} from '../surveys/SurveyFormError';

export const TriageSurveyForm = ({
  triageSurveyForm,
  triageSurveySections,
  conversationId,
}: {
  triageSurveyForm: TriageSurveyRedux['form'];
  triageSurveySections: TriageSurveySection[];
  conversationId: number;
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm();
  const {handleInputChange} = useChat();

  const handleClose = async (
    e: React.MouseEvent<HTMLButtonElement>,
    triageSurveyForm: TriageSurveyRedux['form'],
  ) => {
    e.preventDefault();
    if (triageSurveyForm?.id) {
      await TriageSurveyRepository.updateStatus({
        conversationId,
        status: SurveyStatus.DRAFT,
        surveyId: triageSurveyForm.id,
      });

      dispatch({
        type: ConversationActionType.TRIAGE_SURVEY_FORM_OPEN,
        payload: {
          isOpen: false,
        },
      });
      if (triageSurveyForm.status !== SurveyStatus.SAVED)
        dispatch({
          type: ConversationActionType.TRIAGE_SURVEY_LIST_UPDATE_STATUS,
          payload: {
            surveyId: triageSurveyForm.id,
            status: SurveyStatus.DRAFT,
          },
        });
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    if (triageSurveyForm?.id) {
      const result = await TriageSurveyRepository.finishSurvey(
        triageSurveyForm.id,
        conversationId,
      );

      if (result) {
        dispatch({
          type: ConversationActionType.TRIAGE_SURVEY_FORM_OPEN,
          payload: {
            isOpen: false,
          },
        });

        dispatch({
          type: ConversationActionType.TRIAGE_SURVEY_LIST_UPDATE_STATUS,
          payload: {
            surveyId: triageSurveyForm.id,
            status: SurveyStatus.SAVED,
          },
        });

        notify({
          text: t('surveys.form.save.success'),
          type: 'success',
        });
      } else {
        notify({
          text: t('surveys.form.save.error'),
          type: 'error',
          duration: 500000,
        });
      }
    }
  };

  if (triageSurveyForm.isLoading) return <Loading isAbsolute={false} />;

  return (
    <>
      <SurveyFormHeader
        isLoading={triageSurveyForm.isLoading}
        handleClose={(e) => handleClose(e, triageSurveyForm)}
        title={triageSurveyForm.title || ''}
      />
      <SurveyFormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          {triageSurveySections.map((section, index) => (
            <div key={index}>
              <div className="group flex flex-row items-start justify-between py-3">
                <Text textType="body-3" fontWeight="bold">
                  {section.openingQuestion}
                </Text>
                <div
                  className="text-white opacity-0 group-hover:opacity-100"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInputChange({
                      target: {
                        name: 'messageBody',
                        value: `${section.openingQuestion}`,
                      },
                    } as React.ChangeEvent<HTMLTextAreaElement>);
                  }}>
                  <Button
                    buttonType="icon-button-primary"
                    shape="round"
                    icon={<Send color="white" />}
                  />
                </div>
              </div>
              {section.questions.map((question) => (
                <SurveyFormQuestions
                  conversationId={conversationId}
                  key={question.id}
                  question={question}
                  control={control}
                  errors={errors}
                />
              ))}
            </div>
          ))}

          {Object.keys(errors).length !== 0 && <SurveyFormError />}
          <div className="flex w-full flex-col items-center justify-center gap-2.5 py-5">
            <Text textType="caption">{t('surveys.form.info')}</Text>

            <Button
              type="submit"
              buttonType={'button-primary'}
              text={t('generic.finish')}
              loading={triageSurveyForm.isLoading}
            />
          </div>
        </form>
      </SurveyFormContainer>
    </>
  );
};
