import React, {ComponentProps} from 'react';

export const CardWhite = ({className, children}: ComponentProps<'div'>) => {
  return (
    <div
      className={`${
        className || 'p-6'
      } rounded-2.5 border border-borderConversation bg-white shadow-patientProfile `}>
      {children}
    </div>
  );
};
