import React from 'react';

import {Provider as ReduxProvider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {Toaster} from 'react-hot-toast';

import {ErrorBoundary, Provider as RollbarProvider} from '@rollbar/react';
import {createTheme, ThemeProvider} from '@mui/material';
import Rollbar from 'rollbar';

import {COLOR_TEXT_500, COLOR_TEXT_COLD_500} from 'src/constants/colors';
import {IS_DEVELOPMENT} from 'src/constants';
import 'src/styles/base/generals.scss';
import 'src/styles/base/fonts.scss';
import 'src/styles/base/reset.css';
import {store} from 'src/state';

import {ConnectToAgentChannelProvider} from './ConnectToAgentChannelProvider';
import {ActionCableProvider} from './ActionCableProvider';
import {UserProvider} from './UserProvider';

export const AppProviders = ({children}: {children: JSX.Element}) => {
  const rollbarConfig: Rollbar.Configuration = {
    accessToken: `${import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN}`,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: !IS_DEVELOPMENT,
    payload: {
      environment: `${import.meta.env.VITE_APP_ENV}`,
      client: {
        project: 'Ifeel agent chat',
      },
    },
  };

  const theme = createTheme({
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: COLOR_TEXT_500,
            fontSize: '14px',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: COLOR_TEXT_COLD_500,
            color: 'white',
            fontFamily: 'sofia',
            fontWeight: 400,
          },
        },
      },
    },
  });

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <ReduxProvider store={store}>
          <UserProvider>
            <ConnectToAgentChannelProvider>
              <ThemeProvider theme={theme}>
                <ActionCableProvider>
                  <BrowserRouter>
                    {children}
                    <Toaster />
                  </BrowserRouter>
                </ActionCableProvider>
              </ThemeProvider>
            </ConnectToAgentChannelProvider>
          </UserProvider>
        </ReduxProvider>
      </ErrorBoundary>
    </RollbarProvider>
  );
};

export default AppProviders;
