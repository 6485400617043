import React from 'react';

import {CallableModal} from '@ifeelonline/storybook';

import {AppRouter} from './router/AppRouter';
import AppProviders from './providers';

function App() {
  return (
    <AppProviders>
      <>
        <AppRouter />
        <CallableModal />
      </>
    </AppProviders>
  );
}

export default App;
