import {useEffect, useState} from 'react';

import {Route, Routes} from 'react-router-dom';

import {Loading} from '@ifeelonline/storybook';

import {AIQuestionnaireInfo} from 'src/pages/chat/components/ai_questionnaire/AIQuestionnaireInfo';
import {mapAuthSupportResponseStorageToAuthSupportResponse} from 'src/helpers/api_response/login';
import {loadAndSetCurrentAgentId, setLocationParams} from 'src/helpers/user';
import {startChecking} from 'src/state/action_creators/authActionCreator';
import {ChatSinglePage} from 'src/pages/chat_single/ChatSinglePage';
import {PatientInfoPage} from 'src/pages/patient/PatientInfoPage';
import {FavoritesPage} from 'src/pages/favorites/FavoritesPage';
import VideoCallPage from 'src/pages/video_call/VideoCall';
import localStorageHelper from 'src/helpers/localStorage';
import {ChatProvider} from 'src/providers/ChatProvider';
import {changeLanguage} from 'src/helpers/translations';
import {LoginPage} from 'src/pages/login/LoginPage';
import {useUser} from 'src/providers/UserProvider';
import {Custom404} from 'src/pages/Custom404page';
import VideoCallEnd from 'src/pages/VideoCallEnd';
import {ChatPage} from 'src/pages/chat/ChatPage';
import {ROUTES} from 'src/constants/Routes';

import {ScheduledChatPage} from '../pages/scheduled_chat/ScheduledChatPage';

import {PrivateRoute} from './PrivateRoute';
import {PublicRoute} from './PublicRoute';

export const AppRouter = () => {
  const {user, setUser, setIsChatOnline, setLocation, setCurrentAgentId} =
    useUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    startChecking().then((res) => {
      if (!res) {
        setUser(null);
      } else {
        const userNew = mapAuthSupportResponseStorageToAuthSupportResponse(
          res,
          localStorageHelper.getItem('email') ?? '',
        ).user;
        setUser(userNew);
        setIsChatOnline(userNew.state === 'true');

        changeLanguage(userNew.locale);
        setUser(userNew);
        setIsChatOnline(userNew.state === 'true');
      }
      setLoading(false);
    });

    setLocationParams(setLocation);
    loadAndSetCurrentAgentId(setCurrentAgentId);
  }, []);

  if (loading) return <Loading />;

  return (
    <Routes>
      <Route
        path={ROUTES.HOME}
        element={
          <PrivateRoute isAuthenticated={!!user}>
            <ChatProvider>
              <ChatPage />
            </ChatProvider>
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.SCHEDULED}
        element={
          <PrivateRoute isAuthenticated={!!user}>
            <ChatProvider>
              <ScheduledChatPage />
            </ChatProvider>
          </PrivateRoute>
        }
      />

      <Route
        path={ROUTES.CONVERSATION}
        element={
          <PrivateRoute isAuthenticated={!!user}>
            <ChatProvider>
              <ChatSinglePage />
            </ChatProvider>
          </PrivateRoute>
        }
      />

      <Route
        path={ROUTES.FAVORITE_MESSAGES}
        element={
          <PrivateRoute isAuthenticated={!!user}>
            <ChatProvider>
              <FavoritesPage />
            </ChatProvider>
          </PrivateRoute>
        }
      />

      <Route
        path={ROUTES.PATIENT}
        element={
          <PrivateRoute isAuthenticated={!!user}>
            <PatientInfoPage />
          </PrivateRoute>
        }
      />

      <Route
        index // <-- "/"
        path="*"
        element={
          <PrivateRoute isAuthenticated={!!user}>
            <Custom404 />
          </PrivateRoute>
        }
      />

      <Route
        path={ROUTES.AI_QUESTIONNAIRE_INFO}
        element={
          <PrivateRoute isAuthenticated={!!user}>
            <AIQuestionnaireInfo />
          </PrivateRoute>
        }
      />

      <Route
        path={ROUTES.VIDEO_CALL}
        element={
          <PrivateRoute isAuthenticated={!!user}>
            <VideoCallPage />
          </PrivateRoute>
        }
      />

      <Route
        path={ROUTES.END_VIDEO_CALL}
        element={
          <PrivateRoute isAuthenticated={!!user}>
            <VideoCallEnd />
          </PrivateRoute>
        }
      />

      {/* ---- PUBLIC ROUTES ---- */}
      <Route
        path={ROUTES.LOGIN}
        element={
          <PublicRoute isAuthenticated={!!user}>
            <LoginPage />
          </PublicRoute>
        }
      />
    </Routes>
  );
};
