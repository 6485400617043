import {useEffect, useRef, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {MessageRefs} from '@ifeelonline/chat-core';

import {ChatChannelSubscriber} from 'src/action_cable/subscribers/ChatChannelSubscriber';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {getConversation} from 'src/state/action_creators/conversationActionCreator';
import {AIAnswer} from 'src/pages/chat/types/AIQuestionnaire';
import {useChat} from 'src/providers/ChatProvider';
import {useUser} from 'src/providers/UserProvider';
import {useAppDispatch} from 'src/hooks/hooks';
import {Navbar} from 'src/components/Navbar';
import {RootState} from 'src/state';

import {AIQuestionnaireFormDisplay} from '../chat/components/ai_questionnaire/AIQuestionnaireFormDisplay';
import {AIQuestionnaireForm} from '../chat/components/ai_questionnaire/AIQuestionnaireForm';
import {connectToAgentChannelProvider} from '../../providers/ConnectToAgentChannelProvider';
import {AIQuestionnaireProvider} from '../../providers/AIQuestionnaireProvider';
import {ChatContainer} from '../chat/components/chat_section/ChatContainer';
import {TriageSurvey} from '../chat/components/triage_survey/TriageSurvey';
import {ChatPageProvider} from '../chat/components/ChatPageContext';
import {SidePanel} from '../chat/components/side_panel/SidePanel';
import {INITIAL_PAGE} from '../chat/constants/chat';

import {ScheduledConversationsList} from './ScheduledConversationsList';

export const ScheduledChatPage = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {formValues, resetFormValues, setNewMessages, conversation} = useChat();
  const [page, setPage] = useState(1);
  const {user} = useUser();
  const {aiQuestionnaireForm, triageSurvey} = useSelector(
    (state: RootState) => state.conversation,
  );
  const {setShouldDisconnectFromAgentChannel} = connectToAgentChannelProvider();

  const patientId = conversation?.patientId;
  const [areAllPagesLoaded, setAreAllPagesLoaded] = useState<boolean>(false);
  const [firstMsgRefId, setFirstMsgRefId] = useState<number | null>(null);
  const [answersAI, setAnswersAI] = useState<AIAnswer[]>([]);
  const msgRefs = useRef<MessageRefs>({});
  const readyForSubscription = user && conversation && conversation.patientId;

  const messagesDiv = useRef<any>(null); //@TODO TEMP ANY TO FIX THE TYPES OF THE FOWARD REF
  const [loading, setLoading] = useState<boolean>(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    setShouldDisconnectFromAgentChannel(true);
  }, []);

  useEffect(() => {
    dispatch({
      type: ConversationActionType.SET_PAGES_LOADED,
      payload: [INITIAL_PAGE],
    });

    setIsFirstLoad(true);
    setNewMessages(null);
    msgRefs.current = {};
  }, [patientId]);

  const getFirstPage = () => {
    setIsFirstLoad(true);
    if (msgRefs) msgRefs.current = {};
    if (conversation && conversation.id)
      dispatch(getConversation(conversation.id, INITIAL_PAGE)).then(() => {
        dispatch({
          type: ConversationActionType.SET_PAGES_LOADED,
          payload: [INITIAL_PAGE],
        });
      });
  };

  return (
    <ChatPageProvider>
      <div className="home__screen">
        <Navbar isScheduledView={true} />
        <div
          id="chat-page"
          className="bg-f8 relative top-15 flex h-vh-minus-15 flex-row">
          <ScheduledConversationsList
            page={page}
            setPage={setPage}
            setAreAllPagesLoaded={setAreAllPagesLoaded}
            reset={resetFormValues}
          />
          {readyForSubscription && (
            <ChatChannelSubscriber
              userId={conversation.patientId!}
              clientId={user.uid}
            />
          )}
          <ChatContainer
            isScheduledChat={true}
            formValues={formValues}
            resetFormValues={resetFormValues}
            msgRefs={msgRefs}
            areAllPagesLoaded={areAllPagesLoaded}
            setAreAllPagesLoaded={setAreAllPagesLoaded}
            isSingleConversation={false}
            setShowSearcher={null}
            messagesDiv={messagesDiv}
            setLoading={setLoading}
            loading={loading}
            isFirstLoad={isFirstLoad}
            setIsFirstLoad={setIsFirstLoad}
            patientId={patientId ? true : false}
            getFirstPage={getFirstPage}
            firstMsgRefId={firstMsgRefId}
            setFirstMsgRefId={setFirstMsgRefId}
          />
          {triageSurvey.form.isOpen && conversation?.id && (
            <TriageSurvey
              triageSurvey={triageSurvey}
              conversationId={conversation.id}
            />
          )}
          <AIQuestionnaireProvider>
            {aiQuestionnaireForm.isOpen && <AIQuestionnaireForm />}
            {aiQuestionnaireForm.displayIsOpen && (
              <AIQuestionnaireFormDisplay />
            )}
          </AIQuestionnaireProvider>
          <SidePanel isScheduledView={true} />
        </div>
      </div>
    </ChatPageProvider>
  );
};
