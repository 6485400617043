import {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {Button, ModalLayout, Text} from '@ifeelonline/storybook';
import {ChatStatusType} from '@ifeelonline/chat-core';

import {sendMessage} from 'src/state/action_creators/conversationActionCreator';
import googlePlayLogo from 'src/assets/images/store/google_play.png';
import appleLogo from 'src/assets/images/store/app_store.png';
import {ModalBaseProps as Props} from 'src/types/Modals';
import {useChat} from 'src/providers/ChatProvider';
import {useUser} from 'src/providers/UserProvider';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

enum storeType {
  IOS = 'ios',
  ANDROID = 'android',
}

export const AppLinksModal = ({isOpen, close}: Props) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const {user} = useUser();
  const {conversation} = useSelector((state: RootState) => state.conversation);
  const [storeSelected, setStoreSelected] = useState<storeType | null>(null);
  const [isSendingLink, setIsSendingLink] = useState(false);
  const {setChatStatus} = useChat();

  const sendLink = () => {
    setIsSendingLink(true);
    const alink =
      storeSelected === storeType.ANDROID
        ? `https://play.google.com/store/apps/details?id=com.ifeel.ifeeluserchat&hl=${
            conversation?.patientLocale ? conversation.patientLocale : 'en'
          }`
        : `https://itunes.apple.com/app/ifeel-modern-online-therapy/id1405536289?l=${
            conversation?.patientLocale ? conversation.patientLocale : 'en'
          }&ls=1&mt=8`;

    dispatch(
      sendMessage({
        body: alink,
        conversation_id: conversation!.id,
        patient_id: user!.uid,
        user_id: conversation!.patientId,
      }),
    );
    setStoreSelected(null);
    close();
    setChatStatus(ChatStatusType.DEFAULT);
  };

  return (
    <>
      <ModalLayout
        layout="basic"
        show={isOpen}
        title={t('chat_options.send_app_link')}
        size={'small'}
        showCloseButtonX={true}
        closeOutside={true}
        closeModal={() => {
          setStoreSelected(null);
          close();
        }}
        confirmButtonText={t('chat_options.send_link')}
        isConfirmButtonLoading={isSendingLink}
        confirmButtonAction={() => sendLink()}>
        <div className="flex flex-col gap-4 pb-4">
          {conversation && conversation.patientSource !== 'web' && (
            <Text textType={'body-2'}>
              {t('modals.app_links.patient_source', {
                source: conversation.patientSource,
              })}
            </Text>
          )}
          <div className="flex w-full justify-center gap-4">
            <div
              className={`flex items-center rounded-2 border-brandPrimary-600 p-4 transition-all duration-150 hover:border ${
                storeSelected === storeType.ANDROID && 'border'
              }`}>
              <Button
                buttonType="default"
                size="large"
                text={
                  <img
                    className="h-9 w-25"
                    src={googlePlayLogo}
                    alt={t('modals.app_links.google_play')}
                  />
                }
                onClick={() => setStoreSelected(storeType.ANDROID)}
              />
            </div>
            <div
              className={`flex items-center rounded-2 border-brandPrimary-600 p-4 transition-all duration-150 hover:border ${
                storeSelected === storeType.IOS && 'border'
              }`}>
              <Button
                buttonType="default"
                size="large"
                text={
                  <img
                    className="h-9 w-25"
                    src={appleLogo}
                    alt={t('modals.app_links.app_store')}
                  />
                }
                onClick={() => setStoreSelected(storeType.IOS)}
              />
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};
