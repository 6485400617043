/*TO-DO create some hooks with separated logic for answers, questions, result ... */
import {
  AIAnswer,
  AIAnswerType,
  AIQuestion,
  AIQuestionAnswer,
  AIQuestionNew,
  AIQuestionTag,
  AIUserQuestionnaire,
} from 'src/pages/chat/types/AIQuestionnaire';

export const isAgentQuestion = (questionTag: AIQuestionTag) =>
  questionTag === 'agent';

export const extractSOFASQuestion = (questions: AIQuestionNew[]) =>
  questions.filter((question) => question.isSOFAS)[0];

export const getQuestionType = (tag: AIQuestionTag): AIAnswer['type'] => {
  switch (tag) {
    case AIQuestionTag.Agent:
      return AIAnswerType.Agent;
    case AIQuestionTag.Text:
    case AIQuestionTag.Number:
      return AIAnswerType.Comment;
    default:
      return AIAnswerType.Options;
  }
};

export const extractAnswersFromQuestions = (questions: AIQuestion[] | null) => {
  if (!questions) {
    // eslint-disable-next-line no-console
    console.warn('Questions input is null');
    return [];
  }
  return questions.reduce<AIAnswer[]>((acc, question) => {
    const answerData = question.answer;
    if (!answerData) return acc;

    const answer: AIAnswer = {
      questionId: answerData.questionId,
      type: AIAnswerType.Comment,
    };

    if (answerData.agentId) {
      answer.agentId = answerData.agentId;
      answer.type = AIAnswerType.Agent;
    }
    if (answerData.optionId) {
      answer.optionIds = answerData.optionId;
      answer.type = AIAnswerType.Options;
    }
    if (answerData.comment) {
      answer.comment = answerData.comment;
    }

    acc.push(answer);
    return acc;
  }, []);
};

export const getResultMax = (aiData: AIUserQuestionnaire) => {
  const propertiesToCheck = [
    'virtualAssistant',
    'emotionalSupportChat',
    'videoTherapy',
    'referral',
  ];

  let maxValue = -Infinity;

  for (const property of propertiesToCheck) {
    const value = aiData[property as keyof AIUserQuestionnaire] as number;
    if (value > maxValue) maxValue = value;
  }

  return maxValue;
};

export const getAnswerDescription = (
  questionId: number,
  answer: AIQuestionAnswer | undefined,
  questions: AIQuestion[] | null,
) => {
  if (!answer) return undefined;
  if (answer?.comment) return answer.comment;

  const question = questions?.find((item) => item.id === questionId);
  if (!question) return undefined;

  if (answer?.agentId) {
    const option = question.questionOptions?.find(
      (option) => option.agentId === answer.agentId,
    );
    return option?.nickname;
  }

  if (answer.optionId) {
    const descriptions = answer.optionId
      .map((optionId) => {
        const option = question.questionOptions?.find(
          (option) => option.id === optionId,
        );
        return option?.body || null;
      })
      .filter(Boolean);

    return descriptions.join(', ');
  }

  return undefined;
};
