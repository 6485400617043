import {useState} from 'react';

import {useTranslation} from 'react-i18next';

import {ProgressBar, Text, Themes} from '@ifeelonline/storybook';
import {camelCaseToSnakeCase} from '@ifeelonline/chat-core';

import {
  AIQuestionnaireResult as AIQuestionnaireResultInterface,
  AIQuestionnaireShow,
  AIUserQuestionnaire,
} from 'src/pages/chat/types/AIQuestionnaire';
import {getResultMax} from 'src/pages/chat/utils/ai_questionnaire/logic';

interface Props {
  aiQuestionnairePossibleResult: AIQuestionnaireResultInterface[];
  aiQuestionnaireResult: AIQuestionnaireShow;
}

export const AIQuestionnaireResult = ({
  aiQuestionnaireResult,
  aiQuestionnairePossibleResult,
}: Props) => {
  const {t, i18n} = useTranslation();

  const maxResultValue = getResultMax(aiQuestionnaireResult.ai);
  const [hovered, setHovered] = useState<string | false>(false);

  return (
    <div className="flex flex-col gap-4 pb-7 pl-6 pr-4">
      <Text textType="body-3" fontColor="text-white">
        {t('ai.result.title')}
      </Text>
      {aiQuestionnairePossibleResult.map((result) => {
        const value = Math.round(
          (aiQuestionnaireResult.ai[
            result.key as keyof AIUserQuestionnaire
          ] as number) * 100,
        );

        const showValue =
          Math.round(maxResultValue * 100) ===
            Math.round(
              (aiQuestionnaireResult.ai[
                result.key as keyof AIUserQuestionnaire
              ] as number) * 100,
            ) || hovered === result.key;

        return (
          <div
            key={result.key}
            className="flex min-h-4 flex-col gap-4"
            onMouseEnter={() => setHovered(result.key)}
            onMouseLeave={() => setHovered(false)}>
            <div className="flex h-10 justify-between">
              <div className="flex items-center gap-2 overflow-hidden">
                <div className="h-4">{result.icon}</div>

                <div className="w-full overflow-hidden">
                  <Text textType="body-2" fontColor="text-white">
                    {t(`ai.result.${camelCaseToSnakeCase(result.key)}`)}
                  </Text>
                </div>
              </div>
              {showValue && (
                <div className="h-7 rounded-1 bg-slate-500 p-1">
                  {`${value}%`}
                </div>
              )}
            </div>

            <ProgressBar
              value={value}
              variant="determinate"
              barColor={result.color}
              locale={i18n.language === 'he' ? 'he-IL' : undefined}
              holderColor={
                Themes['DefaultTheme'].theme.extend.colors.deprecatedColors
                  .supportChat.progressBar
              }
            />
          </div>
        );
      })}
    </div>
  );
};
