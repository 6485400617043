import {
  ScheduledConversationAppointmentStorage,
  ScheduledConversationListItem,
  ScheduledConversationListItemStorage,
} from 'src/types/ConversationList';

import {
  mapApiResponsePlanType,
  mapApiResponseSupportMatchStatus,
} from './conversationList';

export function mapApiResponseScheduledConversationList(
  jsonBack: ScheduledConversationListItemStorage,
  typing: boolean,
) {
  const conversation: ScheduledConversationListItem = {
    id: jsonBack.id,
    patientId: jsonBack.patient_id,
    isNew: jsonBack.is_new ?? false,
    enabled: jsonBack.enabled ?? false,
    agentId: jsonBack.agent_id ?? null,
    status: mapApiResponseSupportMatchStatus(jsonBack.status),
    agentName: jsonBack.agent_name ?? '',
    patientInactive: jsonBack.patient_inactive ?? false,
    patientTypePlan: mapApiResponsePlanType(jsonBack.patient_type_plan),
    patientNickname: jsonBack.patient_nickname ?? '',
    patientPartner: jsonBack.patient_partner ?? null,
    patientState: jsonBack.patient_state,
    unreadMessages: jsonBack.unread_messages ?? 0,
    typing,
    lastMessageReceivedOn: jsonBack.appointment
      ? jsonBack.appointment.chat_time_start!
      : jsonBack.last_message_received_on!,
    lastMessageSentBy: jsonBack.last_message_sent_by ?? null,
    highRisk: jsonBack.high_risk ?? null,
    appointment: jsonBack.appointment
      ? mapApiResponseAppointment(jsonBack.appointment)
      : null,
  };
  return conversation;
}

const mapApiResponseAppointment = (
  jsonBack: ScheduledConversationAppointmentStorage,
) => {
  return {
    chatNow: jsonBack.chat_now,
    chatTimEnd: jsonBack.chat_time_end,
    chatTimeStart: jsonBack.chat_time_start,
    createdAt: jsonBack.created_at,
    id: jsonBack.id,
    title: jsonBack.title,
    updatedAt: jsonBack.updated_at,
    userId: jsonBack.user_id,
  };
};
