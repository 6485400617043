import React from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {
  ChatProps,
  Message as MessageInterface,
  formatDateMessageForSupport,
  useScroll,
} from '@ifeelonline/chat-core';
import {Message, modalService} from '@ifeelonline/storybook';

import {
  deleteMessage,
  getPreviousMessagesToOne,
  handleMessageFavorite,
} from 'src/state/action_creators/conversationActionCreator';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {uiEditMsg, uiReplyTo} from 'src/state/action_creators/uiActionCreator';
import {useChatTranslations} from 'src/pages/chat/hooks/useChatTranslations';
import {useChat} from 'src/providers/ChatProvider';
import {useUser} from 'src/providers/UserProvider';
import {useAppDispatch} from 'src/hooks/hooks';
import {IS_DEVELOPMENT} from 'src/constants';
import {RootState} from 'src/state';

interface Props {
  message: MessageInterface;
  isFavoritesView: boolean;
  msgRefs: ChatProps['msgRefs'];
  messagesDiv: ChatProps['messagesDiv'];
  setFirstMsgRefId?: React.Dispatch<React.SetStateAction<number | null>>;
}

export const RenderMessage = ({
  message,
  isFavoritesView,
  msgRefs,
  messagesDiv,
  setFirstMsgRefId,
}: Props) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {scrollToMessage} = useScroll();
  const {setImageBigUrl} = useChat();

  const {user} = useUser();
  const {messageTranslations} = useChatTranslations();

  const {conversation} = useSelector((state: RootState) => state.conversation);

  const handleFavorite = (selectedMessage: MessageInterface) => {
    dispatch(
      handleMessageFavorite(selectedMessage.id, !message.isFavorite),
    ).then((isResponseOk) => {
      const successMessage = message.isFavorite
        ? t('chat.favorites.deleted')
        : t('chat.favorites.saved');

      return isResponseOk
        ? toast.success(successMessage)
        : toast.error(t('errors.generic.title'));
    });
  };

  const handleReplyToMessage = (selectedMessage: MessageInterface) => {
    dispatch(uiReplyTo(selectedMessage));
  };

  const handleEditMessage = (selectedMessage: MessageInterface) => {
    dispatch(uiEditMsg(selectedMessage));
  };

  const handleDeleteMessage = (selectedMessage: MessageInterface) => {
    modalService.fire({
      show: true,
      closeOutside: true,
      title: t('chat.sure_to_delete_msg'),
      cancelButtonText: t('generic.no'),
      confirmButtonText: t('generic.yes'),
      confirmButtonAction: () => {
        dispatch(deleteMessage(selectedMessage.id, conversation!.id!)).then(
          () => toast.success(t('chat.msg_deleted_successfully')),
        );
        modalService.close(); //Maybe we should add an alwaysAction to confirmModal, to fire this action always
      },
      cancelButtonAction: () => {
        modalService.close();
      },
      closeModal: () => {
        modalService.close();
      },
      showCloseButtonX: false,
      reverseButtons: true,
    });
  };

  const handleReplyClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    replyMessageId: string | number,
  ) => {
    const messageId = Number(replyMessageId);

    let messageReply = msgRefs?.current[messageId];
    if (setFirstMsgRefId) setFirstMsgRefId(messageId);

    if (messageReply && document.contains(messageReply)) {
      scrollToMessage({
        messageDiv: messageReply,
        messagesDiv: messagesDiv,
      });
    } else {
      dispatch(getPreviousMessagesToOne(messageId, conversation!.id!)).then(
        (res) => {
          if (res) {
            dispatch({
              type: ConversationActionType.SET_PAGES_LOADED,
              payload: [res],
            });
            if (msgRefs?.current[messageId]) {
              messageReply = msgRefs.current[messageId];
              if (messageReply)
                scrollToMessage({
                  messageDiv: messageReply,
                  messagesDiv: messagesDiv,
                });
            }
          }
        },
      );
    }
  };

  return (
    <Message
      dateFormatted={formatDateMessageForSupport(message.receivedOn)}
      handleClick={function () {}}
      handleClickReply={handleReplyClick}
      handleDeleteMessage={handleDeleteMessage}
      handleEditMessage={handleEditMessage}
      handleFavoriteMessage={handleFavorite}
      handleReplyToMessage={handleReplyToMessage}
      isDevelopmentEnv={IS_DEVELOPMENT}
      isFavoritesView={isFavoritesView}
      isOwnMessage={message?.userId === user?.id}
      isPatientRole={false}
      message={message}
      msgRefs={msgRefs}
      setImageBigUrl={setImageBigUrl}
      translations={messageTranslations}
    />
  );
};
