import {
  Agent,
  CouponAvailable,
  PlanAvailable,
  SharedActionType,
  SupportMatchStatus,
} from '@ifeelonline/chat-core';

import {mapApiResponseConversationList} from 'src/helpers/api_response/conversationList';
import {
  ConversationList,
  ConversationListItem,
} from 'src/types/ConversationList';
import {ConversationsActionType} from 'src/state/action_types/ConversationsActionType';
import {CONVERSATIONS_FILTERS} from 'src/pages/chat/constants/conversationFilters';
import {ConversationsAction} from 'src/state/actions/conversations';

interface ConversationsState {
  activeAgents: Agent[] | null;
  activeConversationId: number | null;
  conversations: ConversationList | null;
  couponsAvailable: CouponAvailable[] | null;
  defaultAgentPosition: number | null; // used to set the "no agent option" in the agent dropdown
  error: string | null;
  filter: CONVERSATIONS_FILTERS | null;
  plansAvailable: PlanAvailable[] | null;
  status: SharedActionType;
  total: number | null;
}

const initialState = {
  activeAgents: null,
  activeConversationId: null,
  conversations: null,
  couponsAvailable: null,
  defaultAgentPosition: null,
  error: null,
  filter: null,
  plansAvailable: null,
  total: null,
  status: SharedActionType.IDLE,
};

export const conversationsReducer = (
  state: ConversationsState = initialState,
  action: ConversationsAction,
): ConversationsState => {
  switch (action.type) {
    case ConversationsActionType.CONVERSATIONS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case ConversationsActionType.CONVERSATIONS_LOAD_MORE_CONVERSATIONS: {
      const newConversation: ConversationList | null = action.payload;
      const results = newConversation?.filter(
        ({id: id1}: {id: number}) =>
          !state.conversations!.some(({id: id2}) => id2 === id1),
      );
      return {
        ...state,
        conversations:
          results && results.length > 0
            ? [...state.conversations!, ...results]
            : [...state.conversations!],
        status: SharedActionType.COMPLETED,
      };
    }

    case ConversationsActionType.CONVERSATIONS_CHAT_OPTIONS:
      return {
        ...state,
        activeAgents: action.payload.activeAgents,
        defaultAgentPosition: action.payload.defaultAgentPosition,
        couponsAvailable: action.payload.couponsAvailable,
        plansAvailable: action.payload.plansAvailable,
      };

    case ConversationsActionType.SET_ACTIVE_CONVERSATION: {
      return {
        ...state,
        activeConversationId: action.payload,
      };
    }
    case ConversationsActionType.CONVERSATIONS_NEW_MESSAGE: {
      const newConversation: ConversationListItem =
        mapApiResponseConversationList(
          {
            unread_messages: action.payload.unread_messages_support,
            ...action.payload,
          },
          false,
        );
      if (!state.conversations) {
        return {...state};
      }
      const isConversationInsideFilter = shouldIncludeConversation(
        state.filter,
        newConversation,
      );
      const isActiveConversation =
        state.activeConversationId === newConversation.id;
      if (conversationAlreadyExists(state.conversations, newConversation.id)) {
        // Update the conversation list
        // If the message originates from the patient, we need to reorder the conversations
        // to ensure the most recent conversation is displayed at the top.
        if (action.payload.isPatientMessage) {
          return {
            ...state,
            conversations:
              state.conversations && isConversationInsideFilter
                ? [
                    {
                      ...newConversation,
                      unreadMessages:
                        !isActiveConversation &&
                        action.payload.unread_messages_support
                          ? action.payload.unread_messages_support
                          : 0,
                    },
                    ...state.conversations.filter(
                      (conversation) =>
                        conversation.id !== action.payload.conversation_id,
                    ),
                  ]
                : state.conversations,
          };
        } else {
          return {
            ...state,
            conversations: isConversationInsideFilter
              ? state.conversations.map((conversation) =>
                  conversation.id === action.payload.conversation_id
                    ? {
                        ...newConversation,
                        // unreadMessages: conversation.unreadMessages + 1,
                      }
                    : conversation,
                )
              : state.conversations,
          };
        }
      } else {
        // For messages from support simply update the conversation's data
        // without reordering the list.
        return {
          ...state,
          conversations: isConversationInsideFilter
            ? [newConversation, ...state.conversations]
            : state.conversations,
        };
      }
    }
    case ConversationsActionType.CONVERSATIONS_NEW_USER: {
      const newConversation: ConversationListItem =
        mapApiResponseConversationList(action.payload, false);
      return {
        ...state,
        conversations: state.conversations
          ? [newConversation, ...state.conversations]
          : null,
      };
    }

    case ConversationsActionType.CONVERSATIONS_MATCHED:
      if (state.conversations) {
        return {
          ...state,
          conversations: state.conversations.map((c) =>
            c.id === action.payload.conversationId
              ? {
                  ...c,
                  isNew: false,
                  status: SupportMatchStatus.MATCHED,
                  agentId: action.payload.agentId,
                  agentName: action.payload.agentName,
                }
              : c,
          ),
        };
      } else {
        return {
          ...state,
        };
      }
    case ConversationsActionType.CONVERSATIONS_TYPING:
      if (state.conversations) {
        return {
          ...state,
          conversations: state.conversations.map((c) =>
            c.patientId === action.payload.user_id
              ? {...c, typing: action.payload.state}
              : c,
          ),
        };
      } else {
        return {
          ...state,
        };
      }

    case ConversationsActionType.CONVERSATIONS_USER_STATUS:
      // If the user is offline, we need to set the typing status to false
      if (state.conversations) {
        return {
          ...state,
          conversations: state.conversations.map((c) =>
            c.patientId === action.payload.user_id
              ? {
                  ...c,
                  patientState: action.payload.status,
                  typing: action.payload.status === false ? false : c.typing,
                }
              : c,
          ),
        };
      } else {
        return {
          ...state,
        };
      }

    case ConversationsActionType.CONVERSATIONS_MARK_AS_READ:
      if (state.conversations) {
        return {
          ...state,
          conversations: state.conversations.map((c) =>
            c.id && c.id === action.payload.conversation_id
              ? {...c, unreadMessages: 0}
              : c,
          ),
        };
      } else {
        return {
          ...state,
        };
      }

    case ConversationsActionType.CONVERSATIONS_AGENT_CHANGED:
      if (state.conversations) {
        return {
          ...state,
          conversations: state.conversations.map((c) =>
            c.id && c.id === action.payload.conversation_id
              ? {...c, agentName: action.payload.agent_nickname}
              : c,
          ),
        };
      } else {
        return {
          ...state,
        };
      }

    case ConversationsActionType.SET_CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload.conversations,
        filter: action.payload.filter,
        total: action.payload.total ?? 0,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};

function shouldIncludeConversation(
  filter: CONVERSATIONS_FILTERS | null,
  newConversation: ConversationListItem,
): boolean {
  switch (filter) {
    case CONVERSATIONS_FILTERS.B2B:
      return newConversation.patientPartner ? true : false;
    case CONVERSATIONS_FILTERS.B2C:
      return !newConversation.patientPartner;
    case CONVERSATIONS_FILTERS.NO_AGENT:
      return newConversation.status !== SupportMatchStatus.MATCHED;
    case CONVERSATIONS_FILTERS.LIMBO:
      return newConversation.status === SupportMatchStatus.UNMATCHED;
    case CONVERSATIONS_FILTERS.UNREAD_MESSAGES:
      return newConversation.unreadMessages > 0;
    default:
      return true;
  }
}
function conversationAlreadyExists(
  conversations: ConversationList,
  conversationId: ConversationListItem['id'],
) {
  return conversations.find(
    (conversation) => conversation.id === conversationId,
  );
}
