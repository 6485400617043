import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {Button, Loading, Text} from '@ifeelonline/storybook';

import localStorageHelper from 'src/helpers/localStorage';
import {useUser} from 'src/providers/UserProvider';
import {RootState} from 'src/state';

export const SelectCurrentAgent = () => {
  const {t} = useTranslation();
  const {activeAgents} = useSelector((state: RootState) => state.conversations);
  const [agentSelected, setAgentSelected] = useState<number | null>(null);
  const {setCurrentAgentId, currentAgentId} = useUser();

  const handleSelectChange = ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>) => {
    const value = target.value === '' ? null : Number(target.value);
    setAgentSelected(value);
  };

  const handleClick = () => {
    if (!setCurrentAgentId) {
      return;
    }
    setCurrentAgentId(agentSelected);
    localStorageHelper.setCurrentAgent(agentSelected);
  };

  if (currentAgentId) return null;

  return (
    <>
      <div className="fixed inset-0 z-40 bg-black/50"></div>
      <div className="fixed left-1/2 top-2/10 z-50 -translate-x-1/2 -translate-y-1/2 rounded-2 bg-white p-8 text-center shadow-md">
        {activeAgents ? (
          <>
            <div className="m-auto mb-4 max-w-60">
              <Text textType="subtitle">{t('modals.select_agent.title')}</Text>
            </div>

            <select value={agentSelected || ''} onChange={handleSelectChange}>
              <option value="" disabled>
                {t('modals.select_agent.select')}
              </option>{' '}
              {activeAgents.map(
                (agent) =>
                  agent.id && (
                    <option
                      key={agent.id}
                      value={agent.id!}
                      id={agent.id.toString()}>
                      {agent.nickname}
                    </option>
                  ),
              )}
            </select>
            <div className="mt-3 flex items-center justify-center">
              <Button
                buttonType="button-primary"
                text={t('generic.continue')}
                onClick={handleClick}
                size="small"
                disabled={agentSelected === null}
              />
            </div>
          </>
        ) : (
          <Loading isAbsolute={false} />
        )}
      </div>
    </>
  );
};
