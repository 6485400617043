import {Control, Controller, FieldErrors, FieldValues} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {InputErrorMessage} from '@ifeelonline/storybook';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {createAnswer} from 'src/state/action_creators/conversationActionCreator';
import {AIQuestionNew} from 'src/pages/chat/types/AIQuestionnaire';
import {useAppDispatch} from 'src/hooks/hooks';

export const RenderNumberInput = ({
  question,
  control,
  errors,
  handleBlur,
  defaultValue,
  placeholder,
  conversationId,
}: {
  question: AIQuestionNew;
  control: Control<FieldValues>;
  errors: FieldErrors<FieldValues>;
  handleBlur: (id: number, value: string) => void;
  defaultValue?: string;
  placeholder?: string;
  conversationId?: number;
}) => {
  const {t} = useTranslation();
  const hasError = (name: string) =>
    errors[name]?.type === 'required'
      ? t('ai.form.error_required')
      : errors[name]?.message?.toString();

  const dispatch = useAppDispatch();

  return (
    <Controller
      name={question.id.toString()}
      control={control}
      defaultValue={defaultValue ?? (question.answer?.comment || '')}
      rules={{
        required: question.required,
        maxLength: question.rules?.maxLength
          ? {
              value: question.rules?.maxLength,
              message: t('ai.form.maxLength', {
                length: question.rules?.maxLength,
              }),
            }
          : undefined,
      }}
      render={({field: {value, onChange, name}}) => (
        <div>
          <input
            className={`h-12 w-full p-2.5 ${hasError(name) ? 'border-semantic-danger-500' : 'border-text-300'}`}
            type="number"
            value={value}
            onBlur={async () => {
              if (conversationId && question.answer?.comment !== '')
                await dispatch(createAnswer(conversationId, question)).then(
                  (res) => {},
                );
            }}
            onChange={(e) => {
              if (e.target.value.length <= 3) {
                onChange(e);

                dispatch({
                  type: ConversationActionType.UPDATE_AI_ANSWERS,
                  payload: {
                    questionId: question.id,
                    newAnswer: {
                      questionId: question.id,
                      comment: e.target.value,
                    },
                  },
                });
              }
            }}
            maxLength={question.rules?.maxLength}
            placeholder={placeholder}
          />

          <InputErrorMessage
            text={(typeof hasError(name) === 'string' && hasError(name)) || ''}
          />
        </div>
      )}
    />
  );
};
