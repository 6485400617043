import React, {useCallback, useMemo} from 'react';

import {Control, Controller, FieldErrors, FieldValues} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {Text} from '@ifeelonline/storybook';

import {
  AIQuestionNew,
  AIQuestionTag,
} from 'src/pages/chat/types/AIQuestionnaire';
import {createAnswer} from 'src/state/action_creators/conversationActionCreator';
import {useAppDispatch} from 'src/hooks/hooks';

import {RenderNumberInput} from './form_fields/RenderNumberInput';
import {RenderRadioButton} from './form_fields/RenderRadioButton';
import {RenderCheckbox} from './form_fields/RenderCheckbox';
import {RenderDropdown} from './form_fields/RenderDropdown';
import {RenderTextArea} from './form_fields/RenderTextArea';

interface Props {
  question: AIQuestionNew;
  conversationId: number;
  control: Control<FieldValues>;

  errors: FieldErrors<FieldValues>;
  isLastQuestion: boolean;
}

export const AIRenderQuestions = React.memo(function AIRenderQuestions({
  question,
  conversationId,
  control,
  errors,
  isLastQuestion = false,
}: Props) {
  const dispatch = useAppDispatch();
  const handleBlur = () => async () => {
    await dispatch(createAnswer(conversationId, question)).then((res) => {});
  };

  const {t} = useTranslation();

  const getDefaultValue = useCallback(() => {
    if (question.answer?.agentId) return question.answer?.agentId;
    if (question.answer?.optionIds && question.answer.optionIds.length > 0)
      return question.answer.optionIds[0];
    return '';
  }, [question]);

  const RenderQuestionContent = useMemo(() => {
    switch (question.tag) {
      case AIQuestionTag.Agent:
      case AIQuestionTag.Dropdown:
        return (
          <Controller
            name={question.id.toString()}
            control={control}
            defaultValue={getDefaultValue()}
            rules={{required: question.required}}
            render={({field: {name, value, onChange}}) => (
              <RenderDropdown
                options={
                  question.questionOptions
                    ? question.questionOptions.map((option) => ({
                        text: option.body || option.nickname || '',
                        value: option.agentId ? option.agentId : option.id,
                      }))
                    : []
                }
                conversationId={conversationId}
                selectedOption={value}
                handleChange={onChange}
                question={question}
                error={
                  errors[name]?.type === 'required'
                    ? t('ai.form.error_required')
                    : null
                }
              />
            )}
          />
        );

      case AIQuestionTag.RadioButton:
        return question.questionOptions ? (
          <Controller
            name={question.id.toString()}
            control={control}
            defaultValue={
              question.answer?.optionIds && question.answer.optionIds.length > 0
                ? question.answer.optionIds[0]
                : ''
            }
            rules={{required: question.required}}
            render={({field: {name, value, onChange}}) => (
              <RenderRadioButton
                questionOptions={question.questionOptions || []}
                selectedOption={value}
                handleRadioButtonChange={onChange}
                displayInColumn={question.position === 5}
                question={question}
                conversationId={conversationId}
                error={
                  errors[name]?.type === 'required'
                    ? t('ai.form.error_required')
                    : null
                }
              />
            )}
          />
        ) : null;

      case AIQuestionTag.Checkbox:
        return question.questionOptions ? (
          <Controller
            name={question.id.toString()}
            control={control}
            defaultValue={
              question.answer?.optionIds && question.answer.optionIds.length > 0
                ? question.answer.optionIds
                : []
            }
            rules={{required: question.required}}
            render={({field: {value, name, onChange}}) => (
              <RenderCheckbox
                questionOptions={question.questionOptions || []}
                selectedOptions={value}
                handleCheckboxChange={onChange}
                question={question}
                conversationId={conversationId}
                isError={errors[name]?.type ? true : false}
              />
            )}
          />
        ) : null;

      case AIQuestionTag.Number:
        return (
          <RenderNumberInput
            question={question}
            control={control}
            errors={errors}
            handleBlur={handleBlur}
            conversationId={conversationId}
          />
        );

      case AIQuestionTag.Text:
        return (
          <RenderTextArea
            question={question}
            conversationId={conversationId}
            control={control}
            errors={errors}
            handleBlur={handleBlur}
          />
        );

      default:
        return <></>;
    }
  }, [question, control, getDefaultValue, errors, t, handleBlur]);

  return (
    <div className="flex">
      <div
        key={`questionHolder-${question.id}`}
        className={`new-comp flex w-full flex-col gap-3 py-2.5 text-start ${
          !isLastQuestion &&
          'border-b border-solid border-borders-aiQuestionnaire'
        }`}>
        <Text textType={'body-3'} fontColor="text-text-400">
          {question.body}
        </Text>
        {RenderQuestionContent}
      </div>
    </div>
  );
});
