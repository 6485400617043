import {Dispatch} from 'redux';

import {
  ConversationList,
  ScheduledConversationListItemStorage,
} from 'src/types/ConversationList';
import {ConversationsActionType} from 'src/state/action_types/ConversationsActionType';
import {CONVERSATIONS_FILTERS} from 'src/pages/chat/constants/conversationFilters';
import {ConversationsAction} from 'src/state/actions/conversations';
import {fetchWrapper} from 'src/helpers/fetch';

import {mapApiResponseScheduledConversationList} from '../../helpers/api_response/ScheduledConversationList';

export const getScheduledConversations = (
  page: number,
  filter: CONVERSATIONS_FILTERS | null,
  currentAgentId: number | null,
) => {
  return async (dispatch: Dispatch<ConversationsAction>) => {
    if (page === 1) {
      dispatch({type: ConversationsActionType.CONVERSATIONS_LOADING});
    }
    const highRiskFilter =
      filter === CONVERSATIONS_FILTERS.HIGH_RISK ? '&high_risk=true' : '';
    const resp = await fetchWrapper({
      endPoint: `conversations/incoming_patients?page=${page}&psychologist_guide_id=${currentAgentId}${highRiskFilter}`,
    });

    if (!resp) return null;
    if (!resp.body.success) return false;

    const {conversations, total_count} = resp.body;
    const conversationList: ConversationList = conversations?.map(
      (conversationListStorage: ScheduledConversationListItemStorage) =>
        mapApiResponseScheduledConversationList(conversationListStorage, false),
    );

    if (page === 1) {
      dispatch({
        type: ConversationsActionType.SET_CONVERSATIONS,
        payload: {
          conversations: conversationList,
          filter,
          total: total_count,
        },
      });
      return conversationList.length;
    } else {
      if (conversationList && conversationList.length > 0) {
        dispatch({
          type: ConversationsActionType.CONVERSATIONS_LOAD_MORE_CONVERSATIONS,
          payload: conversationList,
        });
        return true;
      } else {
        return false;
      }
    }
  };
};
