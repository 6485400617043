import {mapApiResponsePatient, PatientStorage} from '@ifeelonline/chat-core';

import {Dispatch} from 'redux';

import {PatientActionType} from 'src/state/action_types/PatientActionType';
import {PatientAction} from 'src/state/actions/patient';
import {fetchWrapper} from 'src/helpers/fetch';

export const getPatient = (id: number) => {
  return async (dispatch: Dispatch<PatientAction>) => {
    dispatch({type: PatientActionType.PATIENT_LOADING});

    const resp = await fetchWrapper({endPoint: `users/${id}`});

    if (!resp) return null;
    if (!resp.body.success || !resp.body.patient) return false;

    const {patient}: {patient: PatientStorage} = resp.body;

    const patientMapped = mapApiResponsePatient(patient);

    dispatch({
      type: PatientActionType.SET_PATIENT,
      payload: patientMapped,
    });

    return patient;
  };
};
