import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {Button} from '@ifeelonline/storybook';

import {CaretDown, CaretUp, FileText} from 'phosphor-react';

import {setSurvey} from 'src/state/action_creators/conversationActionCreator';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {useUser} from '../../../../../providers/UserProvider';
import {isFemiSupport} from '../../../../../helpers/user';

export const SurveyInfoSection = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {user} = useUser();

  const {conversation, userSurvey} = useSelector(
    (state: RootState) => state.conversation,
  );

  const [showSurvey, setShowSurvey] = useState(false);

  const toggleShowSurvey = (conversationId: number) => {
    if (!userSurvey) dispatch(setSurvey(conversationId, isFemiSupport(user!)));
    setShowSurvey(!showSurvey);
  };

  return (
    <>
      <div className="flex h-14 w-full items-center justify-between border-t border-solid border-backgrounds-13 px-5 py-2.5 text-white hover:bg-borders-sidebarHover disabled:opacity-50">
        <Button
          buttonType="default"
          onClick={() => toggleShowSurvey(conversation!.id!)}
          size="large"
          text={
            <div className="w-17/18 text-left text-white">
              <FileText size={24} className="inline-block disabled:hidden" />
              <span className="ms-2 font-sofia text-4 font-normal">
                {t('chat_options.survey') as string}
              </span>
            </div>
          }
          iconPosition="end"
          icon={
            showSurvey ? (
              <CaretUp size={12} color="white" />
            ) : (
              <CaretDown size={12} color="white" />
            )
          }
        />
      </div>
      {showSurvey && userSurvey && userSurvey.answers && (
        <ul id="userSurvey" className="px-6 pb-5">
          {userSurvey.answers.map((a, index: number) => (
            <li
              key={index}
              className="mb-2.5 flex flex-col gap-0.5 truncate font-sofia text-4 font-light">
              <span className="text-2.5">{a.tag}</span>
              <p>{a.result}</p>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
