import React from 'react';

import ReactDOM from 'react-dom/client';

import '@ifeelonline/storybook/dist/themes/DefaultTheme.css';
import '@ifeelonline/storybook/dist/style.css';
import './themes/DefaultTheme.css'; /* */
import './customPreflight.css';
import './styles/style.scss';
import App from './App';
import './index.css'; /*Anything written in the project will have more importance than imported From SB*/
import './i18n';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
