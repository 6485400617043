import React from 'react';

import {useTranslation} from 'react-i18next';

import {Text} from '@ifeelonline/storybook';

import {Prohibit} from 'phosphor-react';

export const ChatDisabled = () => {
  const {t} = useTranslation();
  return (
    <div className="flex h-14 gap-2 bg-white p-4 text-text-600 shadow-lg">
      <Prohibit />
      <Text textType="body-3">{t('chat.assign_agent.must_assign_agent')}</Text>
    </div>
  );
};
