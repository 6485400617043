import {useTranslation} from 'react-i18next';

import {Dropdown, InputErrorMessage} from '@ifeelonline/storybook';
import {DropDownOption} from '@ifeelonline/chat-core';

import {SelectChangeEvent} from '@mui/material';

import {
  getQuestionType,
  isAgentQuestion,
} from 'src/pages/chat/utils/ai_questionnaire/logic';
import {
  AIQuestionNew,
  AIQuestionTag,
} from 'src/pages/chat/types/AIQuestionnaire';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {createAnswer} from 'src/state/action_creators/conversationActionCreator';
import {SurveyStatus} from 'src/pages/chat/types/Surveys';
import {useAppDispatch} from 'src/hooks/hooks';

interface Props {
  options: DropDownOption[];
  selectedOption: string | number | null;
  handleChange: (event: SelectChangeEvent<unknown>) => void;
  error: string | null;
  question: AIQuestionNew;
  conversationId: number;
}

export const RenderDropdown = ({
  options,
  selectedOption,
  handleChange,
  error,
  question,
  conversationId,
}: Props) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const handleDropdownChange = (event: SelectChangeEvent<unknown>) => {
    const selectedValueAsNumber: number | null =
      (event.target.value as number) ?? null;

    handleChange(event);
    dispatch({
      type: ConversationActionType.UPDATE_AI_ANSWERS,
      payload: {
        questionId: question.id,
        newAnswer: {
          questionId: question.id,
          comment: '',
          type: getQuestionType(question.tag),
          ...(isAgentQuestion(question.tag)
            ? {agentId: selectedValueAsNumber ?? undefined}
            : {
                optionIds: selectedValueAsNumber
                  ? [selectedValueAsNumber]
                  : undefined,
              }),
        },
      },
    });

    dispatch(
      createAnswer(conversationId, {
        ...question,
        answer: {
          ...(question.answer || {questionId: question.id}),
          ...(isAgentQuestion(question.tag)
            ? {
                agentId: selectedValueAsNumber ?? undefined,
              }
            : {
                optionIds: selectedValueAsNumber ? [selectedValueAsNumber] : [],
              }),
        },
      }),
    ).then((res) => {});

    if (question.tag === AIQuestionTag.Agent) {
      const agent = options.find(
        (option) => option.value === selectedValueAsNumber,
      );
      const agentName = agent ? agent.text : '';

      dispatch({
        type: ConversationActionType.SET_STATUS_AI_QUESTIONNAIRE_FORM,
        payload: {status: SurveyStatus.EDITING, agent: agentName},
      });
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <Dropdown
        value={selectedOption ? selectedOption : undefined}
        options={options}
        hasBorder={true}
        disabled={false}
        errors={!selectedOption && error ? true : false}
        onChange={handleDropdownChange}
        placeholder={t('ai.form.select')}
        height={'16px'}
      />
      <InputErrorMessage text={!selectedOption && error ? error : ''} />
    </div>
  );
};
