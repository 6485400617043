import {Message, SharedActionType} from '@ifeelonline/chat-core';

import {FavoritesActionType} from 'src/state/action_types/FavoritesActionType';
import {FavoritesAction} from 'src/state/actions/favorites';

interface FavoritesState {
  conversationId: number | null;
  error: string | null;
  loadingMoreMessages: SharedActionType | null;
  messages: Message[];
  status: SharedActionType;
}

const initialState = {
  conversationId: null,
  messages: [],
  status: SharedActionType.IDLE,
  loadingMoreMessages: SharedActionType.IDLE,
  error: null,
};

export const favoritesReducer = (
  state: FavoritesState = initialState,
  action: FavoritesAction,
): FavoritesState => {
  switch (action.type) {
    case FavoritesActionType.FAVORITES_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case FavoritesActionType.SET_FAVORITES:
      return {
        ...state,
        conversationId: action.payload.conversationId,
        messages: action.payload.messages,
        status: SharedActionType.COMPLETED,
      };

    case FavoritesActionType.FAVORITES_LOADING_MORE_MESSAGES:
      return {
        ...state,
        loadingMoreMessages: SharedActionType.LOADING,
      };

    case FavoritesActionType.FAVORITES_LOAD_MORE_MESSAGES:
      return {
        ...state,
        messages: [...action.payload, ...state.messages],
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
