import {TFunction, useTranslation} from 'react-i18next';

import i18n from 'src/i18n';

import {Message, MessageData, isToday} from '@ifeelonline/chat-core';

import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/he';
import 'moment/locale/pt';
import 'moment/locale/es';

import dayjs from 'dayjs';

export function todayTranslation(date: string, t: TFunction) {
  return t('dates.todayAndHour', {hour: dayjs(date).format('HH:mm')});
}

export function formatDate(
  date: string,
  todayTranslation: string,
  formatting?: 'DayMonth',
) {
  const formattedDate = isToday(dayjs(date))
    ? todayTranslation
    : dayjs(date).format(
        formatting === 'DayMonth' ? 'DD/MM, HH:mm' : 'll, HH:mm',
      );

  return formattedDate;
}

export const GeneralFunctions = () => {
  const {t} = useTranslation();
  moment.locale(i18n.language);

  const automaticTitle = (message: Message) => {
    if (message && message.data) {
      const statusVideoCall = message.data.statusVideoCall;
      switch (statusVideoCall) {
        case 'finished':
          return t('automatic_message.end_vs');
        case 'new':
          return t('automatic_message.new');
        case 'updated':
          return t('automatic_message.updated');
        case 'cancelled':
          return t('automatic_message.cancelled');
        case 'lost':
          return t('automatic_message.lost_vs');
        default:
          return ``;
      }
    }
  };
  const automaticSubMessage = (message: Message) => {
    if (!message.data || !message.data.statusVideoCall) return null;

    const {originalStartTime, startTime, statusVideoCall}: MessageData =
      message.data;
    const originalStartTimeDate = originalStartTime
      ? formatDate(originalStartTime, todayTranslation(originalStartTime, t))
      : null;
    const startTimeDate = formatDate(startTime, todayTranslation(startTime, t));

    let duration;
    const receivedOn = formatDate(
      message.receivedOn,
      todayTranslation(message.receivedOn, t),
    );
    switch (statusVideoCall) {
      case 'finished':
        duration = t('automatic_message.duration', {
          vs_duration: message.videoCall?.duration,
        });

        return `${receivedOn}h. ${duration}`;
      case 'new':
        return t('automatic_message.new_vs', {
          date: startTimeDate,
        });
      case 'updated': {
        const startTimeFormat = startTimeDate;
        const originalStartTimeFormat = originalStartTimeDate;
        if (startTimeFormat === originalStartTimeFormat) {
          return t('automatic_message.updated_vs_hour', {
            date: startTimeFormat,
          });
        } else {
          return t('automatic_message.updated_vs', {
            date: startTimeFormat,
            date_original: originalStartTimeFormat,
          });
        }
      }
      case 'cancelled':
        return t('automatic_message.cancelled_vs', {
          date: startTimeDate,
        });
      default:
        return `${receivedOn}h.`;
    }
  };

  return {
    automaticTitle,
    automaticSubMessage,
    formatText,
  };
};

export const capitalizeFirstLetter = (labelName: string) => {
  return labelName.charAt(0).toUpperCase() + labelName.slice(1);
};

export const decodeHTMLEntities = (text: string) => {
  const div = document.createElement('div');
  div.innerHTML = text;
  return div.innerText;
};

export const formatText = (message_body: string) => {
  let message_format = '';
  if (
    message_body.indexOf(' ') === -1 &&
    message_body.length > 30 &&
    !isValidUrl(message_body)
  ) {
    let i = 0;
    while (message_body.length - i > 0) {
      message_format +=
        message_body.substring(i, i + 30) +
        (message_body.length - i > 30 ? '\n' : '');
      i += 30;
    }
  } else {
    message_body = message_body.replace(/^\s+|\s+$/g, '');
    message_format = sanitizedPhone(message_body);
    if (message_body.indexOf('<a href=') < 0) {
      message_format = sanitizedText(message_body);
    }
  }
  return message_format;
};

export const getInitials = (name: string, numChar = 0, length = 1) => {
  return name.substring(numChar, length);
};

export function renderEmptyParagraph() {
  return '\u00a0\u00a0';
}

/*export const shouldShowEditDeleteIcons = (message: MessageStorage, uid: number) =>
  (!('audio' in message) || message.audio.url === null) &&
  (!('video_object' in message) || message.video_object === null) &&
  (!('file' in message) || message.file.url === null) &&
  message.user_id === uid &&
  (!('deleted' in message) || !message.deleted);*/

function isValidUrl(text: string) {
  let url;

  try {
    url = new URL(text);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

const regExp =
  /((?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff]+-?)*[a-z0-9\u00a1-\uffff]+)(?:\.(?:[a-z0-9\u00a1-\uffff]+-?)*[a-z0-9\u00a1-\uffff]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?)/gi;
const regExpTel = /((?:^|[\s:;,])(\(\+[0-9]{1,}\) ?|\+?)[0-9]+([- ][0-9]+)*)/g;

function sanitizedPhone(text: string) {
  return text.replace(regExpTel, function (match) {
    return match.replace(/[^0-9]/g, '').length < 6 ||
      isValidUrl(match) ||
      isEmail(match)
      ? match
      : '<a href="tel:' + match + '">' + match + '</a>';
  });
}

function sanitizedText(text: string) {
  return text.replace(regExp, function (match, substr) {
    let sanitizedUrl = '';
    if (isEmail(substr)) {
      sanitizedUrl = 'mailto:' + substr;
    } else if (/^https?:/.test(substr)) {
      sanitizedUrl = substr;
    } else {
      sanitizedUrl = 'https://' + substr;
    }
    return '<a href="' + sanitizedUrl + '" target="_blank">' + substr + '</a>';
  });
}

function isEmail(url: string) {
  return /[\w+\-.]+@[a-z\d\-.]+\.[a-z]+/g.test(url);
}

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
