import React from 'react';

import {useTranslation} from 'react-i18next';

import Image404 from 'src/assets/images/404.png';

export const Custom404 = () => {
  const {t} = useTranslation();

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="max-w-107.5 text-center">
        <img src={Image404} alt="404" />
        <h1 className="mb-3 mt-10 font-recoleta text-9 font-semibold text-text-600">
          {t('404.title')}
        </h1>
        <p className="text-4 text-text-500">{t('404.text')}</p>
      </div>
    </div>
  );
};
