import {formatDateMessageForSupport} from '@ifeelonline/chat-core';

import {
  ConversationListItem,
  ScheduledConversationListItem,
} from 'src/types/ConversationList';

import {BadgeConversationList} from './BadgeConversationList';

export const InfoLastMessage = ({
  conversationListItem,
  userId,
  isScheduledView,
}: {
  conversationListItem: ConversationListItem | ScheduledConversationListItem;
  userId: number;
  isScheduledView?: boolean;
}) => {
  return (
    <div className="absolute right-1 top-1 flex items-center gap-2">
      <span className="text-3.5 text-blue21">
        {formatDateMessageForSupport(
          conversationListItem.lastMessageReceivedOn,
        )}
      </span>
      <BadgeConversationList
        bgClass={
          conversationListItem.unreadMessages > 0
            ? 'bg-blue-500'
            : 'bg-transparent'
        }
      />
      <BadgeConversationList
        bgClass={
          conversationListItem.lastMessageSentBy &&
          conversationListItem.lastMessageSentBy !== userId
            ? 'bg-brandSalmon-700'
            : 'bg-transparent'
        }
      />
    </div>
  );
};
