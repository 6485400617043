import {useTranslation} from 'react-i18next';

const ALLOWED_FILE_FORMATS = '.jpg, .jpeg, .png, .gif, .pdf';

export const useChatTranslations = () => {
  const {t} = useTranslation();

  const uploadFileTranslations = {
    sendFiles: t('modals.send_files'),
    errorToast: t('errors.generic.contact_admin'),
    dropFile: t('modals.drop_file'),
    browser: t('modals.browser'),
    supportedFormats: t('modals.supported_formats', {
      supported_formats: ALLOWED_FILE_FORMATS,
    }),
    fileSize: t('modals.file_size'),
    genericSend: t('generic.send'),
    errorFileFormat: t('modals.error_file_format'),
    errorFileSize: t('modals.error_file_size'),
  };

  const sendMessageBoxTranslations = {
    messageBox: {
      audioTranslation: t('chat.audio'),
      sendTranslation: t('generic.send'),
      cancelTranslation: t('generic.cancel'),
      stopTranslation: t('generic.stop'),
      typeMessageTranslation: t('chat.type_msg'),
      typeMessageTranslationMobile: t('chat.type_msg'),
      youTranslation: t('chat.you'),
      search: t('generic.search'),
    },
    menuTranslations: {
      toolsTranslation: t('generic.tools'),
      searchTranslation: t('generic.search'),
      attachTranslation: t('chat.attach'),
      videoTranslation: t('chat_options.video'),
    },
  };

  const messageTranslations = {
    messageEditedTranslation: t('chat.msg_edited'),
    messageDeletedTranslation: t('chat.msg_deleted'),
    messageErrorTranslation: t('chat.errors.file_error'),
  };

  return {
    uploadFileTranslations,
    sendMessageBoxTranslations,
    messageTranslations,
  };
};
