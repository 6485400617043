import React from 'react';

import {InputErrorMessage, Text} from '@ifeelonline/storybook';

import {
  AIQuestionNew,
  AIQuestionOption,
} from 'src/pages/chat/types/AIQuestionnaire';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {createAnswer} from 'src/state/action_creators/conversationActionCreator';
import {useAppDispatch} from 'src/hooks/hooks';

export const RenderRadioButton = ({
  questionOptions,
  selectedOption,
  handleRadioButtonChange,
  displayInColumn,
  error,
  question,
  conversationId,
}: {
  questionOptions: AIQuestionOption[];
  selectedOption: number | null;
  handleRadioButtonChange: (optionId: number) => void;
  displayInColumn: boolean;
  error: string | null;
  question: AIQuestionNew;
  conversationId: number;
}) => {
  const dispatch = useAppDispatch();
  const renderOptionText = (option: AIQuestionOption) => (
    <Text textType="body-3" fontColor="text-text-400">
      {option.body}
    </Text>
  );

  return (
    <div className="flex flex-col gap-3">
      <div
        className={`flex ${
          displayInColumn ? 'flex-col justify-between gap-3' : 'justify-between'
        }`}>
        {questionOptions &&
          questionOptions.map((option) => (
            <div
              key={option.id}
              className={`flex  ${
                displayInColumn
                  ? 'items-center gap-2'
                  : 'flex-col items-center justify-center'
              }`}>
              {!displayInColumn && renderOptionText(option)}
              <div className="!w-4">
                <input
                  type="radio"
                  value={option.id}
                  checked={selectedOption === option.id}
                  className="size-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500"
                  onChange={() => {
                    handleRadioButtonChange(option.id);
                    dispatch({
                      type: ConversationActionType.UPDATE_AI_ANSWERS,
                      payload: {
                        questionId: question.id,
                        newAnswer: {
                          questionId: question.id,
                          optionIds: [option.id],
                        },
                      },
                    });

                    dispatch(
                      createAnswer(conversationId, {
                        ...question,
                        answer: {
                          ...(question.answer || {questionId: question.id}),
                          optionIds: [option.id],
                        },
                      }),
                    ).then((res) => {});
                  }}
                />
              </div>

              {displayInColumn && renderOptionText(option)}
            </div>
          ))}
      </div>
      <InputErrorMessage
        text={
          (!question.answer?.optionIds ||
            question.answer?.optionIds.length === 0) &&
          error
            ? error
            : ''
        }
      />
    </div>
  );
};
