import {Text} from '@ifeelonline/storybook';

import {getColorInfoDescription} from '../../utils/ai_questionnaire/renderInformation';

type Description = {
  title?: string;
  description: string;
};

interface Props {
  title?: string;
  description: Description[];
  small?: boolean;
}

export const AIQuestionnaireInfoItem = ({title, description, small}: Props) => {
  return (
    <div className={`flex flex-col ${small ? 'gap-0' : 'gap-3'}`}>
      <Text textType={'body-2'} fontWeight="semibold" fontColor="text-text-500">
        {title}
      </Text>
      {description &&
        description.length > 0 &&
        description.map((item) => (
          <div className="flex items-center">
            {item.title && (
              <div
                className={`me-3 min-w-max rounded-1.5 p-1 ${getColorInfoDescription(
                  item.title,
                )}`}>
                <Text textType={'caption'} fontWeight="semibold">
                  {item.title}
                </Text>
              </div>
            )}
            <Text textType={'body-3'} fontColor="text-text-400">
              {item.description}
            </Text>
          </div>
        ))}
    </div>
  );
};
