import React from 'react';

import {TextInput as TextInputStorybook} from '@ifeelonline/storybook';

export const TextInputFormField = ({
  textAnswer,
  error,
  handleTextAreaChange,
  handleBlur,
  questionId,
}: {
  questionId: number;
  textAnswer: string;
  error?: string | null;
  handleTextAreaChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    validate?: boolean,
  ) => void;
  handleBlur: (id: number, value: string) => void;
}) => (
  <div
    onBlur={() => handleBlur(questionId, textAnswer)}
    className={`relative h-11 overflow-hidden overflow-x-visible rounded-1.5 border-e ${
      error ? 'border-e-semantic-danger-500' : 'border-e-text-300'
    }`}>
    <TextInputStorybook
      value={textAnswer}
      onChange={(e) => handleTextAreaChange(e, true)}
      placeholder=""
      errors={(typeof error === 'string' && error) || ''}
    />
  </div>
);
