import React, {useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Switcher, modalService} from '@ifeelonline/storybook';
import {InfoMedium} from '@ifeelonline/icons';

import {changeStatusChat} from 'src/state/action_creators/authActionCreator';
import {useUser} from 'src/providers/UserProvider';
import {useAppDispatch} from 'src/hooks/hooks';

import {ChatStatus} from './constants/chat';

export const ChatStatusSwitch = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {isChatOnline, chatStateChanged, setIsChatOnline, setChatStateChanged} =
    useUser();
  const [label, setLabel] = useState<string>(t('status_switch.offline'));
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();

  const handleChange = () => {
    setIsOpenModal(true);
  };

  const confirmSwitchAction = () => {
    setIsLoading(true);

    dispatch(
      changeStatusChat(
        !isChatOnline,
        !isChatOnline ? ChatStatus.ONLINE : ChatStatus.OFFLINE,
      ),
    ).then(() => {
      setIsLoading(false);
      setIsChatOnline(!isChatOnline);
      setChatStateChanged(true);
      setIsOpenModal(false);
    });
  };

  const closeSwitchAction = () => {
    setIsOpenModal(false);
    modalService.close();
  };

  useEffect(() => {
    if (isOpenModal) {
      modalService.fire({
        show: true,
        closeOutside: true,
        image: <InfoMedium size={80} />,
        title: !isChatOnline
          ? t('swal_questions.are_you_sure_online')
          : t('swal_questions.are_you_sure_offline'),
        description: t('swal_questions.are_you_sure_chat'),
        cancelButtonText: t('generic.no'),
        confirmButtonText: t('generic.yes'),
        confirmButtonAction: () => {
          confirmSwitchAction();
        },
        cancelButtonAction: () => {
          closeSwitchAction();
        },
        closeModal: () => {
          closeSwitchAction();
        },
        showCloseButtonX: false,
        reverseButtons: true,
        hasBackdropBlur: false,
      });
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (!isLoading) {
      setLabel(
        isChatOnline ? t('status_switch.online') : t('status_switch.offline'),
      );
      const amazonUrl = `https://s3.eu-west-2.amazonaws.com/ifeel-media/images/${
        isChatOnline ? 'chat_therapy_purchase-min.png' : 'error_payment-min.png'
      }`;
      if (chatStateChanged) {
        modalService.fire({
          show: true,
          title: isChatOnline
            ? t('chat.status_changed_online')
            : t('chat.status_changed_offline'),
          description: t('generic.reload_page'),
          image: <img className="object-fill" src={amazonUrl} />,
          confirmButtonText: t('generic.ok'),
          confirmButtonAction: () => navigate(0),
          hasBackdropBlur: false,
        });
      }
    }
  }, [chatStateChanged, isLoading]);

  return (
    <div className="me-6 flex items-center">
      <p className="me-2 text-3 leading-normal">{label}</p>
      <Switcher
        isChecked={isChatOnline}
        onSwitchChange={() => handleChange()}
        loading={isLoading}
      />
    </div>
  );
};
