export const translationAvailableLanguages = [
  {
    name: 'Español',
    code: 'es',
  },
  {
    name: 'English',
    code: 'en',
  },
  {
    name: 'Français',
    code: 'fr',
  },
  {
    name: 'Português',
    code: 'pt',
  },
  {
    name: 'עִברִית',
    code: 'he',
  },
  {
    name: 'Deutsch',
    code: 'de',
  },
];
