import {ConversationListItem} from 'src/types/ConversationList';

export const AvatarConversation = ({
  conversationListItem,
}: {
  conversationListItem: ConversationListItem;
}) => {
  const getConversationStyle = () => {
    if (conversationListItem.patientInactive) return 'bg-backgrounds-9';
    if (conversationListItem.patientPartner)
      return 'bg-backgrounds-badgeSalmon';
    return 'bg-backgrounds-badgeBlue';
  };

  return (
    <div
      className={`relative size-10 rounded-full text-center font-recoleta text-4.5 font-normal uppercase leading-10 ${getConversationStyle()}`}>
      {conversationListItem.patientNickname.charAt(0)}
      <span
        className={`absolute -bottom-0.5 me-2 inline-block size-3 rounded-full bg-offline ${
          conversationListItem.patientState ? 'bg-online' : ''
        }`}></span>
    </div>
  );
};
