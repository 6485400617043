import React, {useRef, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {
  OnEmojiSelect,
  SendMessageBox,
  Themes,
  VideoRecorderModal,
  menuActions,
} from '@ifeelonline/storybook';
import {ChatStatusType, Conversation, Message} from '@ifeelonline/chat-core';

import {
  editMessage,
  sendMessage,
  sendMessageFormData,
  sendSplitMessage,
} from 'src/state/action_creators/conversationActionCreator';
import {AutomaticMessagesModal} from 'src/pages/chat/components/modals/AutomaticMessagesModal';
import {TranslateStringModal} from 'src/pages/chat/components/modals/TranslateStringModal';
import automatic_msg_icon from 'src/assets/images/chat/automatic_msg_icon.png';
import {uiEditMsg, uiReplyTo} from 'src/state/action_creators/uiActionCreator';
import {useChatTranslations} from 'src/pages/chat/hooks/useChatTranslations';
import translate_icon from 'src/assets/images/chat/translate_icon.png';
import {useChat} from 'src/providers/ChatProvider';
import {useUser} from 'src/providers/UserProvider';
import {useAppDispatch} from 'src/hooks/hooks';
import {IS_DEVELOPMENT} from 'src/constants';
import {RootState} from 'src/state';

import {RenderUploadFileModal} from './RenderUploadFileModal';

interface Props {
  conversation: Conversation;
  isSingleConversation: boolean;
  setShowSearcher: React.Dispatch<React.SetStateAction<boolean>> | null;
}

export const RenderSendMessageBox = ({
  conversation,
  isSingleConversation,
  setShowSearcher,
}: Props) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const {
    formValues,
    handleInputChange,
    resetFormValues,
    setChatStatus,
    chatStatus,
  } = useChat();
  const {user} = useUser();
  const {sendMessageBoxTranslations} = useChatTranslations();

  const [classSlide, setClassSlide] = useState<string>('');
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isTranslateModalOpen, setIsTranslateModalOpen] = useState(false);
  const [isAutomaticMessagesModalOpen, setIsAutomaticMessagesModalOpen] =
    useState(false);

  const {replyTo, editMsg}: {replyTo: Message; editMsg: Message} = useSelector(
    (state: RootState) => state.ui,
  );
  const {status} = useSelector((state: RootState) => state.conversation);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const dispatchSendMessage = (message: FormData) => {
    const audio =
      (message.get('audio') as Blob) || (message.get('file') as Blob);
    if (message.get('file')) message.delete('file');
    if (audio) message.set('audio', audio, 'audio.mp4');
    dispatch(sendMessageFormData(message));
  };

  const handleRecordAudio = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setChatStatus(ChatStatusType.RECORDING);
  };

  const handleCloseQuotedMessageBox = () => {
    setClassSlide('slide-down');
    setTimeout(() => {
      if (replyTo) dispatch(uiReplyTo(null));
      setClassSlide('');
    }, 100);
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChatStatus(
      e.target.value === '' || !e.target.value
        ? ChatStatusType.DEFAULT
        : ChatStatusType.WRITING,
    );
    handleInputChange(e);
  };

  const customMenuActions = [
    {
      text: t('chat_options.translator'),
      icon: <img src={translate_icon} alt={t('chat_options.translator')} />,
      onClick: () => setIsTranslateModalOpen(true),
      backgroundColor:
        Themes['DefaultTheme'].theme.extend.colors.brandPrimary[50],
    },
    {
      text: t('chat_options.automatic_msg'),
      icon: (
        <img src={automatic_msg_icon} alt={t('chat_options.automatic_msg')} />
      ),
      onClick: () => setIsAutomaticMessagesModalOpen(true),
      backgroundColor:
        Themes['DefaultTheme'].theme.extend.colors.brandPrimary[50],
    },
  ];

  const menuActions: menuActions = {
    ...(isSingleConversation && {
      setShowSearcher: setShowSearcher ?? undefined,
      setShowVideoModal: setIsVideoModalOpen,
      setFileModal: setIsUploadModalOpen,
    }),
    custom: customMenuActions,
  };

  const handleEmojiClick = (emoji: OnEmojiSelect) => {
    handleInputChange({
      target: {
        name: 'messageBody',
        value: `${formValues.messageBody} ${emoji.native}`,
      },
    } as React.ChangeEvent<HTMLTextAreaElement>);
  };

  const closeEditMsg = () => {
    setClassSlide('slide-down');
    setTimeout(() => {
      dispatch(uiEditMsg(null));
      setClassSlide('');
    }, 600);
  };

  const closeReplyTo = () => {
    setClassSlide('slide-down');
    setTimeout(() => {
      dispatch(uiReplyTo(null));
      setClassSlide('');
    }, 600);
  };

  const handleSendMessage = () => {
    if (user) {
      if (formValues.messageBody !== '') {
        if (replyTo !== null) {
          dispatch(
            sendMessage({
              body: formValues.messageBody,
              conversation_id: conversation.id,
              patient_id: user.uid,
              user_id: conversation.patientId,
              reply_to: replyTo.id.toString(),
            }),
          ).then(() => closeReplyTo());
        } else {
          if (editMsg !== null) {
            dispatch(
              editMessage({
                id: editMsg.id.toString(),
                body: formValues.messageBody,
                conversation_id: conversation.id,
                error_message: t('errors.messages.message_too_long'),
              }),
            ).then((res) => {
              closeEditMsg();
              if (res) {
                if (res.error) {
                  toast.error(res.error);
                } else {
                  toast.success(t('chat.msg_edited_successfully'));
                }
              }
            });
          } else {
            dispatch(
              sendSplitMessage({
                messageObj: {
                  body: formValues.messageBody,
                  conversation_id: conversation.id,
                  patient_id: user.uid,
                  user_id: conversation.patientId,
                  reply_to: null,
                },
              }),
            );
          }
        }
        resetFormValues();
        setChatStatus(ChatStatusType.DEFAULT);
      }
    }
  };

  return (
    <>
      <SendMessageBox
        chatStatus={chatStatus}
        classSlide={classSlide}
        closeOnPickerClick={true}
        conversationId={Number(conversation.id)}
        dispatchSendMessage={dispatchSendMessage}
        handleCloseQuotedMessageBox={handleCloseQuotedMessageBox}
        handleRecordAudio={handleRecordAudio}
        handleSendMessage={handleSendMessage}
        handleShowEmoji={() => {}}
        isDevelopmentEnv={IS_DEVELOPMENT}
        isPatientRole={false}
        menuActions={menuActions}
        onChange={(e) => handleTextAreaChange(e)}
        onEmojiSelect={handleEmojiClick}
        patientNickname={conversation.patientPsyName || ''}
        quotedMessage={replyTo}
        setCurrentChatStatus={setChatStatus}
        status={status}
        translations={sendMessageBoxTranslations}
        userUid={Number(user?.uid)}
        value={formValues.messageBody}
        textareaRef={textareaRef}
      />
      <TranslateStringModal
        close={() => setIsTranslateModalOpen(false)}
        initialMessageToTranslate={formValues.messageBody}
        isOpen={isTranslateModalOpen}
      />
      <AutomaticMessagesModal
        close={() => setIsAutomaticMessagesModalOpen(false)}
        isOpen={isAutomaticMessagesModalOpen}
      />
      <RenderUploadFileModal
        close={() => setIsUploadModalOpen(false)}
        conversation={conversation}
        isOpen={isUploadModalOpen}
      />
      <VideoRecorderModal
        clickToStartText={t('modals.record_video.click_to_start')}
        close={() => setIsVideoModalOpen(false)}
        conversationId={Number(conversation.id)}
        isOpen={isVideoModalOpen}
        sendVideo={(formData: FormData) => {
          const video =
            (formData.get('video') as Blob) || (formData.get('file') as Blob);
          if (formData.get('file')) formData.delete('file');

          if (video) formData.set('video', video, 'video.mp4');
          dispatch(sendMessageFormData(formData));
          setChatStatus(ChatStatusType.DEFAULT);
        }}
      />
    </>
  );
};
