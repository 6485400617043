import React from 'react';

import {useSelector} from 'react-redux';

import {ModalLayout} from '@ifeelonline/storybook';

import {useAIQuestionnaireFromProvider} from 'src/providers/AIQuestionnaireProvider';
import {RootState} from 'src/state';

import {
  AIAnswer,
  AIFormSteps,
  AIQuestionNew,
} from '../../types/AIQuestionnaire';
import {extractSOFASQuestion} from '../../utils/ai_questionnaire/logic';

import {AIQuestionnaireFormContainer} from './AIQuestionnaireFormContainer';
import {AIQuestionnaireFormQuestions} from './AIQuestionnaireFormQuestions';
import {AIQuestionnaireFormSofas} from './AIQuestionnaireFormSofas';
import {SofaErrorModal} from './SofaErrorModal';

export interface AIQuestionnaireFormProps {
  SOFASAnswer: AIAnswer[];
  setSOFASAnswer: React.Dispatch<React.SetStateAction<AIAnswer[]>>;
  step: AIFormSteps;
  setStep: React.Dispatch<React.SetStateAction<AIFormSteps>>;
  isOpenSofaErrorModal: boolean;
  setIsOpenSofaErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AIQuestionnaireForm = () => {
  const {step, setStep, isOpenSofaErrorModal, setIsOpenSofaErrorModal} =
    useAIQuestionnaireFromProvider();

  const {aiQuestions} = useSelector((state: RootState) => state.conversation);

  const renderQuestionsForm = () => <AIQuestionnaireFormQuestions />;

  const renderSOFASForm = (aiQuestions: AIQuestionNew[]) => (
    <AIQuestionnaireFormSofas
      SOFASQuestion={extractSOFASQuestion(aiQuestions)}
    />
  );

  return (
    <AIQuestionnaireFormContainer>
      {step === AIFormSteps.SOFAS && aiQuestions
        ? renderSOFASForm(aiQuestions)
        : renderQuestionsForm()}
      <ModalLayout
        show={isOpenSofaErrorModal}
        closeOutside={false}
        showCloseButtonX={false}>
        <SofaErrorModal
          setIsOpenModal={setIsOpenSofaErrorModal}
          setStep={setStep}
        />
      </ModalLayout>
    </AIQuestionnaireFormContainer>
  );
};
