import {fetchResolveCredentials} from '@ifeelonline/chat-core';
import {modalService} from '@ifeelonline/storybook';

import {genericError} from './errors';

export const baseUrl = import.meta.env.VITE_API_URL_SUPPORT;

interface FetchParams {
  credentials?: boolean;
  data?: any;
  endPoint: string;
  method?: string;
  validateResponse?: boolean;
  url?: string;
}

export const fetchWrapper = async ({
  data,
  endPoint,
  method,
  credentials,
  validateResponse,
  url,
}: FetchParams) => {
  const resp = await fetchResolveCredentials({
    endPoint,
    data,
    method,
    credentials,
    validateResponse,
    baseUrl: url ? url : baseUrl,
    fireFetchErrorModal,
  });

  return resp;
};

export const fireFetchErrorModal = () => {
  modalService.fire({
    ...genericError,
    show: true,
    confirmButtonAction: () => {
      modalService.close();
    },
    closeModal: () => {
      modalService.close();
    },
  });
};
