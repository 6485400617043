import {useTranslation} from 'react-i18next';

import {Patient} from '@ifeelonline/chat-core';

import moment from 'moment';

import {getInitials, renderEmptyParagraph} from 'src/helpers/generalFunctions';
import AvatarUrl from 'src/assets/images/user/avatar-bg.png';
import {BadgeComp} from 'src/components/BadgeComp';
import {CardWhite} from 'src/components/CardWhite';

import {PatientProfileColumn} from '../patient_profile/PatientProfileColumn';
import {PatientInfoInput} from '../patient_profile/PatientInfoInput';
import {PatientInfoLabel} from '../patient_profile/PatientInfoLabel';

export const PatientInfoCard = ({patient}: {patient: Patient}) => {
  const {t} = useTranslation();

  return (
    <CardWhite>
      <div className="flex flex-col !justify-between sm:flex-row ">
        <div
          className="h-28.5 w-17.5 min-w-17.5 self-center text-center font-recoleta text-11 font-semibold leading-28.5 text-brandPrimary-700 sm:w-28.5"
          style={{
            background: `url(${AvatarUrl}) no-repeat center`,
            backgroundSize: 'contain',
          }}>
          {getInitials(patient.nickname, 0, 2)}
        </div>
        <div className="flex flex-1 flex-col !items-start !justify-between sm:flex-row lg:me-20">
          <PatientProfileColumn>
            <p className="my-3 font-recoleta text-6 font-semibold text-text-600 sm:mb-5 sm:mt-0">
              {patient.nickname}
            </p>
            <div className="mb-3">
              <PatientInfoLabel>{t('patient.active_since')}</PatientInfoLabel>
              <PatientInfoInput>
                {patient.therapyStartDate
                  ? moment(patient.therapyStartDate).format('LL')
                  : renderEmptyParagraph()}
              </PatientInfoInput>
            </div>
          </PatientProfileColumn>
          <PatientProfileColumn>
            <div className="mb-3">
              <PatientInfoLabel>{t('user.birthday')}</PatientInfoLabel>
              <PatientInfoInput>
                {patient.birthday || renderEmptyParagraph()}
              </PatientInfoInput>
            </div>
            <div className="mb-3">
              <PatientInfoLabel>{t('user.nationality')}</PatientInfoLabel>
              <PatientInfoInput>
                {patient.nationality || renderEmptyParagraph()}
              </PatientInfoInput>
            </div>
          </PatientProfileColumn>
          <PatientProfileColumn>
            <div className="mb-3">
              <PatientInfoLabel>{t('generic.plan')}</PatientInfoLabel>
              <PatientInfoInput>
                {patient.plan || renderEmptyParagraph()}
              </PatientInfoInput>
            </div>
            <div>
              <PatientInfoLabel>
                {t('user.date_of_renovation')}
              </PatientInfoLabel>
              <PatientInfoInput>
                {patient.activeUntil ? (
                  <BadgeComp>
                    {moment(patient.activeUntil).format('DD/MM/YY')}
                  </BadgeComp>
                ) : (
                  renderEmptyParagraph()
                )}
              </PatientInfoInput>
            </div>
          </PatientProfileColumn>
        </div>
      </div>
    </CardWhite>
  );
};
