import {Navigate} from 'react-router-dom';

import {SelectCurrentAgent} from 'src/pages/chat/components/SelectCurrentAgent';
import {ROUTES} from 'src/constants/Routes';

interface Props {
  isAuthenticated: boolean;
  children: JSX.Element;
}

export const PrivateRoute = ({isAuthenticated, children}: Props) => {
  return isAuthenticated ? (
    <>
      {children}
      <SelectCurrentAgent />
    </>
  ) : (
    <Navigate to={ROUTES.LOGIN} />
  );
};
