import {useState} from 'react';

import {useTranslation} from 'react-i18next';

import {InputErrorMessage, Text} from '@ifeelonline/storybook';

import {
  AIQuestionNew,
  AIQuestionOption,
} from 'src/pages/chat/types/AIQuestionnaire';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {createAnswer} from 'src/state/action_creators/conversationActionCreator';
import {useAppDispatch} from 'src/hooks/hooks';

export const RenderCheckbox = ({
  questionOptions,
  selectedOptions,
  handleCheckboxChange,
  question,
  conversationId,
  isError,
}: {
  questionOptions: AIQuestionOption[];
  selectedOptions: number[];
  handleCheckboxChange: (options: number[]) => void;
  question: AIQuestionNew;
  conversationId: number;
  isError: boolean;
}) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const handleChange = async (optionId: number) => {
    const updatedOptions = selectedOptions.includes(optionId)
      ? selectedOptions.filter((id) => id !== optionId)
      : [...selectedOptions, optionId];

    handleCheckboxChange(updatedOptions);
    dispatch({
      type: ConversationActionType.UPDATE_AI_ANSWERS,
      payload: {
        questionId: question.id,
        newAnswer: {
          questionId: question.id,
          optionIds: updatedOptions,
        },
      },
    });

    if (updatedOptions.length === 0 || !updatedOptions) {
      setError(t('ai.form.error_required'));
    } else {
      setError(null);
      dispatch(
        createAnswer(conversationId, {
          ...question,
          answer: {
            ...(question.answer || {questionId: question.id}),
            optionIds: updatedOptions,
          },
        }),
      ).then((res) => {});
    }
  };

  return (
    <div className="flex flex-col gap-3">
      {questionOptions.map((option) => (
        <label key={option.id} className="flex items-center gap-2">
          <div className="!w-4">
            <input
              type="checkbox"
              name={`checkbox-${option.id}`}
              value={option.id}
              checked={selectedOptions.includes(option.id)}
              className="size-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500"
              onChange={() => {
                handleChange(option.id);
              }}
            />
          </div>
          <Text textType="body-3" fontColor="text-text-400">
            {option.body}
          </Text>
        </label>
      ))}
      <InputErrorMessage
        text={
          error ||
          (isError &&
            (!question.answer?.optionIds ||
              question.answer?.optionIds.length === 0))
            ? t('ai.form.error_required')
            : ''
        }
      />
    </div>
  );
};
