import React, {useEffect, useState} from 'react';

import {Trans, useTranslation} from 'react-i18next';
import toast from 'react-hot-toast';

import {Button, ModalLayout, Text} from '@ifeelonline/storybook';

import {translationAvailableLanguages as availableLanguages} from 'src/constants/translationAvailableLanguages';
import {useChat} from 'src/providers/ChatProvider';
import {useUser} from 'src/providers/UserProvider';
import {ModalBaseProps} from 'src/types/Modals';
import {fetchWrapper} from 'src/helpers/fetch';

interface Props extends ModalBaseProps {
  initialMessageToTranslate: string;
}

export const TranslateStringModal = ({
  isOpen,
  close,
  initialMessageToTranslate,
}: Props) => {
  const {t} = useTranslation();
  const {user} = useUser();
  const {setChatStatus, handleInputChange} = useChat();

  const userAvailableLanguages = availableLanguages.filter(
    (l) => l.code !== user!.locale,
  );

  const [selectedLanguage, setSelectedLanguage] = useState(
    userAvailableLanguages[0].code,
  );
  const [messageToTranslate, setMessageToTranslate] = useState('');
  const [translatedMessage, setTranslatedMessage] = useState('');

  const closeModal = () => {
    setTranslatedMessage('');
    setMessageToTranslate('');
    close();
  };

  const handleSelectLang = (languageCode: string) => {
    setSelectedLanguage(languageCode);
    setMessageToTranslate('');
    handleTranslateString(languageCode);
  };

  const handleTranslateString = async (toLang: string) => {
    const fromLang = user!.locale;

    let endPoint = '/messages/translate?';
    endPoint += 'q=' + encodeURI(translatedMessage);
    endPoint += `&source=${fromLang}`;
    endPoint += `&target=${toLang}`;

    const resp = await fetchWrapper({endPoint, data: null});
    if (!resp) return null;
    if (resp.body.success) {
      setMessageToTranslate(resp.body.text);
      return true;
    } else {
      toast.error('There was an error with the translation request');
      return false;
    }
  };

  const handleSubmit = () => {
    handleInputChange({
      target: {
        name: 'messageBody',
        value: messageToTranslate ?? '',
      },
    } as React.ChangeEvent<HTMLTextAreaElement>);
    close();
  };

  useEffect(() => {
    if (isOpen) {
      setTranslatedMessage(initialMessageToTranslate);
      if (selectedLanguage && initialMessageToTranslate)
        handleTranslateString(selectedLanguage);
    }
  }, [isOpen]);

  const handleEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    handleTranslateString(selectedLanguage);
  };

  const getCurrentLanguage = () => {
    const filterLanguage =
      availableLanguages.find((l) => l.code === user?.locale) ||
      availableLanguages.find((l) => l.code === 'en');

    return filterLanguage ? filterLanguage.name : '';
  };

  if (!user) return <></>;

  return (
    <ModalLayout
      closeModal={closeModal}
      closeOutside
      confirmButtonAction={handleSubmit}
      confirmButtonText={t('generic.continue')}
      isConfirmButtonDisabled={translatedMessage === ''}
      layout="basic"
      show={isOpen}
      title={t('modals.translate_modal.title')}>
      <div className="mb-4 flex flex-col justify-start gap-2 text-start align-baseline">
        <Trans>
          <Text textType="body-1">
            {t('modals.translate_modal.translate_to', {
              from: getCurrentLanguage(),
            })}
          </Text>
        </Trans>

        <div className="mb-2 flex gap-2">
          {availableLanguages.map(({code, name}) => (
            <span
              className={`flex-1 cursor-pointer rounded-1.5 border p-2 text-center ${
                selectedLanguage === code ? 'bg-backgrounds-1' : ''
              } ${user.locale === code ? 'hidden' : ''}`}
              onClick={() => handleSelectLang(code)}
              key={code}>
              {name}
            </span>
          ))}
        </div>

        <Text textType="body-1">{t('modals.translate_modal.your_msg')}</Text>

        <div className="mb-2 flex h-10 justify-between rounded-1 border border-translation-border p-1">
          <input
            className="size-full !border-none font-sofia text-4 font-medium text-text-600 !shadow-none"
            value={translatedMessage}
            onChange={(e) => setTranslatedMessage(e.target.value)}
            placeholder={t('modals.translate_modal.write_msg_here')}
          />
          <Button
            buttonType="button-outline"
            size="small"
            text={t('modals.translate_modal.translate')}
            onClick={(e) => handleEdit(e)}
            disabled={translatedMessage === ''}
          />
        </div>

        <Text textType="body-1">
          {t('modals.translate_modal.message_translate')}
        </Text>

        <div className="flex h-10 rounded-1 border border-translation-border px-2">
          <input
            readOnly
            className="size-full !border-none p-0 font-sofia text-4 font-normal text-text-400"
            value={messageToTranslate ?? ''}
          />
        </div>

        <Text textType="caption" className="mt-4 bg-backgrounds-4 p-2">
          {t('modals.translate_modal.warning')}
        </Text>
      </div>
    </ModalLayout>
  );
};
