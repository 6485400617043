import {useEffect, useState} from 'react';

import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {MeetingStorage, useLink, useScript} from '@ifeelonline/chat-core';
import {Loading} from '@ifeelonline/storybook';

import {
  getSignature,
  getVideoCallInfo,
  startVideoCall,
} from 'src/state/action_creators/videoCallActionCreator';
import {ROUTES, RouteEndVideoCall} from 'src/constants/Routes';
import {getLocaleZoom} from 'src/helpers/localesZoom';
import {useUser} from 'src/providers/UserProvider';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {
  ZoomBootStrap,
  ZoomLib,
  ZoomLodash,
  ZoomMeeting,
  ZoomMeetingEmbedded,
  ZoomReactSelect,
  ZoomRedux,
  ZoomReduxThunk,
  zoomReact,
  zoomReactDom,
} from './constants/ZoomLinks';

export const VideoCallPage = () => {
  //This is a workaround to remove all the styles from the page before loading the Zoom Meeting since it breaks the layout
  const styleElements = document.getElementsByTagName('style');
  Array.from(styleElements).map(function (styleElement) {
    styleElement.remove();
  });

  useLink(ZoomBootStrap);
  useLink(ZoomReactSelect);
  //Maybe some of this scripts are not needed but I dont know which ones are
  //I will leave them here for now and we can remove them later if we want
  //But I think it is better to have them all here for now
  useScript(zoomReact, 'zoom-scripts');
  useScript(zoomReactDom, 'zoom-scripts');
  useScript(ZoomRedux, 'zoom-scripts');
  useScript(ZoomReduxThunk, 'zoom-scripts');
  useScript(ZoomLodash, 'zoom-scripts');
  useScript(ZoomMeetingEmbedded, 'zoom-scripts');
  useScript(ZoomMeeting, 'zoom-scripts');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ROLE = 1;
  const {user} = useUser();
  const {conversation} = useSelector((state: RootState) => state.conversation);
  const videoCall = conversation?.videoCallInfo;
  const {conversationId} = useParams<string>();
  const {agentId} = useParams<string>();
  const locale = getLocaleZoom(user?.locale);
  const [loading, setLoading] = useState(true);
  const [zoomMtg, setZoomMtg] = useState(undefined);
  const [zoomLoaded, setZoomLoaded] = useState(false);

  useEffect(() => {
    if (zoomMtg) {
      setZoomLoaded(true);
    }
  }, [zoomMtg]);

  useEffect(() => {
    let isMounted = true;
    const loadZoom = () => {
      if (!isMounted) return;
      if (loading) {
        // @ts-ignore next line is not an error but it is not found since it is loaded from CDN
        setTimeout(() => setZoomMtg(window['ZoomMtg']), 6000);
      }
    };
    let intervalId: NodeJS.Timeout | undefined;

    if (loading) intervalId = setInterval(loadZoom, 5000);

    return () => {
      if (intervalId) clearInterval(intervalId);
      isMounted = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!conversationId) navigate(ROUTES.HOME);
  }, [conversationId]);

  const startMeeting = (
    signature: string,
    meeting: MeetingStorage,
    ZoomMtg: any,
  ) => {
    if (user) {
      document.getElementById('zmmtg-root')!.style.display = 'block';

      ZoomMtg.init({
        leaveUrl: RouteEndVideoCall({
          meetingId: Number(meeting.meeting_id),
          conversationId: Number(conversationId),
        }),
        success: () => {
          ZoomMtg.join({
            sdkKey: import.meta.env.VITE_ZOOM_SDK_KEY,
            signature: signature,
            meetingNumber: meeting.meeting_id,
            passWord: meeting.password,
            userName: user.name,
            zak: meeting.zak,
            success: () => {
              dispatch(
                startVideoCall({
                  videoCall,
                  meeting,
                  conversationId: Number(conversationId),
                  agentId: Number(agentId),
                }),
              );
            },
            error: () => {
              ZoomMtg.endMeeting({});
            },
          });
        },
        error: () => {
          ZoomMtg.endMeeting({});
        },
      });
    }
  };

  const startZoomMeeting = async () => {
    if (zoomMtg) {
      const ZoomMtg: {
        i18n: any;
        preLoadWasm: any;
        prepareWebSDK: any;
        setZoomJSLib: any;
        conversationId: number;
      } = zoomMtg;
      await ZoomMtg.setZoomJSLib(ZoomLib, '/av');
      await ZoomMtg.preLoadWasm();
      await ZoomMtg.prepareWebSDK();
      // loads language files, also passes any error messages to the ui
      await ZoomMtg.i18n.load(locale);
      await ZoomMtg.i18n.reload(locale);
      if (!conversationId) return;
      dispatch(getVideoCallInfo(Number(conversationId))).then(
        (meeting: MeetingStorage) => {
          setLoading(false);
          dispatch(getSignature(meeting.meeting_id, ROLE)).then(
            (sig: string) => {
              setLoading(false);
              startMeeting(sig, meeting, ZoomMtg);
            },
          );
        },
      );
    }
  };

  const handleLeave = async () => {
    try {
      // @ts-ignore It cant find ZoomMtg in window but it is there since is loaded from CDN
      const {ZoomMtg} = window['ZoomMtg'];
      ZoomMtg.leaveMeeting({});
      window.location.reload();
    } catch (error) {
      //Possible Fix n1 -> Cleaner since it does not access to the DOM but its not seamless to the user
      location.reload(); //Dont really like this but it works
      //Possible Fix n2 -> Seamless since it does no trigger a Reload, but it access to the DOM
      //document.querySelector('#zmmtg-root')?.remove();

      // @ts-ignore I want to destroy so there is no need to check if exist
      // window['ZoomMtg'] = undefined;
      // eslint-disable-next-line no-console
      console.error('Error en handleLeave:', error);
    }
  };

  useEffect(() => {
    if (zoomLoaded) {
      startZoomMeeting();
      return () => {
        handleLeave();
      };
    } else {
      // @ts-ignore I want to destroy so there is no need to check if exist
      window['ZoomMtg'] = undefined;
    }
  }, [zoomLoaded]);

  return (
    <>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export {VideoCallPage as default};
