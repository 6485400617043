import toast from 'react-hot-toast';

import {Check1, CloseMedium, Info} from '@ifeelonline/icons';
import {Text} from '@ifeelonline/storybook';

interface Props {
  text: string;
  duration?: number;
  type: 'error' | 'success' | 'information';
  size?: 'big' | 'small';
}

export const notify = ({text, duration, type}: Props) => {
  let bg = 'bg-brandPrimary-200';
  let color = 'text-brandPrimary-600';
  let icon = <Info fullHeight />;
  if (type === 'error') {
    bg = 'bg-brandSecondary-200';
    color = 'text-brandSalmon-700';
    icon = <CloseMedium fullHeight />;
  } else if (type === 'success') {
    bg = 'bg-semantic-success-200';
    color = 'text-semantic-success-700';
    icon = <Check1 fullHeight />;
  }

  toast.custom(
    (t) => (
      <div
        className={`rounded-1.5 p-2 transition-opacity duration-100 ease-in-out ${
          t.visible ? 'opacity-100' : 'opacity-0'
        } ${bg} `}>
        <button onClick={() => toast.dismiss(t.id)}>
          <div className="flex items-center justify-center gap-3">
            <div className={`size-6 rounded-full bg-white ${color} p-1`}>
              <div className="h-4">{icon}</div>
            </div>
            <Text textType={'body-2'} fontColor="text-text-600">
              {text}
            </Text>
          </div>
        </button>
      </div>
    ),
    {
      duration,
    },
  );
};
