import React from 'react';

import {useTranslation} from 'react-i18next';

export const InputComment = ({
  comment,
  setComment,
}: {
  comment: string;
  setComment: (value: React.SetStateAction<string>) => void;
}) => {
  const {t} = useTranslation();
  return (
    <input
      className="h-10 w-full rounded-1.5 border border-gray-300 placeholder:text-neutral-400"
      type="text"
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      placeholder={t('surveys.form.questions.comment_placeholder')}
    />
  );
};
