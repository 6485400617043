import {useEffect, useRef, useState} from 'react';

import {useParams} from 'react-router-dom';

import {MessageRefs} from '@ifeelonline/chat-core';

import {getFavoritesConversation} from 'src/state/action_creators/favoritesActionCreator';
import {getConversation} from 'src/state/action_creators/conversationActionCreator';
import {useAppDispatch} from 'src/hooks/hooks';
import {Navbar} from 'src/components/Navbar';

import {FavoritesContainer} from './components/FavoritesContainer';

export const FavoritesPage = () => {
  const {conversationId} = useParams<string>();
  const dispatch = useAppDispatch();
  const msgRefs = useRef<MessageRefs>({});

  const [pagesLoaded, setPagesLoaded] = useState<number[]>([1]);

  useEffect(() => {
    if (conversationId) {
      dispatch(getFavoritesConversation(parseInt(conversationId), 1));
      dispatch(getConversation(parseInt(conversationId), 1));
    }
  }, [conversationId]);

  return (
    <div className="home__screen">
      <Navbar isSingleConversation={true} chatView={false} />
      <div className="home__conversation_individual bg-f8 relative top-15 flex h-vh-minus-15 flex-row">
        <FavoritesContainer
          msgRefs={msgRefs}
          pagesLoaded={pagesLoaded}
          setPagesLoaded={setPagesLoaded}
        />
      </div>
    </div>
  );
};
