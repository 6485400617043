/* Texts */
export const COLOR_TEXT_700 = '#021B4B';
export const COLOR_TEXT_600 = '#273C66';
export const COLOR_TEXT_500 = '#515F78';
export const COLOR_TEXT_400 = '#747F94';
export const COLOR_TEXT_300 = '#A2AAB8';
export const COLOR_TEXT_100 = '#E1E5EB';
export const COLOR_TEXT_50 = '#F4F6F8';
export const COLOR_TEXT_10 = '#FAFAFA';
export const COLOR_TEXT_MEDIUM = '#687398';
export const COLOR_TEXT_BLUE_9B = '#9ba3bc';
export const COLOR_TEXT_BLUE_STRONG = '#153877';
export const COLOR_TEXT_BLUE_21 = '#213a6c';
export const COLOR_TEXT_BLUE_E3 = '#e3e8ee';
export const COLOR_TEXT_BLUE_3B = '#3b5486';
export const COLOR_TEXT_BLUE_LIGHT = '#91a5ca';
export const COLOR_TEXT_BLUE_94 = '#94a5cd';
export const COLOR_TEXT_BLUE_PRIMARY = '#3c4257';
export const COLOR_TEXT_BLUE_POOL = '#4789ff';

export const COLOR_TEXT_WARM_700 = '#4E4949';
export const COLOR_TEXT_WARM_400 = '#977E67';
export const COLOR_TEXT_WARM_100 = '#E9C9AB';

export const COLOR_TEXT_COLD_500 = '#526BA1';

/* Brand */

export const COLOR_BRAND_PRIMARY_800 = '#102E5D';
export const COLOR_BRAND_PRIMARY_700 = '#1B4291';
export const COLOR_BRAND_PRIMARY_600 = '#346ACA';
export const COLOR_BRAND_PRIMARY_400 = '#79A2EC';
export const COLOR_BRAND_PRIMARY_200 = '#C2D8FF';
export const COLOR_BRAND_PRIMARY_100 = '#DEEAFF';
export const COLOR_BRAND_PRIMARY_50 = '#EFF4FC';

export const COLOR_BRAND_PRIMARY_LIGHT_600 = '#f2f7fd99';
export const COLOR_BRAND_PRIMARY_LIGHT_500 = '#C0E1FF';
export const COLOR_BRAND_PRIMARY_LIGHT_400 = '#D1E9FF';
export const COLOR_BRAND_PRIMARY_LIGHT_300 = '#E0F0FF';
export const COLOR_BRAND_PRIMARY_LIGHT_200 = '#F2F7FD';

export const COLOR_BRAND_SECONDARY_700 = '#D67B7B';
export const COLOR_BRAND_SECONDARY_600 = '#EA7E7E';
export const COLOR_BRAND_SECONDARY_400 = '#FF9090';
export const COLOR_BRAND_SECONDARY_200 = '#FFCCC5';
export const COLOR_BRAND_SECONDARY_100 = '#FFDDDB';

export const COLOR_BRAND_SALMON_700 = '#E37C67';
export const COLOR_BRAND_SALMON_600 = '#FF9D88';
export const COLOR_BRAND_SALMON_500 = '#FFB3A3';
export const COLOR_BRAND_SALMON_400 = '#FFDAC8';
export const COLOR_BRAND_SALMON_300 = '#FFE9DF';
export const COLOR_BRAND_SALMON_200 = '#FFF2EC';
export const COLOR_BRAND_SALMON_100 = '#FFF8F4';
export const COLOR_BRAND_SALMON_50 = '#FAF3EF';

export const COLOR_BRAND_COMPLEMENTARY_AMARILLO = '#F8E4C5';
export const COLOR_BRAND_COMPLEMENTARY_AGUA_MARINA = '#ACE4F5';

/* Backgrounds */

export const COLOR_BACKGROUND_BADGE_BLUE = '#c7dfff';
export const COLOR_BACKGROUND_BADGE_SALMON = '#ffe5da';
export const COLOR_BACKGROUND_14 = '#e5ecfd';
export const COLOR_BACKGROUND_13 = 'rgba(224, 240, 255, 0.3)';
export const COLOR_BACKGROUND_12 = '#1f3052';
export const COLOR_BACKGROUND_11 = '#f3f3f3';
export const COLOR_BACKGROUND_10 = '#88baf5';
export const COLOR_BACKGROUND_9 = '#d4d4e0';
export const COLOR_BACKGROUND_8 = '#FBF9F9';
export const COLOR_BACKGROUND_7 = '#F8F8F8';
export const COLOR_BACKGROUND_6 = '#E2D8D6';
export const COLOR_BACKGROUND_5 = '#FBE8DA';
export const COLOR_BACKGROUND_4 = '#FFF0EB';
export const COLOR_BACKGROUND_3 = '#FAF5EF';
export const COLOR_BACKGROUND_2 = '#FBF6F5Z';
export const COLOR_BACKGROUND_1 = '#F3F7FB';

/* Semantic colors */

export const COLOR_SEMANTIC_SUCCESS_700 = '#3B8B81';
export const COLOR_SEMANTIC_SUCCESS_500 = '#45BFB0';
export const COLOR_SEMANTIC_SUCCESS_300 = '#83DDD2';
export const COLOR_SEMANTIC_SUCCESS_200 = '#C1ECE7';
export const COLOR_SEMANTIC_SUCCESS_150 = '#E4F3ED';
export const COLOR_SEMANTIC_SUCCESS_100 = '#EDF9F7';

export const COLOR_SEMANTIC_WARNING_700 = '#FFAD0D';
export const COLOR_SEMANTIC_WARNING_500 = '#FFCD6A';
export const COLOR_SEMANTIC_WARNING_400 = '#FFE0A3';
export const COLOR_SEMANTIC_WARNING_200 = '#FFF6E5';

export const COLOR_SEMANTIC_DANGER_700 = '#E14237';
export const COLOR_SEMANTIC_DANGER_500 = '#F48179';
export const COLOR_SEMANTIC_DANGER_200 = '#FFD2CE';
export const COLOR_SEMANTIC_DANGER_100 = '#FFECEB';

export const COLOR_SEMANTIC_LINK_500 = '#2789FD';
export const COLOR_SEMANTIC_LINK_200 = '#C8DFFA';

/*We should remove this but, meanwhile is a good fix to prevent repeating the blinking colors in the colors and the keyframes*/
export const COLOR_BLINKING_PRIMARY = '#bfd0ef';
export const COLOR_BLINKING_SECONDARY = '#95c4ff';
