import {Dispatch} from 'redux';

import {
  ConversationList,
  ConversationListItemStorage,
} from 'src/types/ConversationList';
import {mapApiResponseConversationList} from 'src/helpers/api_response/conversationList';
import {ConversationsActionType} from 'src/state/action_types/ConversationsActionType';
import {CONVERSATIONS_FILTERS} from 'src/pages/chat/constants/conversationFilters';
import {ConversationsAction} from 'src/state/actions/conversations';
import {fetchWrapper} from 'src/helpers/fetch';

export const getConversations = (
  page: number,
  filter: CONVERSATIONS_FILTERS | null,
) => {
  return async (dispatch: Dispatch<ConversationsAction>) => {
    if (page === 1) {
      dispatch({type: ConversationsActionType.CONVERSATIONS_LOADING});
    }
    const query = buildQueryAccordingFilter(filter, '');
    const resp = await fetchWrapper({
      endPoint: `conversations?page=${page}${query}`,
    });

    if (!resp) return null;
    if (!resp.body.success) return false;

    const {conversations, total_count} = resp.body;
    const conversationList: ConversationList = conversations?.map(
      (conversationListStorage: ConversationListItemStorage) =>
        mapApiResponseConversationList(conversationListStorage, false),
    );

    if (page === 1) {
      dispatch({
        type: ConversationsActionType.SET_CONVERSATIONS,
        payload: {
          conversations: conversationList,
          filter,
          total: total_count,
        },
      });
      return conversationList.length;
    } else {
      if (conversationList && conversationList.length > 0) {
        dispatch({
          type: ConversationsActionType.CONVERSATIONS_LOAD_MORE_CONVERSATIONS,
          payload: conversationList,
        });
        return true;
      } else {
        return false;
      }
    }
  };
};

export const getChatOptions = () => {
  return async (dispatch: Dispatch<ConversationsAction>) => {
    dispatch({type: ConversationsActionType.CONVERSATIONS_LOADING});
    const resp = await fetchWrapper({endPoint: `conversations/chat_options`});

    if (!resp) return null;
    if (
      !resp.body.success ||
      !resp.body.agents ||
      !resp.body.coupons_available ||
      !resp.body.plans_available ||
      !resp.body.default_agent
    )
      return false;

    const {
      agents: activeAgents,
      coupons_available: couponsAvailable,
      plans_available: plansAvailable,
      default_agent: defaultAgentPosition,
    } = resp.body;

    dispatch({
      type: ConversationsActionType.CONVERSATIONS_CHAT_OPTIONS,
      payload: {
        activeAgents,
        couponsAvailable,
        defaultAgentPosition,
        plansAvailable,
      },
    });
  };
};

function buildQueryAccordingFilter(
  filter: CONVERSATIONS_FILTERS | null,
  baseQuery: string,
): string {
  switch (filter) {
    case CONVERSATIONS_FILTERS.NO_AGENT:
      return baseQuery + '&waiting=true';
    case CONVERSATIONS_FILTERS.UNANSWERED:
      return baseQuery + '&unanswered=true';
    case CONVERSATIONS_FILTERS.UNREAD_MESSAGES:
      return baseQuery + '&unread_messages=true';
    case CONVERSATIONS_FILTERS.B2B:
      return baseQuery + '&partner=true';
    case CONVERSATIONS_FILTERS.B2C:
      return baseQuery + '&partner=false';
    case CONVERSATIONS_FILTERS.LIMBO:
      return baseQuery + '&unmatched=true';
    case CONVERSATIONS_FILTERS.MACCABI:
      return baseQuery + '&maccabi=true';
    case CONVERSATIONS_FILTERS.MACCABI_SCHEDULED:
      return baseQuery + '&maccabi=true&maccabi_scheduled=true';
    default:
      return baseQuery;
  }
}

export const markAsReadConversation = ({
  conversationId,
  agentId,
}: {
  conversationId: number;
  agentId: number | null;
}) => {
  return async (dispatch: Dispatch<ConversationsAction>) => {
    const resp = await fetchWrapper({
      endPoint: `conversations/mark_all_messages_as_read`,
      method: 'POST',
      data: {
        id: conversationId,
        agent_id: agentId,
      },
    });
    if (!resp) return null;
    if (!resp.body.success) return false;

    dispatch({
      type: ConversationsActionType.CONVERSATIONS_MARK_AS_READ,
      payload: {conversation_id: conversationId},
    });
  };
};
