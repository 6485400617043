import {convertObjectKeysToSnakeCase} from '@ifeelonline/chat-core';

import {
  mapSurveyListStatus,
  mapSurveySections,
} from 'src/helpers/api_response/triage_survey';
import {SurveyAnswer} from 'src/pages/chat/types/Surveys';
import {fetchWrapper} from 'src/helpers/fetch';

class TriageSurveyRepository {
  async getSurveysStatus(conversationId: number) {
    const response = await fetchWrapper({
      endPoint: `b2b/surveys_list?conversation_id=${conversationId}`,
      url: import.meta.env.VITE_API_URL,
    });

    if (!response) return null;

    if (response.body.error || !response.body.success) return null;
    const surveyListStatus = mapSurveyListStatus(response.body);
    return surveyListStatus;
  }

  async getSurvey(surveyId: number, conversationId: number) {
    const response = await fetchWrapper({
      endPoint: `b2b/all_survey_questions?survey_id=${surveyId}&conversation_id=${conversationId}`,
      url: import.meta.env.VITE_API_URL,
    });

    if (!response) return null;

    if (response.body.error || !response.body.success) return null;
    const surveySections = mapSurveySections(response.body);

    return surveySections;
  }

  async createAnswer(answer: SurveyAnswer, conversationId: number) {
    /* Back allows us to send multiple answers, that's why questions is an array */
    /* To have under control and avoid sending unnecessary data, we send it one by one */
    const response = await fetchWrapper({
      endPoint: `b2b/create_survey_answer`,
      method: 'POST',
      url: import.meta.env.VITE_API_URL,
      data: {
        conversation_id: conversationId,
        questions: [convertObjectKeysToSnakeCase(answer)],
      },
    });

    if (!response) return null;

    if (response.body.error || !response.body.success) return null;

    return true;
  }

  async finishSurvey(surveyId: number, conversationId: number) {
    const response = await fetchWrapper({
      endPoint: `b2b/survey_saved`,
      method: 'POST',
      url: import.meta.env.VITE_API_URL,
      data: {
        survey_id: surveyId,
        conversation_id: conversationId,
      },
    });

    if (!response) return null;

    if (response.body.error || !response.body.success) return null;

    return true;
  }

  async updateStatus({
    surveyId,
    conversationId,
    status,
  }: {
    surveyId: number;
    conversationId: number;
    status: number;
  }) {
    const response = await fetchWrapper({
      endPoint: `b2b/update_status`,
      method: 'POST',
      url: import.meta.env.VITE_API_URL,
      data: {
        survey_id: surveyId,
        conversation_id: conversationId,
        status,
      },
    });

    if (!response) return null;

    if (response.body.error || !response.body.success) return null;

    return true;
  }

  async completeTriage(conversationId: number) {
    const response = await fetchWrapper({
      endPoint: `b2b/triage_completed`,
      method: 'POST',
      url: import.meta.env.VITE_API_URL,
      data: {
        conversation_id: conversationId,
      },
    });

    if (!response) return null;

    if (response.body.error || !response.body.success) return null;

    return true;
  }
}

export default new TriageSurveyRepository();
