import * as React from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {Button} from '@ifeelonline/storybook';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {Funnel} from 'phosphor-react';

import {getConversations} from 'src/state/action_creators/conversationsActionCreator';
import close_icon from 'src/assets/images/icons/close.svg';
import {useUser} from 'src/providers/UserProvider';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {getScheduledConversations} from '../../../../state/action_creators/ScheduledPatientsActionCreator';
import {CONVERSATIONS_FILTERS} from '../../constants/conversationFilters';

import {MenuSpan} from './menuSpan';

interface Props {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isScheduledView?: boolean;
}
export const FilterConversationMenu = ({setPage, isScheduledView}: Props) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {user, currentAgentId} = useUser();
  const {filter} = useSelector((state: RootState) => state.conversations);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const dispatchGetConversations = (
    e: React.MouseEvent<HTMLElement>,
    filter: CONVERSATIONS_FILTERS | null,
  ) => {
    e.preventDefault();
    setPage(1);
    dispatch(getConversations(1, filter));
    setAnchorEl(null);
  };

  const dispatchGetScheduledConversations = (
    e: React.MouseEvent<HTMLElement>,
    filter: CONVERSATIONS_FILTERS | null,
  ) => {
    e.preventDefault();
    setPage(1);
    dispatch(getScheduledConversations(1, filter, currentAgentId));
    setAnchorEl(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (filter) {
      if (isScheduledView) {
        dispatchGetScheduledConversations(e, null);
      } else {
        dispatchGetConversations(e, null);
      }
    } else {
      setAnchorEl(e.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        buttonType="icon-button-secondary"
        onClick={handleClick}
        icon={
          filter ? (
            <img src={close_icon} alt="Close modal" />
          ) : (
            <Funnel size={22} />
          )
        }
        data-cy="filter-conversations-menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      />
      <Menu
        data-cy="filter-conversations-menu"
        className="font-sofia text-text-500"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableRestoreFocus>
        {!isScheduledView ? (
          <>
            <MenuItem
              onClick={(e) =>
                dispatchGetConversations(e, CONVERSATIONS_FILTERS.UNANSWERED)
              }>
              <MenuSpan variant="unanswered-filter" />
              {t('filter_conversations.unanswered')}
            </MenuItem>

            <MenuItem
              onClick={(e) =>
                dispatchGetConversations(
                  e,
                  CONVERSATIONS_FILTERS.UNREAD_MESSAGES,
                )
              }>
              <MenuSpan variant="unread-msg-filter" />
              {t('filter_conversations.unread_messages')}
            </MenuItem>

            {user?.partner === null && [
              <MenuItem
                key={'B2B'}
                onClick={(e) =>
                  dispatchGetConversations(e, CONVERSATIONS_FILTERS.B2B)
                }
                className="!text-start">
                <MenuSpan variant="b2b-filter" />
                B2B
              </MenuItem>,
              <MenuItem
                onClick={(e) =>
                  dispatchGetConversations(e, CONVERSATIONS_FILTERS.B2C)
                }
                key={'B2C'}
                className="!text-start">
                <MenuSpan variant="b2c-filter" />
                B2C
              </MenuItem>,
            ]}

            <MenuItem
              onClick={(e) =>
                dispatchGetConversations(e, CONVERSATIONS_FILTERS.LIMBO)
              }
              className="!text-start">
              <MenuSpan variant="limbo-filter" />
              {t('filter_conversations.limbo')}
            </MenuItem>

            <MenuItem
              data-cy="no-agent-li"
              onClick={(e) =>
                dispatchGetConversations(e, CONVERSATIONS_FILTERS.NO_AGENT)
              }
              className="!text-start">
              <MenuSpan variant="no-agent-filter" />
              {t('filter_conversations.no_agent')}
            </MenuItem>

            <MenuItem
              data-cy="maccabi-scheduled-li"
              onClick={(e) =>
                dispatchGetConversations(
                  e,
                  CONVERSATIONS_FILTERS.MACCABI_SCHEDULED,
                )
              }
              className="!text-start">
              <MenuSpan variant="maccabi-scheduled-filter" />
              {t('filter_conversations.maccabi_scheduled')}
            </MenuItem>
          </>
        ) : (
          <MenuItem
            data-cy="maccabi-scheduled-li"
            onClick={(e) =>
              dispatchGetScheduledConversations(
                e,
                CONVERSATIONS_FILTERS.HIGH_RISK,
              )
            }
            className="!text-start">
            <MenuSpan variant="maccabi-scheduled-filter" />
            {t('filter_conversations.maccabi_scheduled')}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
