import React, {createContext, useContext, useState, ReactNode} from 'react';

interface ConnectToAgentChannelContextType {
  shouldDisconnectFromAgentChannel: boolean;
  setShouldDisconnectFromAgentChannel: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const ConnectToAgentChannelContext = createContext<
  ConnectToAgentChannelContextType | undefined
>(undefined);

export const ConnectToAgentChannelProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [
    shouldDisconnectFromAgentChannel,
    setShouldDisconnectFromAgentChannel,
  ] = useState(false);

  return (
    <ConnectToAgentChannelContext.Provider
      value={{
        shouldDisconnectFromAgentChannel,
        setShouldDisconnectFromAgentChannel,
      }}>
      {children}
    </ConnectToAgentChannelContext.Provider>
  );
};

export const connectToAgentChannelProvider = () => {
  const context = useContext(ConnectToAgentChannelContext);
  if (context === undefined) {
    throw new Error(
      'connectToAgentChannelProvider must be used within a ConnectToAgentChannelProvider',
    );
  }
  return context;
};
