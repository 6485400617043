import React from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import noFavoritesMessages from 'src/assets/images/img_nomessage.png';
import {RootState} from 'src/state/store';

export const NoFavorite = () => {
  const {conversation} = useSelector((state: RootState) => state.conversation);
  const {t} = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <div>
        <img
          src={noFavoritesMessages}
          alt="reconnect"
          className="mt-7.5 w-50"
        />
      </div>

      <div className="mt-5 w-100">
        <p className="text-center font-sofia text-6 font-medium text-text-blueStrong">
          {
            t('no_fav_messages', {
              user_name: conversation?.patientNickname,
            }) as string
          }
        </p>
        <p className="mb-10 mt-5 text-center font-sofia text-3.5 font-light text-text-blue9b">
          {t('click_star_button') as string}
        </p>
      </div>
    </div>
  );
};
