import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {Loading, Text} from '@ifeelonline/storybook';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import AIQuestionnaireRepository from 'src/services/AIQuestionnaireRepository';
import {AIQuestion} from 'src/pages/chat/types/AIQuestionnaire';
import {useAppDispatch} from 'src/hooks/hooks';
import {ROUTES} from 'src/constants/Routes';
import {RootState} from 'src/state';

import {getAnswerDescription} from '../../utils/ai_questionnaire/logic';

import {AIHeaderQuestionnaire} from './AIHeaderQuestionnaire';
import {AIQuestionnaireTitle} from './AIQuestionnaireTitle';

export const AIQuestionnaireFormDisplay = () => {
  const dispatch = useAppDispatch();
  const [questionsAi, setQuestionsAI] = useState<AIQuestion[] | null>(null);
  const {conversation} = useSelector((state: RootState) => state.conversation);

  if (!conversation || conversation.id === null) return <></>;

  const getAIQuestionnairePatientAnswers = async () => {
    const questions = await AIQuestionnaireRepository.getQuestions(
      conversation.id,
    );

    setQuestionsAI(questions);
  };

  const closeForm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    dispatch({
      type: ConversationActionType.AI_QUESTIONNAIRE_FORM_DISPLAY_OPEN,
      payload: false,
    });
  };

  const showInfo = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.open(ROUTES.AI_QUESTIONNAIRE_INFO, '_blank');
  };

  useEffect(() => {
    getAIQuestionnairePatientAnswers();
  }, []);

  return (
    <div className="relative h-full w-91.5 overflow-y-auto overflow-x-hidden border-l border-solid border-borders-aiQuestionnaire bg-white">
      <AIHeaderQuestionnaire actionClose={closeForm} actionInfo={showInfo} />
      <div className="px-6">
        <AIQuestionnaireTitle />
        {questionsAi && questionsAi.length > 0 ? (
          questionsAi.map((question) => (
            <div className="flex" key={question.id}>
              <div className="flex w-full flex-col border-b border-solid border-borders-aiQuestionnaire py-5 text-start">
                <div className="mb-3">
                  <Text textType={'body-3'} fontColor="text-text-400">
                    {question.body}
                  </Text>
                </div>
                <Text textType={'body-3'} fontColor="text-text-600">
                  {getAnswerDescription(
                    question.id,
                    question.answer,
                    questionsAi,
                  )}
                </Text>
              </div>
            </div>
          ))
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
