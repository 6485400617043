export enum ConversationActionType {
  CONVERSATION_HANDLE_MESSAGE_FAVORITE = '[conversation] Set message favorite/unfavorite',
  CONVERSATION_LOADING = '[conversation] Set conversation loading',
  CONVERSATION_LOADING_MORE_MESSAGES = '[conversation] Loading more messages',
  CONVERSATION_LOAD_MORE_MESSAGES = '[conversation] Load more messages',
  CONVERSATION_MESSAGE_DELETED = '[conversation] Update message after deleting',
  CONVERSATION_MESSAGE_EDITED = '[conversation] Update message after editing',
  CONVERSATION_MESSAGE_READ = '[conversation] Message red',
  CONVERSATION_NEW_MESSAGE = '[conversation] New message',
  CONVERSATION_USER_STATUS = '[conversation] User status changes',
  SET_CONVERSATION = '[conversation] Set conversation',
  SET_PATIENT_SURVEY = '[conversation] Set user survey',
  SET_NEW_MESSAGES = '[conversation] Set new messages',
  SET_MESSAGES_BOUNDS = '[conversation] Set messages bounds',
  JUMPING_TO_MESSAGES = '[conversation] Jumping to messages',
  JUMP_TO_MESSAGES = '[conversation] Jump to messages',
  SET_PAGES_LOADED = '[conversation] Set pages loaded',
  RESET_INITIAL_STATE = '[conversation] Reset initial state',

  AI_QUESTIONNAIRE_FORM_LOADING = '[conversation] Set AI questionnaire form loading',
  AI_QUESTIONNAIRE_FORM_OPEN = '[conversation] Set AI questionnaire form open',
  AI_QUESTIONNAIRE_FORM_DISPLAY_OPEN = '[conversation] Set AI questionnaire form display open',
  AI_QUESTIONNAIRE_FORM_PATIENT_ID = '[conversation] Set AI questionnaire form patient id',
  SET_STATUS_AI_QUESTIONNAIRE_FORM = '[conversation] Set status AI questionnaire form',
  AI_QUESTIONNAIRE_FORM_RESULT = '[conversation] Set result AI questionnaire form',
  SET_AI_QUESTIONS = '[conversation] Set AI questions',
  UPDATE_AI_ANSWERS = '[conversation] Update AI answers',

  ADD_VIDEO_CALL_INFO = '[conversation] Add videocall info',
  DELETE_VIDEO_CALL_INFO = '[conversation] Delete videocall info',
  EDIT_VIDEO_CALL_INFO = '[conversation] Edit videocall info',

  TRIAGE_SURVEY_FORM_LOADING = '[conversation] Set triage questionnaire form loading',
  TRIAGE_SURVEY_FORM_OPEN = '[conversation] Set triage questionnaire form open',
  TRIAGE_SURVEY_LIST = '[conversation] Set triage questionnaire list',
  TRIAGE_SURVEY_LIST_UPDATE_STATUS = '[conversation] Update triage questionnaire list status',
  TRIAGE_SURVEY_COMPLETED = '[conversation] Update triage completed status',
}
