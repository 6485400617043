import React from 'react';

import {useTranslation} from 'react-i18next';

import VCDoneSvg from 'src/assets/images/icons/vc_done.svg?react';

export const VideoSessionsHeldHeader = ({
  sessionsHeld,
}: {
  sessionsHeld: number;
}) => {
  const {t} = useTranslation();
  return (
    <h3 className="flex w-full p-5">
      <VCDoneSvg className="mr-1 inline" />
      <p className="flex-1 text-left font-sofia text-4 font-semibold text-text-600">
        {sessionsHeld} {t('videosessions.held')}
      </p>
    </h3>
  );
};
