import React, {useContext, useEffect, useRef, useState} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';
import {Button, Loading} from '@ifeelonline/storybook';

import {CaretCircleDown} from 'phosphor-react';

import {
  getChatOptions,
  getConversations,
} from 'src/state/action_creators/conversationsActionCreator';
import {COLOR_TEXT_BLUE_POOL} from 'src/constants/colors';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {CHAT_FORM_INITIAL_VALUES} from '../../constants/chat';
import {ChatPageContext} from '../ChatPageContext';

import {Conversation} from './Conversation';

interface Props {
  setAreAllPagesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  reset: (newFormState?: typeof CHAT_FORM_INITIAL_VALUES) => void;
}

export const ConversationsList = ({
  page,
  setPage,
  setAreAllPagesLoaded,
  reset,
}: Props) => {
  const dispatch = useAppDispatch();

  const dataFetch = useRef(false);

  /*TO-DO review duplication, why allpadesloades and allconversations */
  const [allConversations, setAllConversations] = useState(false);
  const {pageConversations, setPageConversations} = useContext(ChatPageContext);

  const {status, conversations, filter} = useSelector(
    (state: RootState) => state.conversations,
  );

  const dispatchGetConversations = () => {
    dispatch(getConversations(pageConversations!, filter));
  };

  const loadMoreConversations = () => {
    setPageConversations!(pageConversations! + 1);
    dispatch(getConversations(pageConversations! + 1, filter)).then((res) => {
      if (!res) {
        setAllConversations(true);
      }
    });
  };

  useEffect(() => {
    if (dataFetch.current) {
      return;
    }
    dataFetch.current = true;
    dispatchGetConversations();
    dispatch(getChatOptions());
  }, []);

  return (
    <div
      id="home__conversations"
      className="w-87.5 overflow-auto border-r border-solid border-backgrounds-9 p-2.5"
      style={{borderRightWidth: '1px'}}>
      {status === SharedActionType.LOADING ? (
        <div className="relative mx-0 my-2.5 h-7.5">
          <Loading />
        </div>
      ) : (
        conversations && (
          <>
            {conversations.map((conversation) => (
              <Conversation
                key={conversation.id}
                conversationListItem={conversation}
                page={page}
                setPage={setPage}
                setAreAllPagesLoaded={setAreAllPagesLoaded}
                reset={reset}
              />
            ))}
            {!allConversations && conversations.length >= 25 && (
              <Button
                buttonType="icon-button-secondary"
                onClick={() => loadMoreConversations()}
                icon={
                  <CaretCircleDown size={16} color={COLOR_TEXT_BLUE_POOL} />
                }
              />
            )}
          </>
        )
      )}
    </div>
  );
};
