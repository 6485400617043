import {mapApiResponseMessage} from '@ifeelonline/chat-core';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {AppDispatch} from 'src/state';

import {ConversationChannel} from '../types/conversationChannelDataTypes';

export function handleConversationChannelReceived(
  dataResponse: ConversationChannel['DataResponse'],
  dispatch: AppDispatch,
) {
  switch (dataResponse.action) {
    case 'client_read_message':
      handleClientReadMessage(dispatch, dataResponse.data);
      break;
    case 'message_updated':
      handleMessageUpdated(dispatch, dataResponse.data);
      break;
    case 'message_deleted':
      handleMessageDeleted(dispatch, dataResponse.data);
      break;
    case 'send_message':
      handleSendMessage(dispatch, dataResponse.data, dataResponse.client);
      break;
    case 'user_status':
      handleUserStatus(dispatch, dataResponse.data);
      break;
    default:
      break;
  }
}

const handleClientReadMessage = (
  dispatch: AppDispatch,
  data: ConversationChannel['ClientReadMessage'],
) => {
  dispatch({
    type: ConversationActionType.CONVERSATION_MESSAGE_READ,
    payload: {message_id: data.message.id, date: data.message.created_at},
  });
};

const handleSendMessage = (
  dispatch: AppDispatch,
  data: ConversationChannel['SendMessageData'],
  client: 'patient' | 'support',
) => {
  /* TO-DO remove this when the API is fixed */
  if (data.file?.url === null) data.file = null;
  dispatch({
    type: ConversationActionType.CONVERSATION_NEW_MESSAGE,
    payload: mapApiResponseMessage(data),
  });
  if (client === 'patient') {
    dispatch({
      type: ConversationActionType.SET_NEW_MESSAGES,
      conversationId: data.conversation_id,
    });
  }
};

const handleMessageUpdated = (
  dispatch: AppDispatch,
  data: ConversationChannel['MessageUpdatedData'],
) => {
  dispatch({
    type: ConversationActionType.CONVERSATION_MESSAGE_EDITED,
    payload: data,
  });
};

const handleMessageDeleted = (
  dispatch: AppDispatch,
  data: ConversationChannel['MessageDeletedData'],
) => {
  dispatch({
    type: ConversationActionType.CONVERSATION_MESSAGE_DELETED,
    payload: {message_id: data.message_id},
  });
};

const handleUserStatus = (
  dispatch: AppDispatch,
  data: ConversationChannel['UserStatusChangedData'],
) => {
  dispatch({
    type: ConversationActionType.CONVERSATION_USER_STATUS,
    payload: {status: data.status},
  });
};
