const locales = new Map();
locales.set('en', 'en-US');
locales.set('es', 'es-ES');
locales.set('fr', 'fr-FR');
locales.set('de', 'de-DE');
locales.set('pt', 'pt-PT');
locales.set('it', 'it-IT');

type localeZoom = 'en-US' | 'es-ES' | 'fr-FR' | 'de-DE' | 'pt-PT' | 'it-IT';
//In Zoom locales, 'he-IL' is not available as Zoom does not provide support for Hebrew.

export const getLocaleZoom = (locale: string | undefined): localeZoom => {
  return locales.get(locale) || 'en-US';
};
