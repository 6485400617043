import React from 'react';

import {Textarea} from '@ifeelonline/storybook';

export const TextAreaFormField = ({
  textAnswer,
  handleTextAreaChange,
  handleBlur,
  questionId,
}: {
  questionId: number;
  textAnswer: string;
  handleTextAreaChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  handleBlur: (id: number, value: string) => void;
}) => (
  <div
    className="flex w-full"
    onBlur={() => handleBlur(questionId, textAnswer)}>
    <Textarea
      value={textAnswer}
      onChange={handleTextAreaChange}
      placeholder=""
      adjustHeightAutomatically
    />
  </div>
);
