import React from 'react';

import {FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Toaster} from 'react-hot-toast';
import {useSelector} from 'react-redux';

import {Button, Loading} from '@ifeelonline/storybook';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {useAIQuestionnaireFromProvider} from 'src/providers/AIQuestionnaireProvider';
import AIQuestionnaireRepository from 'src/services/AIQuestionnaireRepository';
import {AIFormSteps} from 'src/pages/chat/types/AIQuestionnaire';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {
  extractSOFASQuestion,
  getQuestionType,
} from '../../utils/ai_questionnaire/logic';

import {AIQuestionnaireError} from './AIQuestionnaireError';
import {AIQuestionnaireTitle} from './AIQuestionnaireTitle';
import {AIRenderQuestions} from './AIRenderQuestions';

export const AIQuestionnaireFormQuestions = () => {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm({mode: 'onChange'});

  const {t} = useTranslation();
  const {setStep, setSOFASAnswer, setIsOpenSofaErrorModal} =
    useAIQuestionnaireFromProvider();

  const {conversation, aiQuestions} = useSelector(
    (state: RootState) => state.conversation,
  );

  const conversationId = conversation?.id;
  const {aiQuestionnaireForm} = useSelector(
    (state: RootState) => state.conversation,
  );

  if (!conversation || !conversation.id) return <></>;

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    dispatch({
      type: ConversationActionType.AI_QUESTIONNAIRE_FORM_LOADING,
      payload: true,
    });
    const res = await AIQuestionnaireRepository.calculateSofas(conversation.id);

    const question = aiQuestions ? extractSOFASQuestion(aiQuestions) : null;
    if (res && question) {
      setSOFASAnswer([
        {
          comment: res.toString(),
          questionId: question.id,
          type: getQuestionType(question.tag),
        },
      ]);
      setStep(AIFormSteps.SOFAS);
      dispatch({
        type: ConversationActionType.AI_QUESTIONNAIRE_FORM_LOADING,
        payload: false,
      });
    } else {
      setSOFASAnswer([]);
      setIsOpenSofaErrorModal(true);
      dispatch({
        type: ConversationActionType.AI_QUESTIONNAIRE_FORM_LOADING,
        payload: false,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-6">
        <AIQuestionnaireTitle />

        {conversationId && aiQuestions && aiQuestions.length > 0 ? (
          <>
            {aiQuestions.slice(0, -1).map((question) => (
              <AIRenderQuestions
                key={question.id}
                question={question}
                control={control}
                errors={errors}
                isLastQuestion={
                  aiQuestions[aiQuestions.length - 1].id === question.id
                }
                conversationId={conversationId}
              />
            ))}
            {Object.keys(errors).length !== 0 && <AIQuestionnaireError />}
            <div className="flex w-full items-center justify-center py-5">
              <Button
                type="submit"
                buttonType={'button-primary'}
                text={t('ai.form.calculate_SOFAS')}
                loading={aiQuestionnaireForm.isLoading}
              />
            </div>
          </>
        ) : (
          <div className="absolute left-0 top-0 z-10 size-full">
            <Loading />
          </div>
        )}
      </div>

      <Toaster position="top-center" />
    </form>
  );
};
