import React, {ComponentProps} from 'react';

import {useSelector} from 'react-redux';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import AIQuestionnaireRepository from 'src/services/AIQuestionnaireRepository';
import {useAppDispatch} from 'src/hooks/hooks';
import {ROUTES} from 'src/constants/Routes';
import {RootState} from 'src/state';

import {AIQuestionnaireStatus} from '../../types/AIQuestionnaire';

import {AIHeaderQuestionnaire} from './AIHeaderQuestionnaire';

export const AIQuestionnaireFormContainer = ({
  children,
}: ComponentProps<'div'>) => {
  const dispatch = useAppDispatch();
  const {conversation} = useSelector((state: RootState) => state.conversation);
  const {aiQuestions} = useSelector((state: RootState) => state.conversation);

  if (!conversation || !conversation.id) return <></>;

  const closeForm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!aiQuestions) return;

    const isDraft = aiQuestions.some((question) => question.answer);
    const surveyStatus: AIQuestionnaireStatus = isDraft
      ? AIQuestionnaireStatus.DRAFT
      : AIQuestionnaireStatus.START;

    await AIQuestionnaireRepository.changeSurveyStatus(
      conversation.id.toString(),
      surveyStatus as unknown as AIQuestionnaireStatus,
    );

    dispatch({
      type: ConversationActionType.AI_QUESTIONNAIRE_FORM_OPEN,
      payload: false,
    });

    dispatch({
      type: ConversationActionType.SET_STATUS_AI_QUESTIONNAIRE_FORM,
      payload: {status: surveyStatus},
    });
  };

  const showInfo = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.open(ROUTES.AI_QUESTIONNAIRE_INFO, '_blank');
  };

  return (
    <div className="relative h-full w-91.5 overflow-y-auto overflow-x-hidden border-l border-solid border-borders-aiQuestionnaire bg-white">
      <AIHeaderQuestionnaire actionClose={closeForm} actionInfo={showInfo} />
      {children}
    </div>
  );
};
