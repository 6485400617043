import React, {useEffect, useRef, useState} from 'react';

import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {
  ChatProps,
  ChatScrollEffect,
  getNextPage,
  useChatScrollEffects,
  useScroll,
  useVisibility,
} from '@ifeelonline/chat-core';
import {
  Button,
  ScrollToBottom,
  ScrollableContainer,
} from '@ifeelonline/storybook';

import {getFavoritesConversation} from 'src/state/action_creators/favoritesActionCreator';
import {ROUTES, RouteConversation} from 'src/constants/Routes';
import {useChat} from 'src/providers/ChatProvider';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state/store';

import {FavoritesContent} from './FavoritesContent';

export type ChatContainerPicks = Pick<ChatProps, 'msgRefs'>;
interface Props extends ChatContainerPicks {
  pagesLoaded: number[];
  setPagesLoaded: React.Dispatch<React.SetStateAction<number[]>>;
}

export const FavoritesContainer = ({
  msgRefs,
  pagesLoaded,
  setPagesLoaded,
}: Props) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {conversationId} = useParams<string>();
  const queryParams = new URLSearchParams(location.search);
  const isSingleConversation = queryParams.get('isSingleConversation');

  const navigate = useNavigate();
  const {handleScroll, scrollToBottom} = useScroll();
  const chatScrollEffects = useChatScrollEffects();

  const {status, messages} = useSelector((state: RootState) => state.favorites);
  const {conversation} = useSelector((state: RootState) => state.conversation);

  const {newMessages, setNewMessages, isVisibleLastMessage} = useChat();
  const messagesDiv = useRef<any>(null);

  const [scrollReachedTop, setScrollReachedTop] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const isFirstLoadRef = useRef(isFirstLoad);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [areAllPagesLoaded, setAreAllPagesLoaded] = useState<boolean>(false);
  const [firstMsgRefId, setFirstMsgRefId] =
    useState<ChatScrollEffect['firstMsgRefId']>(null);
  const [lastMsgRefId, setLastMsgRefId] =
    useState<ChatScrollEffect['lastMsgRefId']>(null);
  const [isNewPage, setIsNewPage] = useState<boolean>(false);

  useEffect(() => {
    isFirstLoadRef.current = isFirstLoad;
  }, [isFirstLoad]);

  const showScrollButton = !useVisibility(messagesEndRef, {threshold: 0.1});
  useEffect(() => {
    setIsNewPage(true);
    if (scrollReachedTop && !isFirstLoad && !isLoading) {
      handleScroll({
        areAllPagesLoaded,
        loading: isLoading,
        status,
        messagesDiv,
        conversation,
        setLoading: setIsLoading,
        dispatchFunction: dispatchConversation,
        setNewMessages,
        newMessages,
        isUpDirection: true,
        pagesLoaded,
      })({
        preventDefault: () => {},
        currentTarget: {
          scrollHeight: messagesDiv.current.current.scrollHeight,
          scrollTop: 0,
          clientHeight: window.innerHeight,
        },
      });
      setScrollReachedTop(false);
    } else if (scrollReachedTop && isFirstLoad) {
      setIsFirstLoad(false);
    }
    setIsNewPage(false);
  }, [scrollReachedTop]);

  useEffect(() => {
    setIsLoading(true);
    chatScrollEffects({
      messages,
      setLastMsgRefId,
      lastMsgRefId,
      setLoading: setIsLoading,
      firstMsgRefId,
      msgRefs,
      setFirstMsgRefId,
      messagesDiv,
      pagesLoaded,
      isFirstLoad,
      isVisibleLastMessage,
      isNewPage,
    });
  }, [messages]);

  const dispatchConversation = (directionIsUp: boolean) => {
    const nextPage = getNextPage(directionIsUp, pagesLoaded, setIsLoading);

    if (!nextPage) return;
    if (conversation && conversation.id) {
      dispatch(getFavoritesConversation(conversation.id, nextPage)).then(
        (res) => {
          if (!res) {
            setAreAllPagesLoaded(true);
            setIsLoading(false);
          } else {
            setPagesLoaded([...pagesLoaded, nextPage]);
          }
        },
      );
    }
  };

  const onScrollReachTop = () => {
    if (!isFirstLoadRef.current) {
      setScrollReachedTop(true);
    }
  };

  return (
    <div id="chat-container" className="block w-full">
      <ScrollableContainer
        id="messages"
        className="columns container-div relative mx-auto h-messagesContainer overflow-auto overflow-y-scroll px-1 pb-11 pt-14"
        onScrollReachTop={onScrollReachTop}
        data-page="1"
        isDisabled={isLoading}
        onScroll={() => {
          setIsLoading(false);
          setIsFirstLoad(false);
        }}
        ref={messagesDiv}>
        <FavoritesContent
          isLoading={isLoading}
          msgRefs={msgRefs}
          messagesEndRef={messagesEndRef}
          areAllPagesLoaded={areAllPagesLoaded}
          messagesDiv={messagesDiv}
        />
      </ScrollableContainer>

      <div className="relative">
        <div className="absolute bottom-0 right-8 z-20">
          {showScrollButton && (
            <ScrollToBottom
              newMessages={0}
              handleScrollToBottom={() =>
                scrollToBottom({
                  setNewMessages,
                  setLoading: setIsLoading,
                  messagesDiv,
                  isOwnMessage: false,
                })
              }
            />
          )}
        </div>
      </div>
      <div className="flex items-center justify-center border-t border-solid border-backgrounds-14 p-2.5 text-center">
        <Button
          buttonType="button-primary"
          text={t('back_button')}
          onClick={() =>
            isSingleConversation && conversationId
              ? navigate(RouteConversation(conversationId))
              : navigate(ROUTES.HOME)
          }
        />
      </div>
    </div>
  );
};
