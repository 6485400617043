import {useState} from 'react';

import {InputErrorMessage, Text} from '@ifeelonline/storybook';

import {
  SurveyAnswer,
  SurveyQuestion,
  SurveyQuestionOption,
} from 'src/pages/chat/types/Surveys';

import {useSurveys} from '../../hooks/useSurveys';

import {InputComment} from './InputComment';

export const CheckboxCommentFormField = ({
  questionOptions,
  selectedOptions,
  handleCheckboxChange,
  error,
  question,
  conversationId,
}: {
  questionOptions: SurveyQuestionOption[];
  selectedOptions: number[];
  handleCheckboxChange: (options: number[]) => void;
  error: string | null;
  question: SurveyQuestion;
  conversationId: number;
}) => {
  const [comment, setComment] = useState<string>(
    question.answer?.comment || '',
  );
  const {updateAnswers} = useSurveys({question, conversationId});

  const handleChange = (optionId: number) => {
    const updatedOptions = selectedOptions.includes(optionId)
      ? selectedOptions.filter((id) => id !== optionId)
      : [...selectedOptions, optionId];

    handleCheckboxChange(updatedOptions);

    const newAnswer: SurveyAnswer = {
      questionId: question.id,
      optionIds: updatedOptions,
      comment,
    };
    updateAnswers(newAnswer);
  };

  const handleBlur = () => {
    const newAnswer: SurveyAnswer = {
      questionId: question.id,
      optionIds: selectedOptions,
      comment,
    };
    updateAnswers(newAnswer);
  };

  return (
    <div className="flex flex-col gap-3" onBlur={handleBlur}>
      {questionOptions.map((option) => (
        <label key={option.id} className="flex items-center gap-2">
          <input
            type="checkbox"
            name={`checkbox-${option.id}`}
            value={option.id}
            checked={selectedOptions.includes(option.id)}
            className="size-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500"
            onChange={() => {
              handleChange(option.id);
            }}
          />

          <Text textType="body-3" fontColor="text-text-400">
            {option.body}
          </Text>
        </label>
      ))}
      <InputComment comment={comment} setComment={setComment} />
      <InputErrorMessage text={error || ''} />
    </div>
  );
};
