export enum ConversationsActionType {
  CONVERSATIONS_AGENT_CHANGED = '[conversations] Agent for conversation changed',
  CONVERSATIONS_CHAT_OPTIONS = '[conversations] Set conversations chat options',
  CONVERSATIONS_LOADING = '[conversations] Set conversations loading',
  CONVERSATIONS_LOADING_MORE_CONVERSATIONS = '[conversations] Set conversations loading more messages',
  CONVERSATIONS_LOAD_MORE_CONVERSATIONS = '[conversations] Load more conversations',
  CONVERSATIONS_MARK_AS_READ = '[conversations] Set one conversation mark as read',
  CONVERSATIONS_MATCHED = '[conversations] Set one conversation matched',
  CONVERSATIONS_NEW_MESSAGE = '[conversations] Set one conversation new message',
  CONVERSATIONS_NEW_USER = '[conversations] Set one conversation new user',
  CONVERSATIONS_TYPING = '[conversations] Set one conversation typing',
  CONVERSATIONS_USER_STATUS = '[conversations] Set one conversation user status',
  SET_CONVERSATIONS = '[conversations] Set conversations',
  SET_ACTIVE_CONVERSATION = '[conversations] Set active conversation',
  ADD_VIDEO_SESSION_INFO = '[conversations] Add video session info to one conversation',
  EDIT_VIDEO_SESSION_INFO = '[conversations] Edit video session info to one conversation',
}
