import toast from 'react-hot-toast';

import {
  Conversation,
  fetchFormData,
  LayoutMessageType,
  mapApiResponseConversation,
  mapApiResponseMessage,
  Message,
  MessageStorage,
  messageUtils,
  SupportMatchStatus,
} from '@ifeelonline/chat-core';

import {Dispatch} from 'redux';

import {ConversationsActionType} from 'src/state/action_types/ConversationsActionType';
import {mapApiResponseAIQuestionsNew} from 'src/helpers/api_response/ai_questionnaire';
import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {ConversationAction, EditMessage} from 'src/state/actions/conversation';
import {baseUrl, fetchWrapper, fireFetchErrorModal} from 'src/helpers/fetch';
import {AIQuestionNew} from 'src/pages/chat/types/AIQuestionnaire';

import {convertAnswersToAnswersStorage} from '../../helpers/api_response/ai_questionnaire';
import {ConversationsAction} from '../actions/conversations';

//TODO TO-DO revisar en una segunda iteracción para poner el tipo correctp de los any que quedan
export interface MessageSendObj {
  body?: string;
  conversation_id?: number | null;
  patient_id?: number | null;
  user_id?: number | null;
  reply_to?: string | null;
  layout?: string;
  received_on?: string;
  original_message_id?: string;
}

export const getConversation = (conversationId: number, page: number) => {
  return async (dispatch: Dispatch<ConversationAction>) => {
    dispatch({
      type:
        page === 1
          ? ConversationActionType.CONVERSATION_LOADING
          : ConversationActionType.CONVERSATION_LOADING_MORE_MESSAGES,
    });

    const resp = await fetchWrapper({
      endPoint: `conversations/${conversationId}?page=${page}`,
    });

    if (!resp) return null;
    if (!resp.body.success) return false;

    const {conversation_info, messages} = resp.body;
    const conversationMapped: Conversation = mapApiResponseConversation(
      conversation_info,
      false,
    );

    const messagesMapped: Message[] = messages?.map(
      (message: MessageStorage) => {
        /* TO-DO remove this when the API is fixed */
        if (message.file?.url === null) message.file = null;
        const mappedMessage = mapApiResponseMessage(message);

        // TODO This is to prevent it from breaking with what is sent or has already been sent, in the end we have to use it.
        if (
          mappedMessage.layout === LayoutMessageType.AUDIO &&
          message.file?.url
        ) {
          mappedMessage.audio = message.file;
        } else if (
          mappedMessage.layout === LayoutMessageType.VIDEO &&
          message.file?.url
        ) {
          mappedMessage.videoObject = message.file;
        }

        return mappedMessage;
      },
    );

    if (page === 1) {
      dispatch({
        type: ConversationActionType.SET_CONVERSATION,
        payload: {
          conversation: conversationMapped,
          messages: messagesMapped,
        },
      });
      return conversation_info as object;
    } else {
      if (messagesMapped) {
        dispatch({
          type: ConversationActionType.CONVERSATION_LOAD_MORE_MESSAGES,
          payload: messagesMapped,
        });
        return true;
      } else {
        return false;
      }
    }
  };
};

export const getPreviousMessagesToOne = (
  messageId: number,
  conversationId: number,
) => {
  return async (dispatch: Dispatch<ConversationAction>) => {
    dispatch({type: ConversationActionType.JUMPING_TO_MESSAGES, payload: true});
    const resp = await fetchWrapper({
      endPoint: `jump_to_messages?id=${conversationId}&message_id=${messageId}`,
    });

    if (!resp) return null;
    if (!resp.body.success || !resp.body.messages) return false;

    const {messages, page} = resp.body;
    const messagesMapped: Message[] = messages?.map(
      (message: MessageStorage) => {
        /* TO-DO remove this when the API is fixed */
        if (message.file?.url === null) message.file = null;
        mapApiResponseMessage(message);
      },
    );

    dispatch({
      type: ConversationActionType.JUMP_TO_MESSAGES,
      payload: messagesMapped,
    });

    return Number(page);
  };
};

export const setSurvey = (conversationId: number, isFemiSupport: boolean) => {
  return async (dispatch: Dispatch<ConversationAction>) => {
    const resp = await fetchWrapper({
      endPoint: `conversations/get_survey?id=${conversationId}${isFemiSupport ? '&name=new-onboard-mac' : ''}`,
    });

    if (!resp) return null;
    if (!resp.body.success || !resp.body.answers || !resp.body.disorder)
      return false;

    const {answers, disorder} = resp.body;
    dispatch({
      type: ConversationActionType.SET_PATIENT_SURVEY,
      payload: {
        answers,
        disorder,
      },
    });
    return true;
  };
};

type DispatchGetConversation = (
  dispatch: Dispatch<ConversationAction>,
) => Promise<boolean | object | null>;

export const changeAgent = ({
  conversationId,
  agentId,
  agentName,
  conversationStatus,
}: {
  conversationId: number;
  agentId: number;
  agentName: string;
  conversationStatus: SupportMatchStatus | null;
}) => {
  return async (
    dispatch: (
      dispatch:
        | ConversationAction
        | ConversationsAction
        | DispatchGetConversation,
    ) => Promise<void>,
  ) => {
    const resp = await fetchWrapper({
      endPoint: `conversations/change_agent`,
      data: {
        id: conversationId,
        agent: agentId,
      },
      method: 'POST',
    });
    if (!resp) return null;
    if (!resp.body.success) return false;
    if (
      conversationStatus &&
      conversationStatus === SupportMatchStatus.MATCHED
    ) {
      dispatch({
        type: ConversationsActionType.CONVERSATIONS_AGENT_CHANGED,
        payload: {
          agent_nickname: agentName,
          conversation_id: conversationId,
        },
      });
    } else {
      dispatch({
        type: ConversationsActionType.CONVERSATIONS_MATCHED,
        payload: {
          conversationId,
          agentId: agentId,
          agentName: agentName,
        },
      });
    }
    dispatch(getConversation(conversationId, 1));
    return true;
  };
};

interface sendMessageProps {
  messageObj: FormData | MessageSendObj;
  typeMsg?: LayoutMessageType;
}

export const sendMessage = (messageObj: MessageSendObj) => {
  return async () => {
    const resp = await fetchWrapper({
      endPoint: `messages`,
      data: createDataMessage(messageObj),
      method: 'POST',
    });

    if (!resp) return null;
    return resp.body.success;
  };
};

export const sendMessageFormData = (formData: FormData) => {
  return async () => {
    const resp = await fetchFormData({
      endPoint: `messages`,
      data: formData,
      method: 'POST',
      baseUrl,
      fireFetchErrorModal,
    });

    if (!resp) return null;
    return resp.body.success;
  };
};

const {splitMessageRequest, getPropertyLength} = messageUtils;

export const sendSplitMessage = ({messageObj, typeMsg}: sendMessageProps) => {
  return async () => {
    const messageChunks = splitMessageRequest(messageObj as MessageSendObj);
    const endPoint = 'messages';
    const method = 'POST';
    let resp;
    let timesToRequest = 1;
    if (Array.isArray(messageChunks)) timesToRequest = messageChunks.length;

    if (!typeMsg) {
      for (let index = 0; index !== timesToRequest; index++) {
        resp = await fetchWrapper({
          endPoint,
          data: createDataMessage(messageChunks[index]),
          method,
        });
      }
    } else {
      resp = await fetchWrapper({
        endPoint,
        data: messageObj,
        method,
      });
    }

    if (!resp) return null;
    return resp.body.success;
  };
};

export const editMessage = (data: EditMessage) => {
  return async () => {
    const limitedMessage = limitMessageRequest(data);
    const messageLength = data.body.length
      ? data.body.replace(/^\s+|\s+$/gm, '').length
      : false;
    let resp;
    if (limitedMessage.exceeded) {
      toast.error(data.error_message);
      return false;
    } else if (messageLength === 0) {
      return {error: 'errors.messages.message_empty'};
    } else {
      resp = await fetchWrapper({
        endPoint: `messages/${data.id}`,
        data: limitedMessage.request,
        method: 'PATCH',
      });

      if (!resp) return null;

      return resp.body.success;
    }
  };
};

export const deleteMessage = (messageId: number, conversationId: number) => {
  return async (dispatch: Dispatch<ConversationAction>) => {
    const resp = await fetchWrapper({
      endPoint: `messages/${messageId}`,
      data: {
        id: messageId,
        conversation_id: conversationId,
      },
      method: 'DELETE',
    });

    if (!resp) return null;
    if (!resp.body.success || !resp.body.message) return false;

    dispatch({
      type: ConversationActionType.CONVERSATION_MESSAGE_DELETED,
      payload: resp.body.message,
    });
    return true;
  };
};

const createDataMessage = (messageObj: MessageSendObj) => {
  return {
    body: messageObj.body,
    conversation_id: messageObj.conversation_id,
    patient_id: messageObj.patient_id,
    user_id: messageObj.user_id,
    uuid: createUUID(),
    layout: messageObj.layout,
    received_on: new Date().toISOString(),
    original_message_id: messageObj.reply_to,
  };
};

function createUUID() {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    },
  );
  return uuid;
}

export const handleMessageFavorite = (
  messageId: number,
  shouldAddToFavorite: boolean,
) => {
  return async (dispatch: Dispatch<ConversationAction>) => {
    const data = {id: messageId};
    const endPoint = shouldAddToFavorite
      ? `favorites`
      : `favorites/${messageId}`;
    const method = shouldAddToFavorite ? 'POST' : 'DELETE';
    const resp = await fetchWrapper({endPoint, data, method});

    if (!resp) return null;
    if (!resp.body.success) return false;

    dispatch({
      type: ConversationActionType.CONVERSATION_HANDLE_MESSAGE_FAVORITE,
      payload: {
        messageId,
        favorite: shouldAddToFavorite,
      },
    });
    return true;
  };
};

export const searchMessages = (conversationId: number, searchText: string) => {
  return async () => {
    const resp = await fetchWrapper({
      endPoint: `conversations/search_messages`,
      data: {id: conversationId, search: searchText},
      method: 'POST',
    });

    if (!resp) return null;

    return resp.body.success ? resp.body : false;
  };
};

const limitMessageRequest = (messageObj: any, sizeLimit = 8000) => {
  const lengthObj: any = {};

  Object.keys(messageObj).map((key) => {
    lengthObj[key] = getPropertyLength(messageObj[key]);
  });

  const exceededParams = Object.keys(lengthObj).filter(function (proKey) {
    if (lengthObj[proKey] >= sizeLimit) {
      return proKey;
    }
  });

  if (exceededParams.length) {
    exceededParams.map(function (key) {
      messageObj[key] = messageObj[key].substring(0, sizeLimit);
    });
  }

  return {
    request: messageObj,
    exceeded: exceededParams.length ? true : false,
  };
};

export const sendCoupon = (
  conversationId: number,
  coupon: string,
  plan: string,
) => {
  return async () => {
    const resp = await fetchWrapper({
      endPoint: `events`,
      data: {id: conversationId, event: 'coupon', coupon: coupon, plan: plan},
      method: 'POST',
    });

    if (!resp) return null;

    return resp.body.success ? resp.body.url : false;
  };
};
/* TO-DO add domain to cors aws s3 */
export const downloadFileCall = (id: number) => {
  return async () => {
    const resp = await fetchWrapper({
      endPoint: `messages/file_url?id=${id}`,
    });

    if (!resp) return null;

    return resp.body.success ? resp.body.url : false;
  };
};

export const setQuestionsAI = (conversationId: number) => {
  return async () => {
    const response = await fetchWrapper({
      endPoint: `ai_answers/get_ai_questions?conversation_id=${conversationId}`,
    });

    if (!response) return null;

    if (response.body.error || !response.body.success) return null;
    const questionsAIMapped: AIQuestionNew[] = mapApiResponseAIQuestionsNew(
      response.body.questions,
    );

    return questionsAIMapped;
  };
};

export const createAnswer = (
  conversationId: number,
  question: AIQuestionNew,
) => {
  return async () => {
    const response = await fetchWrapper({
      endPoint: `ai_answers/create_ai_answers`,
      method: 'POST',
      data: convertAnswersToAnswersStorage(question, conversationId),
    });

    if (response && response.body.success) return true;
    else return false;
  };
};
