import {snakeCaseToCamelCase} from '@ifeelonline/chat-core';

import {
  AIAnswersStorage,
  AIAnswerStorage,
  AIQuestion,
  AIQuestionnaireShow,
  AIQuestionnaireStatus,
  AIQuestionNew,
  AIQuestionStorage,
  AIQuestionTag,
  AIResult,
  AIResultStorage,
  AIShowStorage,
  AIStorage,
  AISurveyStatus,
  AISurveyStatusStorage,
  AIUserQuestionnaire,
} from 'src/pages/chat/types/AIQuestionnaire';
import {getQuestionType} from 'src/pages/chat/utils/ai_questionnaire/logic';

export function mapApiResponseAIQuestions(
  jsonArray: AIQuestionStorage[],
): AIQuestion[] {
  return jsonArray.map((jsonBack) => {
    const mappedAIQuestion: AIQuestion = {
      id: jsonBack.id,
      surveyId: jsonBack.survey_id,
      position: jsonBack.position,
      body: jsonBack.body,
      tag: mapAiQuestionTag(jsonBack.tag),
      required: jsonBack.field === 'required',
      isSOFAS:
        jsonBack.tag ===
        'number' /* TO-DO improve when back make the change to differentiate */,
    };

    if (jsonBack.rules) {
      mappedAIQuestion.rules = {
        maxLength: jsonBack.rules.max_number_characters,
      };
    }

    if (jsonBack.question_options) {
      mappedAIQuestion.questionOptions = jsonBack.question_options.map(
        (option) => ({
          id: option.id,
          agentId: option.agent_id,
          conversationId: option.conversation_id,
          nickname: option.nickname,
          position: option.position,
          body: option.body,
        }),
      );
    }

    if (jsonBack.answer) {
      mappedAIQuestion.answer = {
        id: jsonBack.answer.id,
        questionId: jsonBack.answer.question_id,
        optionId: jsonBack.answer.option_id,
        comment: jsonBack.answer.comment,
        position: jsonBack.answer.position,
        agentId: jsonBack.answer.agent_id,
      };
    }

    return mappedAIQuestion;
  });
}

export function mapApiResponseAIQuestionsNew(
  jsonArray: AIQuestionStorage[],
): AIQuestionNew[] {
  return jsonArray.map((jsonBack) => {
    const mappedAIQuestion: AIQuestionNew = {
      id: jsonBack.id,
      surveyId: jsonBack.survey_id,
      position: jsonBack.position,
      body: jsonBack.body,
      tag: mapAiQuestionTag(jsonBack.tag),
      required: jsonBack.field === 'required',
      isSOFAS:
        jsonBack.tag ===
        'number' /* TO-DO improve when back make the change to differentiate */,
    };

    if (jsonBack.rules) {
      mappedAIQuestion.rules = {
        maxLength: jsonBack.rules.max_number_characters,
      };
    }

    if (jsonBack.question_options) {
      mappedAIQuestion.questionOptions = jsonBack.question_options.map(
        (option) => ({
          id: option.id,
          agentId: option.agent_id,
          conversationId: option.conversation_id,
          nickname: option.nickname,
          position: option.position,
          body: option.body,
        }),
      );
    }

    if (jsonBack.answer) {
      mappedAIQuestion.answer = {
        id: jsonBack.answer.id,
        questionId: jsonBack.answer.question_id,
        optionIds: jsonBack.answer.option_id,
        comment: jsonBack.answer.comment,
        position: jsonBack.answer.position,
        agentId: jsonBack.answer.agent_id,
        type: getQuestionType(mapAiQuestionTag(jsonBack.tag)),
      };
    }

    return mappedAIQuestion;
  });
}

function mapAiQuestionTag(tag: AIQuestionStorage['tag']): AIQuestionTag {
  switch (tag) {
    case 'agent':
      return AIQuestionTag.Agent;
    case 'checkbox':
      return AIQuestionTag.Checkbox;
    case 'radio-button':
      return AIQuestionTag.RadioButton;
    case 'dropdown':
      return AIQuestionTag.Dropdown;
    case 'text':
      return AIQuestionTag.Text;
    case 'number':
      return AIQuestionTag.Number;
    default:
      return AIQuestionTag.Text;
  }
}

export function mapSurveyStatus(
  storage: AISurveyStatusStorage,
): AISurveyStatus {
  const surveyStatus: AISurveyStatus = {
    success: storage.success,
    aiStatus: mapAIStatus(storage.ai_status),
    agent: storage.agent,
    aiStatusList: {
      start: storage.ai_status_list.start,
      draft: storage.ai_status_list.draft,
      editing: storage.ai_status_list.editing,
      sent: storage.ai_status_list.sent,
    },
  };
  return surveyStatus;
}

function mapAIStatus(status: string): AIQuestionnaireStatus {
  switch (status) {
    case 'draft':
      return AIQuestionnaireStatus.DRAFT;
    case 'editing':
      return AIQuestionnaireStatus.EDITING;
    case 'sent':
      return AIQuestionnaireStatus.SENT;
    case 'start':
    default:
      return AIQuestionnaireStatus.START;
  }
}

function mapAIStorageToAIQuestionnaire(
  aiStorage: AIStorage,
): AIUserQuestionnaire {
  const ai: AIUserQuestionnaire = {
    id: aiStorage.id,
    conversationId: aiStorage.conversation_id,
    aiStatus: aiStorage.ai_status,
    virtualAssistant: aiStorage.virtual_assistant,
    emotionalSupportChat: aiStorage.emotional_support_chat,
    videoTherapy: aiStorage.video_therapy,
    referral: aiStorage.referral,
    agentSelection: aiStorage.agent_selection
      ? snakeCaseToCamelCase(aiStorage.agent_selection)
      : null,
  };
  return ai;
}

export function mapShowAIStorageToShowAI(
  showAIStorage: AIShowStorage,
): AIQuestionnaireShow {
  const showAI: AIQuestionnaireShow = {
    success: showAIStorage.success,
    ai: mapAIStorageToAIQuestionnaire(showAIStorage.ai),
    agentPossibleSelection: {
      virtualAssistant:
        showAIStorage.agent_possible_selection.virtual_assistant,
      emotionalSupportChat:
        showAIStorage.agent_possible_selection.emotional_support_chat,
      videoTherapy: showAIStorage.agent_possible_selection.video_therapy,
      referral: showAIStorage.agent_possible_selection.referral,
    },
  };
  return showAI;
}

export function mapAIResultStorageToAIResult(
  storageData: AIResultStorage,
): AIResult {
  return {
    id: storageData.id,
    conversationId: storageData.conversation_id,
    agent: {
      id: storageData.agent.id,
      agentId: storageData.agent.agent_id,
      userId: storageData.agent.user_id,
      nickname: storageData.agent.nickname,
      position: storageData.agent.position,
    },
    aiStatus: storageData.ai_status,
    virtualAssistant: storageData.virtual_assistant,
    emotionalSupportChat: storageData.emotional_support_chat,
    videoTherapy: storageData.video_therapy,
    referral: storageData.referral,
  };
}

/**
 * answer question tag agent: question_id: number, agent_id: number
 * answer question tag checkbox, radioButton: question_id: number, option_ids: number[]
 * answer question tag text: question_id: number, comment: string
 * ?? answer question tag number: question_id: number, comment: string
 */

export function convertAnswersToAnswersStorage(
  question: AIQuestionNew,
  conversationId: number,
): AIAnswersStorage | null {
  if (question && question.answer && question.answer.questionId) {
    const convertedAnswer: AIAnswerStorage = {
      question_id: question.answer.questionId,
    };
    switch (question.tag) {
      case 'agent':
        convertedAnswer.agent_id = question.answer.agentId;
        break;
      case 'radioButton':
      case 'dropdown':
      case 'checkbox':
        convertedAnswer.option_ids = question.answer.optionIds;
        break;
      case 'text':
      case 'number':
        convertedAnswer.comment = question.answer.comment;
        break;
      default:
        throw new Error(`Unknown question type: ${question.answer.type}`);
    }

    return {
      conversation_id: conversationId,
      questions: [convertedAnswer],
    };
  }
  return null;
}

export function convertQuestionsToAnswersStorage(
  questions: AIQuestionNew[],
  conversationId: number,
): AIAnswersStorage | null {
  const answers: AIAnswerStorage[] = [];

  questions.forEach((question) => {
    const result = convertAnswersToAnswersStorage(question, conversationId);
    if (result && result.questions) {
      answers.push(...result.questions);
    }
  });

  if (answers.length === 0) {
    return null;
  }

  return {
    conversation_id: conversationId,
    questions: answers,
  };
}
