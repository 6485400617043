import {useState} from 'react';

import {useSelector} from 'react-redux';

import {RootState} from 'src/state';

import {SendCouponModal} from '../modals/SendCouponModal';
import {AppLinksModal} from '../modals/AppLinksModal';

import {SidePanelNoConversation} from './components/SidePanelNoConversation';
import {SidePanelInfo} from './components/SidePanelInfo';

interface Props {
  isScheduledView?: boolean;
}
export const SidePanel = ({isScheduledView}: Props) => {
  const {conversation} = useSelector((state: RootState) => state.conversation);
  const [showSendCouponModal, setShowSendCouponModal] = useState(false);
  const [showAppLinkModal, setShowAppLinkModal] = useState(false);

  return (
    <>
      <div
        id="home_user_info"
        className="flex w-91.5 flex-col justify-between bg-text-700 text-white">
        <div className="survey-container h-full overflow-auto pb-8">
          {conversation ? (
            <SidePanelInfo
              conversation={conversation}
              setShowSendCouponModal={setShowSendCouponModal}
              setShowAppLinkModal={setShowAppLinkModal}
              isScheduledView={isScheduledView}
            />
          ) : (
            <SidePanelNoConversation />
          )}
        </div>

        <SendCouponModal
          isOpen={showSendCouponModal}
          close={() => setShowSendCouponModal(false)}
        />
        <AppLinksModal
          isOpen={showAppLinkModal}
          close={() => setShowAppLinkModal(false)}
        />
      </div>
    </>
  );
};
