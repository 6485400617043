import {useContext} from 'react';

import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {Button, Text} from '@ifeelonline/storybook';

import {Star} from 'phosphor-react';

import {FilterConversationMenu} from 'src/pages/chat/components/conversations_list_section/FilterConversationMenu';
import {ChatPageContext} from 'src/pages/chat/components/ChatPageContext';
import {checkIfVideoCallIsAvailable} from 'src/helpers/videoCalls';
import {ChatStatusSwitch} from 'src/pages/chat/ChatStatusSwitch';
import {RouteFavoriteMessages} from 'src/constants/Routes';
import {isPatientWaiting} from 'src/helpers/patients';
import {COLOR_TEXT_500} from 'src/constants/colors';
import {useUser} from 'src/providers/UserProvider';
import type {RootState} from 'src/state/store';

import {VideocallButton} from './VideocallButton';
import {MainMenu} from './menu/MainMenu';

interface Props {
  chatView?: boolean;
  isSingleConversation?: boolean;
  favorites?: boolean;
  isScheduledView?: boolean;
}

export const Navbar = ({
  isSingleConversation = false,
  chatView = true,
  isScheduledView = false,
}: Props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {setPageConversations} = useContext(ChatPageContext);

  const {conversation} = useSelector((state: RootState) => state.conversation);
  const {filter} = useSelector((state: RootState) => state.conversations);
  const {user} = useUser();

  const handleGoToFavorites = (conversationId: number) => {
    navigate(
      RouteFavoriteMessages(
        isSingleConversation
          ? `${conversationId.toString()}?isSingleConversation=true`
          : conversationId.toString(),
      ),
    );
  };

  return (
    <div
      className={`fixed top-0 z-10 flex h-15 w-full min-w-204 items-center border-b border-solid border-borders-blueE3 bg-white text-text-blue21 shadow-navbar`}>
      {!isSingleConversation && chatView && (
        <div className="flex h-full w-87.5 items-center justify-between px-2">
          <MainMenu />
          {filter ? <p>{filter}</p> : <p>{t('chat.title') as string}</p>}
          <FilterConversationMenu
            setPage={setPageConversations!}
            isScheduledView={isScheduledView}
          />
        </div>
      )}
      <div
        className={`flex h-full ${
          isSingleConversation ? 'w-vw-minus-75' : 'w-vw-minus-162.5'
        } flex-1 items-center justify-start font-sofia font-semibold capitalize`}>
        {(isSingleConversation || !chatView) && <MainMenu />}
        {conversation && (
          <>
            {!isPatientWaiting(conversation) && chatView && (
              <Button
                buttonType="icon-button-secondary"
                onClick={() => handleGoToFavorites(conversation!.id!)}
                icon={<Star size={20} color={COLOR_TEXT_500} />}
              />
            )}

            {conversation &&
              user &&
              checkIfVideoCallIsAvailable(user, conversation) && (
                <VideocallButton />
              )}

            <div className="flex h-full flex-1 flex-col items-center justify-center p-4 ps-4 align-middle">
              <div className="flex size-full flex-row items-center justify-center align-middle">
                <h3>{conversation.patientNickname}</h3>
              </div>
              <div className="flex size-full flex-row items-center justify-center align-middle">
                <span
                  className={`me-2 inline-block size-3 rounded-full bg-offline ${
                    conversation.patientState ? 'bg-online' : ''
                  }`}
                />
                <Text textType="body-3">
                  {t(
                    `${
                      conversation.patientState
                        ? 'conversation.status.online'
                        : 'conversation.status.offline'
                    }`,
                  )}
                </Text>
              </div>
            </div>
          </>
        )}
      </div>
      {!isSingleConversation && chatView && (
        <div className="flex w-91.5 flex-row items-center justify-end pe-5">
          <ChatStatusSwitch />
        </div>
      )}
    </div>
  );
};
