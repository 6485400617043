import {ConversationsActionType} from 'src/state/action_types/ConversationsActionType';
import {AppDispatch} from 'src/state';

import {AgentChannel} from '../types/agentChannelDataTypes';

export function handleAgentChannelReceived(
  dataResponse: AgentChannel['DataResponse'],
  dispatch: AppDispatch,
  setIsChatOnline: React.Dispatch<React.SetStateAction<boolean>>,
  setChatStateChanged: React.Dispatch<React.SetStateAction<boolean | null>>,
) {
  switch (dataResponse.action) {
    case 'new_user':
      handleNewUser(dispatch, dataResponse.data);
      break;
    case 'typing':
      handleTyping(dispatch, dataResponse.data);
      break;
    case 'send_message':
      handleSendMessage(
        dispatch,
        dataResponse.data,
        dataResponse.conversation,
        dataResponse.client === 'patient',
      );
      break;
    case 'user_status':
      handleUserStatus(dispatch, dataResponse.data);
      break;
    case 'mark_conversation_as_read':
      handleConversationMarkAsRead(dispatch, dataResponse.data);
      break;
    case 'agent_changed_in_conversation':
      handleAgentChanged(dispatch, dataResponse.data);
      break;
    case 'support_status':
      handleSupportStatusChanged(
        dataResponse.data,
        setIsChatOnline,
        setChatStateChanged,
      );
      break;
    default:
      break;
  }
}

const handleNewUser = (
  dispatch: AppDispatch,
  data: AgentChannel['NewUserData'],
) => {
  dispatch({
    type: ConversationsActionType.CONVERSATIONS_NEW_USER,
    payload: data,
  });
};

const handleSendMessage = (
  dispatch: AppDispatch,
  data: AgentChannel['SendMessageData'],
  conversation: AgentChannel['SendMessageConversationData'],
  isPatientMessage: boolean,
) => {
  dispatch({
    type: ConversationsActionType.CONVERSATIONS_NEW_MESSAGE,
    payload: {
      ...data,
      ...conversation,
      isPatientMessage,
    },
  });
};

const handleTyping = (
  dispatch: AppDispatch,
  data: AgentChannel['TypingData'],
) => {
  dispatch({
    type: ConversationsActionType.CONVERSATIONS_TYPING,
    payload: data,
  });
};

const handleUserStatus = (
  dispatch: AppDispatch,
  data: AgentChannel['UserStatusData'],
) => {
  dispatch({
    type: ConversationsActionType.CONVERSATIONS_USER_STATUS,
    payload: data,
  });
};

const handleConversationMarkAsRead = (
  dispatch: AppDispatch,
  data: AgentChannel['MarkConversationAsReadData'],
) => {
  dispatch({
    type: ConversationsActionType.CONVERSATIONS_MARK_AS_READ,
    payload: data,
  });
};

const handleAgentChanged = (
  dispatch: AppDispatch,
  data: AgentChannel['MarkConversationAsReadData'],
) => {
  dispatch({
    type: ConversationsActionType.CONVERSATIONS_AGENT_CHANGED,
    payload: data,
  });
};

const handleSupportStatusChanged = (
  data: AgentChannel['SupportStatusData'],
  setIsChatOnline: React.Dispatch<React.SetStateAction<boolean>>,
  setChatStateChanged: React.Dispatch<React.SetStateAction<null | boolean>>,
) => {
  setIsChatOnline(data.state);
  setChatStateChanged(true);
};
