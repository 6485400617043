import React from 'react';

import {Text} from '@ifeelonline/storybook';

export const Quote = ({text, author}: {author: string; text: string}) => {
  return (
    <div className="text-center">
      <Text
        textType="headline-4"
        fontColor="text-white"
        fontFamily="recoleta"
        className="font-light">
        {text}
      </Text>

      <Text
        textType="body-3"
        fontColor="text-white"
        fontFamily="recoleta"
        className="mt-3 font-light">
        {author}
      </Text>
    </div>
  );
};
