import {Control, FieldErrors, FieldValues} from 'react-hook-form';

import {Button, Text} from '@ifeelonline/storybook';
import {Send} from '@ifeelonline/icons';

import {SurveyQuestion} from 'src/pages/chat/types/Surveys';

import {useRenderFormField} from './hooks/useRenderFormField';
import {useSurveys} from './hooks/useSurveys';

interface Props {
  question: SurveyQuestion;
  control: Control<FieldValues>;
  errors: FieldErrors<FieldValues>;
  conversationId: number;
  isLastQuestion?: boolean;
}

export const SurveyFormQuestions = ({
  question,
  control,
  errors,
  conversationId,
  isLastQuestion = false,
}: Props) => {
  const {handleBlur, handleSendQuestionToPatient} = useSurveys({
    question,
    conversationId,
  });

  const renderField = useRenderFormField({
    question,
    control,
    errors,
    conversationId,
    handleBlur,
  });

  return (
    <div className="flex1">
      <div
        className={`${
          !isLastQuestion &&
          'border-b border-solid border-borders-aiQuestionnaire'
        } flex w-full flex-col gap-3 py-2.5 text-start`}>
        <div className="group flex flex-row items-start justify-between">
          <Text textType="body-3" fontColor="text-text-400">
            {question.body}
          </Text>
          <div
            className="text-white opacity-0 group-hover:opacity-100"
            onClick={handleSendQuestionToPatient}>
            <Button
              buttonType="icon-button-primary"
              shape="round"
              icon={<Send color="white" />}
            />
          </div>
        </div>
        {renderField()}
      </div>
    </div>
  );
};
