export enum CONVERSATIONS_FILTERS {
  ALL = 'All',
  B2B = 'B2B',
  B2C = 'B2C',
  LIMBO = 'LIMBO',
  MACCABI = 'Maccabi',
  MACCABI_SCHEDULED = 'Maccabi Scheduled',
  NO_AGENT = 'No agent',
  UNREAD_MESSAGES = 'Unread messages',
  UNANSWERED = 'Unanswered',
  HIGH_RISK = 'High risk',
}
