import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {
  ChatProps,
  ChatScrollEffect,
  Message,
  getNextPage,
  useChatScrollEffects,
  useScroll,
} from '@ifeelonline/chat-core';
import {
  ImageInBigModal,
  LoadingChat,
  ScrollToBottom,
  ScrollableContainer,
} from '@ifeelonline/storybook';

import {ConversationActionType} from 'src/state/action_types/ConversationActionType';
import {getConversation} from 'src/state/action_creators/conversationActionCreator';
import {RenderSendMessageBox} from 'src/components/messages/RenderSendMessageBox';
import {isPatientMatched, isPatientWaiting} from 'src/helpers/patients';
import {RenderMessages} from 'src/components/messages/RenderMessages';
import {useChat} from 'src/providers/ChatProvider';
import {useUser} from 'src/providers/UserProvider';
import {ChatContext} from 'src/types/ChatContext';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state/store';

import {ChatDisabled} from './ChatDisabled';

type Props = {
  isSingleConversation: boolean;
  isScheduledChat?: boolean;
  areAllPagesLoaded: boolean;
  setAreAllPagesLoaded: Dispatch<SetStateAction<boolean>>;
  setShowSearcher: Dispatch<SetStateAction<boolean>> | null;
  messagesDiv: React.MutableRefObject<any>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isFirstLoad: boolean;
  setIsFirstLoad: Dispatch<SetStateAction<boolean>>;
  patientId?: boolean;
  getFirstPage: () => void;
  firstMsgRefId: number | null;
  setFirstMsgRefId: React.Dispatch<React.SetStateAction<number | null>>;
} & Pick<ChatProps, 'msgRefs'> &
  Pick<ChatContext, 'formValues' | 'resetFormValues'>;

export const ChatContainer = ({
  areAllPagesLoaded,
  isSingleConversation,
  msgRefs,
  patientId,
  resetFormValues,
  setAreAllPagesLoaded,
  setShowSearcher,
  getFirstPage,
  messagesDiv,
  loading,
  setLoading,
  isFirstLoad,
  setIsFirstLoad,
  firstMsgRefId,
  setFirstMsgRefId,
  isScheduledChat,
}: Props) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {handleScroll, scrollToBottom} = useScroll();
  const {
    setImageBigUrl,
    imageBigUrl,
    isVisibleLastMessage,
    messagesEndRef,
    newMessages,
    setNewMessages,
  } = useChat();
  const {user} = useUser();
  const chatScrollEffects = useChatScrollEffects();

  const {
    status,
    messages,
    conversation,
    messageBounds,
    pagesLoaded,
    isJumping,
  } = useSelector((state: RootState) => state.conversation);

  const {editMsg}: {editMsg: Message} = useSelector(
    (state: RootState) => state.ui,
  );

  const [lastMsgRefId, setLastMsgRefId] =
    useState<ChatScrollEffect['lastMsgRefId']>(null);

  const [scrollReachedTop, setScrollReachedTop] = React.useState(false);
  const [scrollReachedBottom, setScrollReachedBottom] = React.useState(false);

  const showScrollButton =
    patientId &&
    messages &&
    (!pagesLoaded.includes(1) || !isVisibleLastMessage);

  const isFirstLoadRef =
    React.useRef(
      isFirstLoad,
    ); /*This is a Trick to handle state changes on nonEffect functions, very cheap i know but it works*/

  useEffect(() => {
    isFirstLoadRef.current = isFirstLoad;
  }, [isFirstLoad]);

  const dispatchConversation = (directionIsUp: boolean) => {
    const nextPage = getNextPage(directionIsUp, pagesLoaded, setLoading);

    if (!nextPage) return;
    if (conversation && conversation.id) {
      dispatch(getConversation(conversation.id, nextPage))
        .then((res) => {
          setLoading(true);
          dispatch({
            type: ConversationActionType.SET_PAGES_LOADED,
            payload: [...pagesLoaded, nextPage],
          });
          if (!res) {
            setAreAllPagesLoaded(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (scrollReachedTop && !isFirstLoad && !loading) {
      handleScroll({
        areAllPagesLoaded,
        loading,
        status,
        messagesDiv,
        conversation,
        setLoading,
        dispatchFunction: dispatchConversation,
        setNewMessages,
        newMessages,
        pagesLoaded,
        isUpDirection: true,
      })({
        preventDefault:
          () => {} /*This could be removed once we delete the handleScroll scroll position checks and change it for a prop that says if its up or down*/,
        currentTarget: {
          scrollHeight: messagesDiv.current.current.scrollHeight,
          scrollTop: 0,
          clientHeight: window.innerHeight,
        },
      });
      setScrollReachedTop(false);
    }
  }, [scrollReachedTop]);

  useEffect(() => {
    if (scrollReachedBottom) {
      handleScroll({
        areAllPagesLoaded,
        loading,
        status,
        messagesDiv,
        conversation,
        setLoading,
        dispatchFunction: dispatchConversation,
        setNewMessages,
        newMessages,
        isUpDirection: false,
        pagesLoaded,
      })({
        preventDefault:
          () => {} /*This could be removed once we delete the handleScroll scroll position checks and change it for a prop that says if its up or down*/,
        currentTarget: {
          scrollHeight: 100,
          scrollTop: 100,
          clientHeight: 100,
        },
      });
      setScrollReachedBottom(false);
    }
  }, [scrollReachedBottom]);

  useEffect(() => {
    let isUpdatingMessages = false;
    if (messages && messages.length > 0) {
      isUpdatingMessages =
        messageBounds?.firstMessageId === messages[0]?.id &&
        messageBounds?.lastMessageId === messages.slice(-1)[0]?.id;

      if (isJumping && scrollReachedBottom) {
        setFirstMsgRefId(messages[0].id);
        return;
      }
    }

    if (!isUpdatingMessages) {
      const chatScrollEffectsParams: ChatScrollEffect = {
        firstMsgRefId,
        isJumping,
        lastMsgRefId,
        messages,
        messagesDiv,
        msgRefs,
        newMessages,
        setFirstMsgRefId,
        setLastMsgRefId,
        setLoading,
        setNewMessages,
        isVisibleLastMessage,
        isFirstLoad,
        pagesLoaded: pagesLoaded,
        isOwnMessage:
          messages &&
          messages.length > 0 &&
          messages.slice(-1)[0].userId === user?.id,
        isNewPage: scrollReachedTop,
      };
      chatScrollEffects(chatScrollEffectsParams);
      setLoading(false);
      if (messages && messages.length > 0) {
        dispatch({
          type: ConversationActionType.SET_MESSAGES_BOUNDS,
          payload: {
            firstMessageId: messages[0]?.id,
            lastMessageId: messages.slice(-1)[0]?.id,
          },
        });
      }
    } else {
      // We need to set loading to false here because loading is true by default.
      // When switching from an individual conversation to the conversation list,
      // it would remain true if not reset, causing the UI to be stuck in a loading state.
      // TO-DO: Refactor this to avoid setting loading to false here.
      setLoading(false);
    }
  }, [messages]);

  useEffect(() => {
    resetFormValues({messageBody: editMsg?.body ? editMsg.body : ''});
  }, [editMsg]);

  const onScrollReachTop = () => {
    //This is a fix for a closure issue

    if (!isFirstLoadRef.current) {
      setScrollReachedTop(true);
    }
  };

  const onScrollReachBottom = () => {
    //This is a fix for a closure issue

    if (!isFirstLoadRef.current) {
      setScrollReachedBottom(true);
    }
  };

  return (
    <div
      id="chat-container"
      className="relative flex h-full w-auto flex-1 flex-col justify-between">
      <ScrollableContainer
        id="messages"
        className="columns container-div relative mx-auto h-messagesContainer w-full flex-1 overflow-auto overflow-y-scroll px-1 pb-11 pt-14"
        data-page="1"
        onScrollReachBottom={onScrollReachBottom}
        onScrollReachTop={onScrollReachTop}
        onScroll={() => {
          setLoading(false);
          /*This is to prevent the first load to call for more messages until scroll is done*/
          /*I dont trust removing it currently, so i will leave it commented for trust issues*/
          /*setTimeout(() => {*/

          setIsFirstLoad(false);

          if (
            isVisibleLastMessage &&
            newMessages &&
            pagesLoaded.includes(1) &&
            setNewMessages
          ) {
            setNewMessages(null);
          }
          /*}, 1000);*/
        }}
        isDisabled={loading}
        ref={messagesDiv}>
        {messages && messages.length > 0 && (
          <>
            {(areAllPagesLoaded || messages.length <= 25) && (
              <>
                <p className="text-center font-sofia text-3.5 font-light text-text-blue9b">
                  {t('chat.confidential')}
                </p>
                <hr className="mx-auto my-4 h-1 max-w-sm" />
              </>
            )}
            {loading && <LoadingChat />}
            <RenderMessages
              messages={messages}
              msgRefs={msgRefs}
              isFavoritesView={false}
              messagesDiv={messagesDiv}
              setFirstMsgRefId={setFirstMsgRefId}
            />
            <div ref={messagesEndRef} />
          </>
        )}
      </ScrollableContainer>
      <div className="relative">
        <div className="absolute bottom-0 right-8 z-20">
          {showScrollButton && (
            <ScrollToBottom
              newMessages={newMessages}
              handleScrollToBottom={() => {
                if (pagesLoaded.includes(1)) {
                  scrollToBottom({
                    setNewMessages,
                    setLoading,
                    messagesDiv,
                    isOwnMessage: false,
                    newMessages,
                  });
                } else {
                  setAreAllPagesLoaded(false);
                  getFirstPage();
                }
              }}
            />
          )}
        </div>
      </div>
      <ImageInBigModal
        setImageBigUrl={setImageBigUrl}
        imageBigUrl={imageBigUrl}
      />
      {conversation && (
        <>
          {isPatientMatched(conversation) ||
          (isPatientWaiting(conversation) && isScheduledChat) ? (
            <>
              <RenderSendMessageBox
                conversation={conversation}
                isSingleConversation={isSingleConversation}
                setShowSearcher={setShowSearcher}
              />
            </>
          ) : (
            <ChatDisabled />
          )}
        </>
      )}
    </div>
  );
};
