import React, {useContext, useEffect, useRef, useState} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';
import {Button, Loading} from '@ifeelonline/storybook';

import {CaretCircleDown} from 'phosphor-react';

import {getChatOptions} from 'src/state/action_creators/conversationsActionCreator';
import {COLOR_TEXT_BLUE_POOL} from 'src/constants/colors';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {getScheduledConversations} from '../../state/action_creators/ScheduledPatientsActionCreator';
import {Conversation} from '../chat/components/conversations_list_section/Conversation';
import {ScheduledConversationListItem} from '../../types/ConversationList';
import {ChatPageContext} from '../chat/components/ChatPageContext';
import {CHAT_FORM_INITIAL_VALUES} from '../chat/constants/chat';
import {useUser} from '../../providers/UserProvider';

interface Props {
  setAreAllPagesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  reset: (newFormState?: typeof CHAT_FORM_INITIAL_VALUES) => void;
}

export const ScheduledConversationsList = ({
  page,
  setPage,
  setAreAllPagesLoaded,
  reset,
}: Props) => {
  const dispatch = useAppDispatch();

  const dataFetch = useRef(false);

  /*TO-DO review duplication, why allpadesloades and allconversations */
  const [allConversations, setAllConversations] = useState(false);
  const {pageConversations, setPageConversations} = useContext(ChatPageContext);
  const {currentAgentId} = useUser();
  const {status, conversations, filter} = useSelector(
    (state: RootState) => state.conversations,
  );

  /*@TODO SCHEDULED CLEAN THIS*/
  const scheduledConversations =
    conversations as ScheduledConversationListItem[];
  /*  const isScheduledConversationListItem = (
    conversation: ConversationListItem | ScheduledConversationListItem
  ): conversation is ScheduledConversationListItem => {
    return (conversation as ScheduledConversationListItem).highRisk !== undefined;
  };*/

  const dispatchGetConversations = () => {
    dispatch(
      getScheduledConversations(pageConversations!, filter, currentAgentId),
    );
  };

  const loadMoreConversations = () => {
    setPageConversations!(pageConversations! + 1);
    dispatch(
      getScheduledConversations(pageConversations! + 1, filter, currentAgentId),
    ).then((res) => {
      if (!res) {
        setAllConversations(true);
      }
    });
  };

  useEffect(() => {
    if (dataFetch.current) {
      return;
    }
    dataFetch.current = true;
    dispatchGetConversations();
    dispatch(getChatOptions());
  }, []);

  return (
    <div
      id="home__conversations"
      className="w-87.5 overflow-auto border-r border-solid border-backgrounds-9 p-2.5"
      style={{borderRightWidth: '1px'}}>
      {status === SharedActionType.LOADING ? (
        <div className="relative mx-0 my-2.5 h-7.5">
          <Loading />
        </div>
      ) : (
        scheduledConversations && (
          <>
            {scheduledConversations.map(
              (conversation: ScheduledConversationListItem) => (
                <Conversation
                  key={conversation.id}
                  conversationListItem={conversation}
                  page={page}
                  setPage={setPage}
                  setAreAllPagesLoaded={setAreAllPagesLoaded}
                  reset={reset}
                  isHighRisk={conversation.highRisk}
                />
              ),
            )}
            {!allConversations && scheduledConversations.length >= 25 && (
              <Button
                buttonType="icon-button-secondary"
                onClick={() => loadMoreConversations()}
                icon={
                  <CaretCircleDown size={16} color={COLOR_TEXT_BLUE_POOL} />
                }
              />
            )}
          </>
        )
      )}
    </div>
  );
};
